import React from 'react'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import AppContainer from './AppContainer'
import Navbar from '../components/Navbar'
import ScrollToTop from '../components/ScrollToTop'
import Footer from './Footer'
import HomePage from './pages/HomePage'
import JobsPage from './pages/JobsPage'
import DiscoverPortfoliosPage from './pages/DiscoverPortfoliosPage'
import DiscoverProjectsPage from './pages/DiscoverProjectsPage'
import PortfolioShowPage from './pages/PortfolioShowPage'
import PortfolioManagePage from './pages/PortfolioManagePage'
import DesignsDemoPage from './pages/DesignsDemoPage'
import StackIndexPage from './pages/StackIndexPage'
import StackShowPage from './pages/StackShowPage'
import ProjectNewPage from './pages/ProjectNewPage'
import ProjectEditPage from './pages/ProjectEditPage'
import ProjectShowPage from './pages/ProjectShowPage'
import ChallengeIndexPage from './pages/ChallengeIndexPage'
import ChallengeShowPage from './pages/ChallengeShowPage'
import ArticleNewPage from './pages/ArticleNewPage'
import ArticleEditPage from './pages/ArticleEditPage'
import AboutPage from './pages/AboutPage'
import PricingPage from './pages/PricingPage'
import PrivacyPage from './pages/PrivacyPage'
import TermsPage from './pages/TermsPage'
import Page404 from './pages/Page404'

const App = (props) => (
  <AppContainer globalProps={props}>
    <BrowserRouter>
      <Navbar />
      <ScrollToTop history={useHistory()} />
      <Switch>
        <Route exact path='/'                            render={() => <HomePage newUsers={props.newUsers}/>} />
        <Route exact path='/jobs'                        component={JobsPage} />
        <Route exact path='/pricing'                     component={PricingPage} />
        <Route exact path='/challenges'                  component={ChallengeIndexPage} />
        <Route exact path='/challenges/:challengeSlug'   component={ChallengeShowPage} />
        <Route exact path='/developers'                  component={DiscoverPortfoliosPage} /> {/* old route so link doesn't break */}
        <Route exact path='/portfolios'                  component={DiscoverPortfoliosPage} />
        <Route exact path='/projects'                    component={DiscoverProjectsPage} />
        <Route exact path='/projects/new'                component={ProjectNewPage} />
        <Route exact path='/projects/:projectId/edit'    component={ProjectEditPage} />
        <Route exact path='/articles/new'                component={ArticleNewPage} />
        <Route exact path='/articles/:articleId/edit'    component={ArticleEditPage} />
        <Route exact path='/portfolios/:tabSlug'         component={PortfolioManagePage} />
        <Route exact path='/stacks'                      component={StackIndexPage} />
        <Route exact path='/stacks/:stackSlug'           component={StackShowPage} />
        <Route exact path='/stacks/:stackSlug'           component={StackShowPage} />
        <Route exact path='/:portfolioSlug/:projectSlug' component={ProjectShowPage} />
        <Route exact path='/designs/demo/:designName'    component={DesignsDemoPage} />
        <Route exact path='/about'                       component={AboutPage} />
        <Route exact path='/privacy'                     component={PrivacyPage} />
        <Route exact path='/terms'                       component={TermsPage} />
        <Route exact path='/404'                         component={Page404} />
        <Route exact path='/:portfolioSlug'              component={PortfolioShowPage} />
        <Route                                           component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  </AppContainer>
)

export default App
