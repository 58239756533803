import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import FetchWrapper from '../../helpers/FetchWrapper'
import Loader from '../Loader'

const StackIndexPage = () => {
  const [stacks, setStacks] = useState({})
  const [loading, setLoading] = useState(true)
  const [API] = useState(new FetchWrapper)
  const location = useLocation()

  useEffect(() => {
    requestTags()
    return () => {}
  }, [location])

  const requestTags = async () => {
    try {
      const response = await API.get('/stacks')
      const data = await response.json()
      setStacks(data)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const renderTags = () => {
    const filteredStacks = stacks.filter(stack => stack.project_count > 0)
    return (
      filteredStacks.map(({id, name, slug, project_count}) => (
        <Link
          key={id}
          to={`/stacks/${slug}`}>
          <h6 className='link'>{name} ({project_count})</h6>
        </Link>
      ))
    )
  }

  return (
    <main className='page-content-container tag-index-container'>
      <header>
        <h1>Stacks 🥞</h1>
        <h5 className='subhead'>Discover projects using a specific tech stack.</h5>
      </header>
      <section className='links'>
        {
          loading
          ? <Loader extraClasses='large-margin' />
          : renderTags()
        }
      </section>
    </main>
  )
}

export default StackIndexPage
