import React from 'react'
const classNames = require('classnames');

const Loader = ({extraClasses, pageLoader, centerLoader}) => {
  const loaderClassNames = classNames('loader-spinner', [extraClasses])

  const renderLoader = () => (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={loaderClassNames}
    >
      <circle cx="50" cy="50" r="45"/>
    </svg>
  )

  if (pageLoader) {
    return (
      <div className='page-loader-container'>
        { renderLoader() }
      </div>
    )
  }

  if (centerLoader) {
    return (
      <div className='center-loader-container'>
        { renderLoader() }
      </div>
    )
  }

  return renderLoader()
}

export default Loader
