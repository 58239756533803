import React from 'react'
import DiscoverFilter from '../DiscoverFilter'

const DiscoverProjectsPage = () => {
  return (
    <main className='page-content-container discover-page-container'>
      <header>
        <h1>
          Discover <span className='troopl-gradient-text'>projects</span> ⚡️
        </h1>
        <h5 className='subhead'>
          Get inspired by these wonderful projects 🙌
        </h5>
      </header>
      <DiscoverFilter model={'projects'} />
    </main>
  )
}

export default DiscoverProjectsPage
