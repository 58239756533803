// If there is 2 or more elements, pass the cardCount property

import React from 'react'
const classNames = require('classnames');

const RadioButtonCards = (props) => {
  const {
    label,
    labelNote,
    values,
    valuesKey,
    name,
    onChange,
    onClick,
    errors,
    required,
    cards,
    cardCount,
    extraClasses
  } = props

  const radioButtonCardsClasses = classNames('radio-button-cards', cardCount, [extraClasses])

  const showChecked = (cardValue) => {
    if (typeof(cardValue) == 'boolean') {
      return JSON.parse(values[valuesKey]) === cardValue;
    }
    return values[valuesKey] === cardValue;
  }

  return (
    <div className='radio-button-container'>
      <label htmlFor={valuesKey}>
        {label}
        {labelNote && <span className='label-note'>{labelNote}</span>}
      </label>
      <div className={radioButtonCardsClasses}>
        {
          cards.map(((card, index) => (
            <label
              key={`${valuesKey}-${index}`}
              className={`${valuesKey}-${index}`}
            >
              <input
                checked={showChecked(card.value)}
                // checked={true}
                id={valuesKey}
                value={card.value}
                type='radio'
                name={name}
                onChange={onChange}
                onClick={onClick}
                aria-required={required}
                aria-invalid={errors && !!errors[valuesKey]}
              />
              <div className={classNames('radio-button', card.value)}>
                <span className='emoji'>{card.emoji}</span>
                <span>{card.text}</span>
              </div>
            </label>
          )))
        }
      </div>
      {errors[valuesKey] && <span className='note error'>{errors[valuesKey]}</span>}
    </div>
  )
}

export default RadioButtonCards
