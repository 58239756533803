import React from 'react'
const classNames = require('classnames')

const Emoji = ({name, extraClasses}) => {
  const emojiClasses = classNames('emoji', [name], [extraClasses])
  const emojiImage = name ? require(`images/emoji/${name}.png`) : null

  return (
    <img
      className={emojiClasses}
      alt={`${name} emoji`}
      src={emojiImage}
    />
  )
}

export default Emoji
