import React from 'react'
const classNames = require('classnames')
import PortfolioCard from './PortfolioCard'
import Button from './Button'

const PortfolioCards = ({portfolios, extraClasses, blur}) => {
  const containerClasses = classNames('portfolio-card-container', { blur: blur }, [extraClasses])

  const renderSignupMessage = () => (
    <div className='sign-up-message'>
      <div className='text'>
        <h4>Create a <span className='troopl-gradient-text'>free</span> company profile</h4>
        <p className='mt-20 mb-25'>Meet our Troop & discover your next amazing developer 🙌</p>
        <a href='/companies/signup'>
          <Button id='create-company-profile-button' extraClasses={['fill-width']} >
            Let's go 🚀
          </Button>
        </a>
      </div>
    </div>
  )

  if (!portfolios.length > 0) {
    return (
      <div className='no-portfolios-found-container'>
        <h3>No match found 🔍</h3>
        <p className='mt-05'>
          Try broadening your horizons.
        </p>
      </div>
    )
  }

  return (
    <section className={containerClasses}>
      { blur && renderSignupMessage() }
      { portfolios.map((p, index) => <PortfolioCard key={index} portfolio={p} />) }
    </section>
  )
}

export default PortfolioCards
