import React from 'react'
const classNames = require('classnames')

const Stats = ({likes, views, allProjects, card}) => {
  const statsContainerClasses = classNames('stats-container', {'card': card })
  const showViews = views !== undefined
  const showLikes = likes !== undefined

  return (
    <div className={statsContainerClasses}>
      {
        showLikes && (
          <p className='likes'>
            {likes}{allProjects ? ' project' : ''} {card ? '' : 'likes'}
          </p>
        )
      }
      {
        showViews && (
          <p className='views'>
            {views}{allProjects ? ' project' : ''} {card ? '' : 'views'}
          </p>
        )
      }
    </div>
  )
}

export default Stats
