import React from 'react'

export const privacyPolicy = [
  {
    headline: 'What\'s covered?',
    body: (
      <>
        <p>
          Good question! This privacy policy will explain how Troopl (“Troopl”, “site”, “website”, “we”, “our” or “us” Troopl has created this Privacy Policy to explain how we collect, use, protect, share, disclose, and process your personal data while operating our website.
        </p>
        <ol type='1'>
          <li>What data do we collect?</li>
          <li>How do we collect your data?</li>
          <li>How will we use your data?</li>
          <li>How do we store your data?</li>
          <li>Email Marketing Opt-Out</li>
          <li>What are your data protection rights?</li>
          <li>What are cookies?</li>
          <li>Why do we use cookies?</li>
          <li>What types of cookies do we use?</li>
          <li>How to manage your cookies</li>
          <li>Cookie declaration</li>
          <li>Privacy policies of other websites</li>
          <li>Changes to our privacy policy</li>
          <li>How to contact us</li>
          <li>How to contact the appropriate authorities</li>
        </ol>
      </>
    )
  },
  {
    headline: '1. What data do we collect?',
    body: (
      <>
        <p>
          You directly provide Troopl with most of the data we collect. We collect data and process data when you:
        </p>
        <ol>
          <li>Register online to join the Troopl platform as a Developer or Employer</li>
          <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email</li>
          <li>Use or view our website via your browser’s cookies</li>
        </ol>
      </>
    )
  },
  {
    headline: '2. How do we collect your data?',
    body: (
      <>
        <p>Troopl collects the following data:</p>
        <ol>
          <li>Personal identification information (name, email address, location, etc.)</li>
          <li>Education details (institution, graduation dates, etc.)</li>
          <li>Work details (company of employment)</li>
          <li>Social media profile (LinkedIn, GitHub, etc)</li>
        </ol>
      </>
    )
  },
  {
    headline: '3. How will we use your data?',
    body: (
      <>
        <p>
          Troopl depends on a number of legal grounds to collect, use, and share your information. We collect, use and share your information:
        </p>
        <ol className='mb-20'>
          <li>to provide, operate, and maintain our website;</li>
          <li>to improve, personalise, and expand our website;</li>
          <li>to understand and analyse how you use our website;</li>
          <li>to develop and protect new products, services, features, and functionality;</li>
          <li>to settle disputes;</li>
          <li>to provide customer support;</li>
          <li>when you have provided your affirmative consent, which you may revoke at any time, such as by signing up for my mailing list for receiving promotional emails regarding the website;</li>
          <li>to comply with a legal obligation;</li>
          <li>the court order or in connection with a legal claim; such as keeping purchasing information if required by tax law;</li>
        </ol>
        <p>Troopl will only share your data with other users on the platform required. Troopl does not provide your data to any other third party.</p>
      </>
    )
  },
  {
    headline: '4. How do we store your data?',
    body: (
      <p>
        Troopl securely stores your data using industry-standard, GDPR-compliant, cloud-based storage. Troopl will keep your personal data for as long as you continue to use our service. You have the right to request erasure of your data as set out below.
      </p>
    )
  },
  {
    headline: '5. Email Marketing Opt-Out',
    body: (
      <p>
        All of our commercial emails contain an ‘unsubscribe’ option. If you do not wish to receive any of our commercial marketing or email, you may unsubscribe anytime by following the unsubscribe option given in the email. Please be noted it will take up to 15 days to opt out from our email marketing service and in this period, you may receive our promotional email. Opting out service does not include transaction alerts or other updates from purchasing our service from time to time.
      </p>
    )
  },
  {
    headline: '6. What are your data protection rights?',
    body: (
      <>
        <p>
          Our Company would like to make sure that we are fully aware of all of your data protection rights. Every user is entitled to the following:
        </p>
        <ol>
          <li>The right to access. You have the right to request Troopl for copies of your personal data.</li>
          <li>The right to rectification. You have the right to request that Troopl correct any information you believe is inaccurate. You also have the right to request Troopl to complete the information you believe is incomplete.</li>
          <li>The right to erasure. You have the right to request that Troopl erase your personal data, under certain conditions.</li>
          <li>The right to restrict processing. You have the right to request that Troopl restrict the processing of your personal data, under certain conditions.</li>
          <li>The right to object to processing. You have the right to object to Troopl's processing of your personal data, under certain conditions.</li>
          <li>The right to data portability. You have the right to request that Troopl transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
          <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us (see point 13 for contact details).</li>
        </ol>
      </>
    )
  },
  {
    headline: '7. Cookies 🍪',
    body: (
      <p>
        Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.<br/><br/>
        For further information, visit <a href='https://allaboutcookies.org' target='_blank'>allaboutcookies.org</a>.
      </p>
    )
  },
  {
    headline: '8. Why do we use cookies?',
    body: (
      <>
        <p>
          Troopl uses cookies in a range of ways to improve your experience on our website, The reason we use cookies:
        </p>
        <ol>
          <li>To keep you signed in</li>
          <li>To verify your user account;</li>
          <li>To determine the time you are logged in;</li>
          <li>To secure your user account;</li>
          <li>To determine and fight against spam, malware, etc.</li>
          <li>To determine users’ interest in showing ads;</li>
          <li>To have the fullest experience possible;</li>
          <li>To Develop and protect new products, services, features, and functionality;</li>
        </ol>
      </>
    )
  },
  {
    headline: '9. What types of cookies do we use?',
    body: (
      <p>
        Troopl only uses functional cookies so that we recognise you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.
      </p>
    )
  },
  {
    headline: '10. How to manage cookies',
    body: (
      <p>
        You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
      </p>
    )
  },
  {
    headline: '11. Cookie declaration',
    body: (
      <script id="CookieDeclaration" src="https://consent.cookiebot.com/3a11b267-c526-4d9d-9c77-4c5e38bdc56e/cd.js" type="text/javascript" async></script>
    )
  },
  {
    headline: '12. Privacy policies of other websites',
    body: (
      <p>
        The Troopl website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
      </p>
    )
  },
  {
    headline: '13. Changes to our privacy policy',
    body: (
      <p>
        Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on July 28, 2021.
      </p>
    )
  },
  {
    headline: '14. How to contact us',
    body: (
      <p>
        If you have any questions about Troopl’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.<br/>
        Email us at <a href='mailto:hi@troopl.com.'>hi@troopl.com.</a><br/>
        Call us on +31 (0) 641 784 408.<br/>
        Or write to us:<br/>
        Scout Works B.V., Bilderdijkstraat 29-2, Amsterdam 1052NA, Noord Holland, The Netherlands.
      </p>
    )
  },
  {
    headline: '15. How to contact the appropriate authority',
    body: (
      <p>
        Should you wish to report a complaint or if you feel that Troopl has not addressed your concern in a satisfactory manner, you may contact the <a href='https://www.autoriteitpersoonsgegevens.nl/en/contact-dutch-dpa/contact-us'>Information Commissioner’s Office.</a><br/>
        Postal Address:<br/>
        Autoriteit Persoonsgegevens<br/>
        PO Box 93374<br/>
        2509 AJ DEN HAAG
      </p>
    )
  }
]
