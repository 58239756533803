import React from 'react'
import ProjectForm from '../forms/ProjectForm'

const ProjectEditPage = () => {
  return (
    <main className='page-content-container'>
      <header>
        <h1>Edit project ⚡️</h1>
        <h5 className='subhead'>Make any changes below!</h5>
      </header>
      <ProjectForm action='edit' />
    </main>
  )
}

export default ProjectEditPage
