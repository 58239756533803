import React, { useState, useEffect } from 'react'
import FetchWrapper from '../../helpers/FetchWrapper'
import Button from '../Button'
import ChallengeCards from '../ChallengeCards'
import Loader from '../Loader'

const ChallengeIndexPage = () => {
  const [challenges, setChallenges] = useState([])
  const [loading, setLoading] = useState(true)
  const [API] = useState(new FetchWrapper)

  useEffect(() => {
    requestChallenges()
    return () => {}
  }, [])

  const requestChallenges = async () => {
    try {
      const response = await API.get('/challenges')
      const data = await response.json()
      setChallenges(data)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <main className='page-content-container'>
      <header>
        <h1>Challenges 🏆</h1>
        <h5 className='subhead challenges'>
          Grow your portfolio and learn new skills!
          Challenges start as Hackathons which can also be completed at your own pace after the event.
        </h5>
      </header>
      {
        loading
        ? <Loader />
        : <ChallengeCards challenges={challenges}/>
      }
      <div className='call-to-action'>
        <h2>Sponsor a hackathon</h2>
          <h5>
            Discover tech talent for your company and inspire emerging developers.
          </h5>
          <a href='mailto:hi@troopl.com'>
            <Button>Get in touch 🤘</Button>
          </a>
      </div>
    </main>
  )
}

export default ChallengeIndexPage
