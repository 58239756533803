import React from 'react'
import DiscoverFilter from '../DiscoverFilter'

const DiscoverPortfoliosPage = () => {
  return (
    <main className='page-content-container discover-page-container'>
      <header>
        <h1>
          Discover <span className='troopl-gradient-text'>portfolios</span>🦄
        </h1>
        <h5 className='subhead'>
          Browse the amazing portfolios on Troopl 🤩
        </h5>
      </header>
      <DiscoverFilter model={'portfolios'} />
    </main>
  )
}

export default DiscoverPortfoliosPage
