export const humanise = (string) => {
  return string.split('_')
               .map((word) => word[0].toUpperCase() + word.slice(1))
               .join(' ')
}

// Returns the last word to return 'Bootcamp' instead of 'Coding Bootcamp'
export const humaniseEducationType = (string) => {
  const splitHumanisedString = humanise(string).split(' ')
  return splitHumanisedString[splitHumanisedString.length - 1]
}

export const safeSlug = (slug) => {
  if (!slug) { return '' }

  return slug.trim()
              .toLowerCase()
              .replaceAll(' ', '-')
              .replaceAll(/[^a-zA-Z0-9-_]/g, '')
}

export const arrayPairs = (initialArray) => {
  return initialArray.reduce((result, value, index, array) => {
    if (index % 2 === 0)
      result.push(array.slice(index, index + 2));
    return result;
  }, []);
}

