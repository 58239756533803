import React, { useContext } from 'react'
import JobCountContext from '../../context/JobCountContext'
import JobList from '../JobList'
import Button from '../Button'

const JobsPage = () => {
  const [jobCount] = useContext(JobCountContext)

  return (
    <main className='page-content-container job-page-container'>
      <header>
        <div className='content'>
          <h1><span className='troopl-gradient-text'>Junior</span> jobs 💪</h1>
          <h5 className='subhead'>
            Browse <span className='job-count'>{ jobCount }</span> entry-level web
            development & data science jobs around the world. New jobs added daily ✌️
          </h5>
          <a
            id='post-a-job-cta'
            href="mailto:hi@troopl.com?subject=Post%20a%20job&body=Share%20your%20job%20with%20us%20and%20we'll%20get%20back%20to%20you."
          >
            <Button extraClasses={['tiny']}>
              Post a job 🦄
            </Button>
          </a>
        </div>
      </header>
      <JobList />
    </main>
  )
}

export default JobsPage
