import React, { useState } from 'react'
import FlashContainer from './FlashContainer'
import SEOMetaData from './SEOMetaData'
import CurrentUserContext from '../context/CurrentUserContext'
import CredentialsContext from '../context/CredentialsContext'
import BotPresentContext from '../context/BotPresentContext'
import JobCountContext from '../context/JobCountContext'
import PlusTemplateContext from '../context/PlusTemplateContext'

const AppContainer = ({globalProps, children}) => {
  const { currentUser, keys, botPresent, jobCount } = globalProps
  const user = useState(currentUser)
  const credentials = useState(keys)
  const bot = useState(botPresent)
  const jobs = useState(jobCount)
  const plusTemplate = useState(null)

  return (
    <CredentialsContext.Provider value={credentials}>
      <BotPresentContext.Provider value={bot}>
        <CurrentUserContext.Provider value={user}>
          <JobCountContext.Provider value={jobs}>
            <PlusTemplateContext.Provider value={plusTemplate}>
              <SEOMetaData />
              <div id='modal-mount' />
              <div className='application-container'>
                {children}
              </div>
              <FlashContainer />
            </PlusTemplateContext.Provider>
          </JobCountContext.Provider>
        </CurrentUserContext.Provider>
      </BotPresentContext.Provider>
    </CredentialsContext.Provider>
  )
}

export default AppContainer
