export const portfolioInputValidation = (id, value) => {
  switch(id) {
    case 'email':
      return validEmailFormat(id, value)
    case 'preferred_name':
    case 'full_name':
    case 'slug':
    case 'bio':
    case 'developer_type':
    case 'education_type':
      return cannotBeBlank(id, value)
    case 'education_name':
      return cannotBeEmpty(id, value)
    case 'picture':
      return validatePortfolioPicture(value)
    case 'cv':
      return validatePortfolioCV(value)
    case 'additional_portfolio_skills':
      return validateAdditionalPortfolioSkills(value)
    default:
      return false
    }
}

// id from multi inputs have an index number on the end, so need to check with .includes
export const portfolioMultiInputValidation = (id, value) => {
  if (
    id.includes('additional_portfolio_skills_text') ||
    id.includes('question_and_answers_question') ||
    id.includes('question_and_answers_answer') ||
    id.includes('references_name') ||
    id.includes('references_job_title') ||
    id.includes('references_headline') ||
    id.includes('references_text')
  ) {
    return cannotBeBlank(id, value, true)
  }

  return false
}

export const projectInputValidation = (id, value) => {
  switch(id) {
    case 'title':
    case 'about':
    case 'project_type':
      return cannotBeBlank(id, value)
    case 'stacks':
      return cannotBeEmpty(id, value)
    case 'notebook':
      return validateNotebook(value)
    case 'presentation':
      return validatePresentation(value)
    case 'uploaded_card_image':
      return validateUploadedCardImage(value)
    default:
      return false
    }
}

export const articleInputValidation = (id, value) => {
  switch(id) {
    case 'title':
    case 'url':
      return cannotBeBlank(id, value)
    default:
      return false
    }
}

const cannotBeBlank = (id, value, multi = false) => {
  if (value == null || value.trim() === '') {
    return `Please provide ${friendly_id(id, multi)} 🙏`
  }

  return false
}

// NOT IN USE
// const educationsCannotBeBlank = (value) => {
//   if (value == null || value.trim() === '') {
//     return `Please provide ${friendly_id(id)} 🙏`
//   }

//   return false
// }

const validEmailFormat = (id, value) => {
  if (value == null || value.trim() === '') {
    return `Please provide a ${friendly_id(id)} 🙏`
  }

  if (!/^[^\s@]+@[^\s@]+$/.test(value)) {
    return `Please provide a valid ${friendly_id(id)} 🙏`
  }

  return false
}

const cannotBeEmpty = (id, value) => {
  if (!value || value.length === 0) {
    return `Please provide ${friendly_id(id)} 🙏`
  }

  return false;
}

const friendly_id = (id, multi = false) => {
  if (multi) {
    return id.split('_').splice(0, id.split('_').length - 1).join(' ')
  }
  return id.split('_').join(' ')
}

// Remember to update the Portfolio Model's picture validation
// to match any changes to this validation
const validatePortfolioPicture = (picture) => {
  if (!['image/png', 'image/jpeg'].includes(picture.type)) {
    return 'Please upload a png or jpeg 🙏'
  }

  if ((picture.size / 1000000) > 5) {
     return 'Please upload an image less than 5mb 🙏'
  }

  return false;
}

// Remember to update the Portfolio Model's cv validation
// to match any changes to this validation
const validatePortfolioCV = (cv) => {
  if (!['application/pdf'].includes(cv.type)) {
    return 'Please upload pdf 🙏'
  }

  if ((cv.size / 1000000) > 4) {
     return 'Please upload a CV less than 4mb 🙏'
  }

  return false;
}

// ipynb files don't include a type, so this is a hacky workaround
const validateNotebook = (notebook) => {
  if (notebook == null) {
    return false
  }

  const splitName = notebook.name.split('.')
  const fileType = splitName[splitName.length - 1]

  if (!['ipynb', 'json'].includes(fileType)) {
    return 'Notebook must be .ipynb or .json format 🙏'
  }

  if ((notebook.size / 1000000) > 20) {
     return 'Notebook must be less than 20mb 🙏'
  }

  return false;
}

const validatePresentation = (presentation) => {
  if (presentation == null) {
    return false
  }

  if (!['application/pdf'].includes(presentation.type)) {
    return 'Presentation must be a pdf 🙏'
  }

  if ((presentation.size / 1000000) > 20) {
     return 'Please upload a presentation less than 20mb 🙏'
  }

  return false;
}

const validateUploadedCardImage = (image) => {
  if (image == null) {
    return false
  }

  if (!['image/png', 'image/jpeg', 'image/gif'].includes(image.type)) {
    return 'Please upload a png, jpeg, or gif 🙏'
  }

  if ((image.size / 1000000) > 5) {
     return 'Please upload an image less than 5mb 🙏'
  }

  return false;
}
