import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const Modal = ({ children, toggle }) => {
  const modalRoot = document.querySelector('#modal-mount')
  const elRef = useRef(null)
  if (!elRef.current) {
    elRef.current = document.createElement('div')
  }

  useEffect(() => {
    modalRoot.appendChild(elRef.current)
    return () => modalRoot.removeChild(elRef.current)
  }, []);

  const handleContentClick = (event) => {
    event.stopPropagation()
  }

  return createPortal(
    <div
      className='modal-container'
      onClick={toggle}
      >
      <div
        className='modal-content'
        onClick={handleContentClick}
      >
        <div
          className='close'
          onClick={toggle}
        >
          +
        </div>
        {children}
      </div>
    </div>,
    elRef.current
  )
}

export default Modal
