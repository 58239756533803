import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
const classNames = require('classnames')

// tags = an array of objects containing {id, name}

const Tags = ({title, tags, buttonSize, extraClasses, layout, heroStacks, emoji}) => {
  const containerClassNames = classNames('tags-container', [extraClasses], layout)

  const handleTagClick = (event) => {
    event.stopPropagation()
  }

  const showHeroStackIcon = (tag) => {
    if (!heroStacks) { return null }

    if (heroStacks.map(stack => stack.name).includes(tag.name)) {
      return 'star-border'
    }

    return null
  }

  if (tags.length == 0) {
    return null
  }

  return (
    <div className={containerClassNames}>
      <h6>{title}</h6>
      {
        tags.map(tag => (
          <Link
            key={ tag.id }
            to={ `/${tag.type}/${tag.slug}` }
          >
            <Button
              extraClasses={[buttonSize || 'small', 'tag']}
              icon={showHeroStackIcon(tag)}
              onClick={handleTagClick}
            >
              { tag.name }
            </Button>
          </Link>
        ))
      }
      { emoji && emoji }
    </div>
  )
}

export default Tags
