import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
const queryString = require('query-string')
import ReactTags from 'react-tag-autocomplete'
import Autocomplete from 'react-google-autocomplete'
import JobCountContext from '../context/JobCountContext'
import CurrentUserContext from '../context/CurrentUserContext'
import CredentialsContext from '../context/CredentialsContext'
import FetchWrapper from '../helpers/FetchWrapper'
import Loader from './Loader'
import Tags from './Tags'
import Button from './Button'
import RadioButtonCards from './RadioButtonCards'
import SEOMetaData from './SEOMetaData'
import Modal from './Modal'
import useShowModal from '../hooks/useShowModal'
import { formatGoogleFilterLocation } from '../helpers/formatGoogleLocation'

const JobList = (props) => {
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [moreJobs, setMoreJobs] = useState(false)
  const [jobsFound, setJobsFound] = useState(0)
  const [searchParams, setSearchParams] = useState({ order: 'desc', stacks: [] })
  const [filterLocation, setFilterLocation] = useState({})
  const [initialLoad, setInitialLoad] = useState(true)
  const [stackSuggestions, setStackSuggestions] = useState([])
  const [stacksFilterParams, setStacksFilterParams] = useState([])
  const [showModal, toggleModal] = useShowModal(props)
  const [jobCount] = useContext(JobCountContext)
  const [currentUser] = useContext(CurrentUserContext)
  const [{ google_maps_api_key }] = useContext(CredentialsContext)
  const [API] = useState(new FetchWrapper)
  let location = useLocation()
  let history = useHistory()

  useEffect(() => {
    if (initialLoad) {
      const urlParams = parsedSearchParams()
      setSearchParams({...searchParams, ...urlParams})
      requestStackSuggestions()
      setInitialLoad(false)
    } else {
      requestJobs()
    }
    return () => {}
  }, [searchParams])


  useEffect(() => {
    if (initialLoad) { return }
    const { city, country, ...newSearchParams } = searchParams
    // Check if location has been cleared from handleLocationClear
    if (filterLocation.type !== undefined) {
      newSearchParams[filterLocation.type] = filterLocation.name
    }
    handleNewSeachParams(newSearchParams)
    return () => {}
  }, [filterLocation])

  useEffect(() => {
    setStacksFilterParams(searchParams.stacks.map((stack, index) => {
      return { type: 'stacks', name: stack, index: index }
    }))
    return () => {}
  }, [searchParams.stacks])

  const requestJobs = async () => {
    try {
      setLoading(true)
      const addOffsetToBody = { ...searchParams, offset: offset }
      const response = await API.post('/jobs-filter', addOffsetToBody, true)
      const data = await response.json()
      // Clear existing jobs when searchParams change
      if (offset == 0) {
        setJobs(data.jobs)
      } else {
        setJobs([...jobs, ...data.jobs])
      }
      setMoreJobs(data.more_jobs)
      setJobsFound(data.job_count)
      if (data.more_jobs) {
        setOffset(offset + 1)
      }
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const requestStackSuggestions = async () => {
    try {
      const response = await API.get('/stacks')
      const data = await response.json()
      setStackSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const parsedSearchParams = () => {
    const params = queryString.parse(location.search, { arrayFormat: 'comma', parseBooleans: true })
    // If 'stacks' has a single value it's parsed as a string --> turn into array
    if (typeof(params['stacks']) == 'string') {
      params['stacks'] = [params['stacks']]
    }

    // set the category
    // if (params['category']) {
    //   params = {...params, role: params['category'] }
    // }

    return params
  }

  // TODO: maybe we should wrap the card in an a link?
  const handleCardClick = (url, id) => {
    API.get(`/jobs/${id}`)
    window.open(url, '_blank')
  }

  const handleNewSeachParams = (newParams) => {
    if (!currentUser) {
      toggleModal()
      return null;
    }
    setSearchParams(newParams)
    updateURLWithParams(newParams)
    setOffset(0)
  }

  const handleOrderChange = (event) => {
    const value = event.target.value
    const { order, ...resetSearchParams } = searchParams
    resetSearchParams['order'] = value
    handleNewSeachParams(resetSearchParams)
  }

  const updateURLWithParams = (params = searchParams) => {
    const { sort, ...reorderSearchParams } = params
    history.push({
      pathname: '/jobs',
      search: '?' + queryString.stringify(
        {...reorderSearchParams, sort},
        {
          arrayFormat: 'comma',
          sort: false,
          skipNull: true,
          skipEmptyString: true
        }
      )
    })
  }

  const handleRoleChange = (event) => {
    handleNewSeachParams({ ...searchParams, role: event.target.value })
  }

  const deselectRadioIfSelected = (event) => {
    const { name, value } = event.target
    if (searchParams[name] == value) {
      handleNewSeachParams({ ...searchParams, [name]: null })
    }
  }

  const handleCategoryChange = (event) => {
    handleNewSeachParams({ ...searchParams, category: event.target.value, role: null })
  }

  const handleLocationSelected = (place) => {
    if (!place.address_components) {
      alert('Pretty please, with a cherry on top, begin typing and select a location from the autocomplete dropdown 🍒🙏')
      return
    }
    setFilterLocation(formatGoogleFilterLocation(place)) // test if i can do this by updating the params
  }

  const handleLocationClear = (event) => {
    if (!searchParams.city && !searchParams.state && !searchParams.country) { return }
    if (event.target.value == '') {
      setFilterLocation({})
    }
  }

  const handleRemoteCheckboxUpdate = (event) => {
    const { checked } = event.target
    const { remote, ...newSearchParams } = searchParams
    if (checked) { newSearchParams['remote'] = true }
    handleNewSeachParams(newSearchParams)
  }

  const handleStackAddition = (stack) => {
    if (searchParams.stacks) {
      const alreadyAdded = searchParams['stacks'].some(addedTag => addedTag === stack.name)
      if (alreadyAdded) { return }
    }
    const safeTags = searchParams.stacks ?? []
    const newTags = [...safeTags, stack.name]
    handleNewSeachParams({ ...searchParams, stacks: newTags })
  }

  const deleteTagFromSearchParams = (index, type) => {
    const newSearchTags = searchParams[type]
    newSearchTags.splice(index, 1)
    handleNewSeachParams({ ...searchParams, [type]: newSearchTags })
  }

  const handleStackDelete = (index) => {
    deleteTagFromSearchParams(index, 'stacks')
    const newStacksParams = stacksFilterParams
    newStacksParams.splice(index, 1)
    setStacksFilterParams(newStacksParams)
  }

  const renderJobFilters = () => {
    return(
      <div className='job-filters-container form-container'>
        <RadioButtonCards
          cards={[
            {
              value: 'web_development',
              emoji: '👨🏼‍🎤',
              text: 'Web Development'
            },
            {
              value: 'data_science',
              emoji: '👩🏻‍🔬',
              text: 'Data Science'
            }
          ]}
          values={searchParams}
          valuesKey='category'
          name='category'
          onChange={handleCategoryChange}
          onClick={deselectRadioIfSelected}
          cardCount='two'
          extraClasses={['job-filter']}
          errors={[]}
        />

        <div className='role-filter-container'>
          {searchParams.category == 'web_development' && (
            <RadioButtonCards
              cards={[
                {
                  value: 'front_end_group',
                  emoji: '🎨',
                  text: 'Front-end'
                },
                {
                  value: 'back_end_group',
                  emoji: '🎒',
                  text: 'Back-end'
                },
                {
                  value: 'full_stack_group',
                  emoji: '🥞',
                  text: 'Full-stack'
                }
              ]}
              values={searchParams}
              valuesKey='role'
              name='role'
              onChange={handleRoleChange}
              onClick={deselectRadioIfSelected}
              cardCount='three'
              extraClasses={['job-filter']}
              errors={[]}
            />
          )}

          {searchParams.category == 'data_science' && (
            <RadioButtonCards
              cards={[
                {
                  value: 'data_scientist',
                  emoji: '🧪',
                  text: 'Data Scientist'
                },
                {
                  value: 'data_analyst',
                  emoji: '🔎',
                  text: 'Data Analyst'
                },
                {
                  value: 'data_engineer',
                  emoji: '🧰',
                  text: 'Data Engineer'
                }
              ]}
              values={searchParams}
              valuesKey='role'
              name='role'
              onChange={handleRoleChange}
              onClick={deselectRadioIfSelected}
              cardCount='three'
              extraClasses={['job-filter']}
              errors={[]}
            />
          )}
        </div>

        <div className='location-filter-container'>
          <label
            htmlFor='location'
            className='location-filter'
          >
            Location
          <Autocomplete
            id='location'
            apiKey={google_maps_api_key}
            defaultValue={searchParams.city || searchParams.country}
            options={{
              fields: ['address_components'],
              types: ['geocode']
            }}
            language='en'
            onPlaceSelected={handleLocationSelected}
            onChange={handleLocationClear}
            type='search'
            autoComplete='off'
            placeholder='City or country'
            />
          </label>

          <div className='checkbox-filters'>
            <label
              htmlFor='remote'
              className='remote-checkbox'
            >
              <input
                id='remote'
                type='checkbox'
                defaultChecked={searchParams.remote}
                onClick={handleRemoteCheckboxUpdate}
              />
              <div className='checkbox-button'>
                <span className='emoji'>🌎</span>
                <span>Remote</span>
              </div>
            </label>
          </div>
        </div>

        <label
          htmlFor='stacks'
          className='stack-filter'
        >
          Tech stack
          <ReactTags
            id='stacks'
            placeholderText='i.e. Ruby'
            minQueryLength={1}
            tags={stacksFilterParams}
            suggestions={stackSuggestions}
            onAddition={handleStackAddition}
            onDelete={handleStackDelete}
          />
        </label>

        <div className='sort-container'>
          <JobsFoundCount />
          <select
            id='sort'
            className='sort-dropdown'
            onChange={handleOrderChange}
            value={searchParams.order}
          >
            <option value={'desc'}>Newest</option>
            <option value={'asc'}>Oldest</option>
          </select>
        </div>
      </div>
    )
  }

  const JobsFoundCount = () => {
    return (
      <div className='jobs-found-container'>
        {
          !loading && jobsFound > 0 && (jobCount !=  Number(jobsFound).toLocaleString()) && (
            <p><span className='count'>{ Number(jobsFound).toLocaleString() }</span> job{jobsFound > 0 ? 's' : ''} match your search 🙌</p>
          )
        }
      </div>
    )
  }

  const SignupMessage = ({ locationClass }) => (
    <div className={`job-signup-message ${locationClass}`}>
      <h5 className='message'>
        Join Troopl to browse all <span className='job-count'>{ jobCount }</span> junior jobs 🚀
      </h5>
      <a id={`job-page-signup-cta-${locationClass}`} href="/signup">
        <Button>Create your free account instantly ⚡️</Button>
      </a>
      <p>
        <a href="/signin">Already joined?</a>
      </p>
    </div>
  )

  const renderInfiniteScrollOrNoJobsFoundOrSignupMessage = () => {
    if (currentUser) {
      if (jobsFound > 0) {
        return (
          <InfiniteScroll
            dataLength={ jobs.length }
            next={ () => requestJobs() }
            hasMore={ moreJobs }
            loader={ <Loader centerLoader /> }
            endMessage={
              <center className='mt-40 mb-20'>
                <p>You've viewed all the jobs 🎉 </p>
                <p>Adjust your filters to find more 🔧</p>
              </center>
            }
          />
        )
      }

      return (
        <div className='no-jobs-found-container'>
          <h5>No jobs match your search 🔍</h5>
          <p className='mt-05'>
            Try broadening your horizons.
          </p>
        </div>
      )
    }

    return <SignupMessage locationClass='job-list' />
  }

  const JobCards = () => (
    jobs.map((job) => {
      const { id, title, company_name, location, remote, published_ago, url, stacks, role_emoji } = job
      return(
        <div
          key={id}
          className='job-card'
          onClick={() => handleCardClick(url, id)}
        >
          <div className='left'>
            <p className='title'>
              <span className='emoji'>{ role_emoji }</span>{ title }
              <span className='title-gradient'>
                <span className='emoji'>{ role_emoji }</span><span className='text'>{ title }</span>
              </span>
            </p>
            <p className='info'>
              <span className='company'>{ company_name }</span> • { remote && 'Remote' } { (remote && location) && '/'} { location }
            </p>
            <Tags
              tags={ stacks }
              buttonSize='job-stack'
            />
          </div>
          <div className='right'>
            <p className='published'>{ published_ago }</p>
          </div>
        </div>
      )
    })
  )

  const JobList = () => (
    <section className='job-list-container'>
      <JobCards />
      { renderInfiniteScrollOrNoJobsFoundOrSignupMessage() }
    </section>
  )

  return (
    <>
      <SEOMetaData
        dynamicTitle={`Jobs | ${jobCount} junior web development & data science opportunities | Troopl`}
        dynamicDescription='Browse junior web developer & data science jobs from around the interet. Filter by role, city, country, remote & tech stack.'
        dynamicKeywords='job career opportunity entry-level web developer data science front-end back-end full-stack data scientist analyst engineer software'
      />
      { renderJobFilters() }
      {
        (loading && offset == 0)
        ? <Loader extraClasses={['mt-40', 'mb-80']} />
        : <JobList />
      }
      { showModal && <Modal toggle={toggleModal}><SignupMessage locationClass='popup' /></Modal> }
    </>
  )
}

export default JobList
