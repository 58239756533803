// This component, as you might expect, returns the window position
// to the top of the page when the url changes.
// There are some urls when we don't want this to happen
// which are listed in the dontScroll array.

import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
     const dontScroll = ['/projects', '/developers', '/portfolios']
      if (dontScroll.find(path => history.location.pathname.includes(path))) {
        return
      }
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return null
}

export default withRouter(ScrollToTop)
