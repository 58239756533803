import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FetchWrapper from '../helpers/FetchWrapper'
import showFlashMessage from '../helpers/showFlashMessage'
const classNames = require('classnames');
import Button from './Button'
import DragToReorder, { useLastDraggedOrder } from './DragToReorder'

const ArticleCards = ({ title, articles, portfolioManage, portfolioShow, handleArticleOrderChange }) => {
  const [lastDraggedOrder, setLastDraggedOrder] = useLastDraggedOrder()
  const [API] = useState(new FetchWrapper)
  const history = useHistory()
  const containerClasses = classNames(
    'article-cards-container',
    { 'portfolio-manage-page': portfolioManage },
    { 'portfolio-show-page': portfolioShow }
  )
  const draggable = portfolioManage && articles.length > 0
  const addArticleCardClasses = classNames('card', 'new', { 'draggable': draggable })

  const handleEditClick = (event) => {
    event.stopPropagation()
    const { id } = event.target.dataset
    history.push(`/articles/${id}/edit`)
  }

  const handleDeleteClick = async (event) => {
    event.stopPropagation()
    const { id } = event.target.dataset

    if (window.confirm('Are you 101% sure you want to delete this article? Just checkin\' as this cannot be undone.')) {
      try {
        await API.delete(`/articles/${id}`)
        const articleToDelete = document.querySelector(`#article-${id}`)
        showFlashMessage('Sayonara! Your article has been deleted from your portfolio.')
        articleToDelete.remove()
      } catch(error) {
          showFlashMessage('Uh-oh! Something went wrong and your project cannot be deleted.', 'warning')
          console.error(error)
        }
      }
  }

  const renderRolloverContent = (id) => (
    <div className='rollover-content'>
        <div className='card-links'>
          <Button
            extraClasses={['small', 'edit']}
            onClick={handleEditClick}
            data-id={id}
          >
            Edit
          </Button>
          <Button
            extraClasses={['small']}
            data-id={id}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </div>
    </div>
  )

  if (portfolioShow && articles.length == 0) { return null }

  return (
    <section className={containerClasses}>
      { title && <h3 className='mb-20'>{title}</h3> }
      <div className='card-grid'>
        {articles.map((article, index) => (
          <DragToReorder
            key={index}
            showDrag={draggable}
            order={article.order}
            handleOrderChange={handleArticleOrderChange}
            lastDraggedOrder={lastDraggedOrder}
            setLastDraggedOrder={setLastDraggedOrder}
            id={`article-${article.id}`}
          >
            <div
              className='card'
              key={index}
            >
              <a
                href={article.url}
                target='_blank'
                rel='noreferrer noopener'
              >
                <h4>{article.title}</h4>
              </a>
              { portfolioManage && renderRolloverContent(article.id) }
            </div>
          </DragToReorder>
        ))}
        {portfolioManage && (
          <div className={addArticleCardClasses}>
            <Link to='/articles/new'>
              <Button icon='add-circle'>Add article</Button>
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}

export default ArticleCards
