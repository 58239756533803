import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Loader from '../Loader'
import FetchWrapper from '../../helpers/FetchWrapper'
import showFlashMessage from '../../helpers/showFlashMessage'
import { articleInputValidation } from '../forms/formValidator'
import Button from '../Button'
import FormInput from '../FormInput'

const ArticleForm = ({ action }) => {
  const [article, setArticle] = useState({})
  const [errors, setErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [API] = useState(new FetchWrapper)
  const history = useHistory()
  const { articleId } = useParams()

  useEffect(async() => {
    try {
      await requestArticle()
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    return () => {}
  }, [])

  useEffect(() => {
    checkFormErrors()
  }, [errors])

  const requestArticle = async () => {
    try {
      const response = await actionSwitch[action].requestResponse()
      const data = await response.json()
      setArticle(data)
    } catch(error) {
      console.error(error)
    }
  }

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setArticle({ ...article, [id]: value })

    if (errors[id] !== false) {
      handleInputErrors(id, value)
    }
  }

  const handleInputBlur = (event) => {
    const {id, value} = event.target
    handleInputErrors(id, value)
  }

  const handleInputErrors = (id, value) => {
    const validationError = articleInputValidation(id, value)
    setErrors({ ...errors, [id]: validationError })
  }

  const anyValidationErrors = () => {
    let anyErrors = false
    const allErrors = {}
    Object.entries(article).forEach(([key, value]) => {
      const validationError = articleInputValidation(key, value)
      if (validationError !== false ) {
        anyErrors = true
        allErrors[key] = validationError
      }
    })
    if (anyErrors) {
      setErrors(allErrors)
      return true
    }
  }

  const checkFormErrors = () => {
    const values = Object.values(errors)
    if (values.length === 0 || values.every(value => value === false)) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
  }

  const setRailsValidationErrors = (errors) => {
    const formattedErrors = {}
    Object.entries(errors).forEach(([key, value]) => {
      formattedErrors[key] = value.join('& ')
    })
    setErrors(formattedErrors)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (anyValidationErrors()) { return }

    try {
      setSubmitting(true)
      const response = await actionSwitch[action].submitResponse()
      const data = await response.json()
      if (response.status !== 422) {
        setArticle(data)
        showFlashMessage(actionSwitch[action].submitSuccessFlash, 'notice')
        history.push({
          pathname: '/portfolios/articles',
          state: {showShareModal: true}
        })
      } else {
        showFlashMessage(actionSwitch[action].submitValidationErrorFlash, 'alert')
        setRailsValidationErrors(data)
      }
    } catch(error) {
      console.error(error)
      showFlashMessage(actionSwitch[action].submitErrorFlash, 'alert')
    } finally {
      setSubmitting(false)
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
  }

  const actionSwitch = {
    new: {
      requestResponse: () => API.get('/articles/new'),
      submitResponse: () => API.post('/articles', article),
      submitSuccessFlash: 'Woohoo! Your article has been added to your portfolio 🚀',
      submitValidationErrorFlash: 'Your article cannot be added just yet.<br/>Please see highlighted fields.',
      submitErrorFlash: `Uh-oh! Something went wrong and your article hasn't been added. Send all angry emails to <a href="mailto:tristan@troopl.com">tristan@troopl.com</a>.`,
      submitButtonText: 'Add article'
    },
    edit: {
      requestResponse: () => API.get(`/articles/${articleId}/edit`),
      submitResponse: () => API.put(`/articles/${articleId}`, article),
      submitSuccessFlash: 'Your article has been updated 🚀',
      submitValidationErrorFlash: 'Your article cannot be updated just yet.<br/>Please see highlighted fields.',
      submitErrorFlash: `Uh-oh! Something went wrong and your article hasn't been updated. Send all angry emails to <a href="mailto:tristan@troopl.com">tristan@troopl.com</a>.`,
      submitButtonText: 'Update article'
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className='form-container article'
    >
      <FormInput
        label='Title*'
        valuesKey='title'
        values={article}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={errors}
        required
      />

      <FormInput
        label='URL*'
        valuesKey='url'
        type='url'
        values={article}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={errors}
        required
      />

      <Button
        extraClasses={['fill-width', 'submit']}
        onClick={handleSubmit}
        errors={hasErrors}
        submitting={submitting}
        submittingText={'Submitting'}
      >
        { actionSwitch[action].submitButtonText }
      </Button>
      {
        hasErrors &&
        <span className='error-message'>Please see highlighted fields above ☝️☝️</span>
      }
    </form>
  )
}

export default ArticleForm
