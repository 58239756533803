import React, { useState, useEffect } from 'react'
const classNames = require('classnames')
import Button from '../components/Button'

const ImageGallery = ({
  images,
  imageOrder,
  handleDeleteImage,
  handleImageOrderChange,
  managePage,
  extraClasses
}) => {
  const [selectedImageName, setSelectedImageName] = useState(imageOrder[0])
  const [dragImageFilename, setDragImageFilename] = useState('')
  const containerClasses = classNames(
    'image-gallery-container',
    { 'manage-page': managePage },
    [extraClasses]
  )

  useEffect(() => {
    if (selectedImageName !== imageOrder[0]) {
      setSelectedImageName(imageOrder[0])
    }
  }, [imageOrder])

  const handleDragStart = (e) => {
    e.currentTarget.classList.add('on-drag')
    setDragImageFilename(getFilename(e))
  }

  const handleDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('on-drag')
    setDragImageFilename('')
  }

  const handleDragEnter = (e) => {
    e.currentTarget.classList.add('over')
  }

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('over')
  }

  // If droped or dragged image are in first position, update hero image to the dropped image
  const handleDrop = (e) => {
    if (imageOrder.indexOf(getFilename(e)) === 0) {
      setSelectedImageName(dragImageFilename)
    }

    if (imageOrder.indexOf(dragImageFilename) === 0) {
      setSelectedImageName(getFilename(e))
    }

    handleImageOrderChange(dragImageFilename, getFilename(e))
    e.currentTarget.classList.remove('over')
  }

  const getFilename = (e) => {
    return e.currentTarget.dataset.filename
  }

  const handleThumbnailClick = (filename) => {
    setSelectedImageName(filename)
  }

  const getHeroImage = () => {
    const heroImage = images.find(image => image.filename === selectedImageName)
    return heroImage ? heroImage.large : null
  }

  const renderManageThumbnail = (image, index) => (
    <div
      className='button-thumbnail-container'
      key={index}
    >
      <Button
        extraClasses={['tiny', 'icon-only', index == 0 && 'hero']}
        icon='close'
        onClick={() => handleDeleteImage(image.filename)}
      >
        { index == 0 && 'Hero' }
      </Button>
      <img
        src={image.thumbnail}
        data-filename={image.filename}
        className='thumbnail'
        alt={`project image thumbnail ${index}`}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      />
    </div>
  )

  const renderShowThumnbail = (image, index) => (
    <img
      key={index}
      src={image.thumbnail}
      className='thumbnail'
      onClick={() => handleThumbnailClick(image.filename)}
      alt={`project thumbnail ${index}`}
    />
  )

  if (images.length === 0 || imageOrder.length === 0) {
    return null
  }

  return (
    <section className={containerClasses}>
      { getHeroImage() && <img src={getHeroImage()} className='hero-image' alt='hero-project-image'/>}
      <div className='thumbnail-container'>
        {
          imageOrder.map((filename, index) => {
            const image = images.find(image => image.filename === filename)
            if (managePage) {
              return renderManageThumbnail(image, index)
            }
            return renderShowThumnbail(image, index)
          })
        }
      </div>
      { managePage && <p className='note mt-10'>Click & drag images to reorder.</p>}
    </section>
  )
}

export default ImageGallery
