import React, { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'
import Button from '../components/Button'
import Loader from '../components/Loader'
import ProjectCards from './ProjectCards'
const classNames = require('classnames')
import FetchWrapper from '../helpers/FetchWrapper'

const ProjectCardImageSelector = ({ project, uploadedImagePreviews, errors, handleProjectInputChange, handleFileChange }) => {
  const [unsplashImages, setUnsplashImages] = useState([])
  const [unsplashError, setUnsplashError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(project.card_image_type !== 'empty' ? project.card_image_type : project.portfolio_type == 'web_development' ? 'project' : 'unsplash')
  const [API] = useState(new FetchWrapper)

  useEffect(() => {
    // Only ping random image Unsplash API
    // when unsplash tab is open and there are no images loaded
    if (currentTab === 'unsplash' && unsplashImages.length === 0) {
      imageSearch()
    }
  }, [currentTab])

  const imageSearch = async (text = null) => {
    try {
      setLoading(true)
      setUnsplashError(false)
      const endpoint = text
        ? `/unsplash_image_search/${text}`
        : '/unsplash_image_search'
      const response = await API.get(endpoint)
      if(response.status == 200) {
        const data = await response.json()
        setUnsplashImages(data)
      } else {
        setUnsplashError(true)
      }
    } catch(error) {
      console.error(error)
      setUnsplashError(true)
    } finally {
      setLoading(false)
    }
  }

  const imageOptionButtonClasses = (tab) => (
    classNames('tiny', { selected: tab == currentTab })
  )

  const showTab = (tab) => tab == currentTab

  const handleSearchChange = (event) => {
    const { value } = event.target
    debouncedImageSearch(value)
  }

  const handleThumbnailClick = (cardImageType, valueKey, value, id) => {
    handleProjectInputChange({
      card_image_type: cardImageType,
      [valueKey]: value,
      uploaded_card_image: null,
      card_image: selectCardImage(cardImageType, value)
    })

    // Ping Unsplash that the image has been downloaded
    if (cardImageType == 'unsplash') {
      API.get(`/unsplash_image_search/track/${id}`)
    }
  }

  const selectCardImage = (cardImageType, value) => {
    if (cardImageType == 'unsplash') {
      return value
    }

    if (cardImageType == 'project') {
      const selectedImage = uploadedImagePreviews.find(image => image.filename == value)
      return selectedImage.large
    }
  }

  const handleRemoveButtonClick = () => {
    handleProjectInputChange({
      card_image_type: 'empty',
      card_unsplash_image_url: null,
      card_project_image_name: null,
      uploaded_card_image: null,
      card_image: null
    })
  }

  const debouncedImageSearch = debounce((text) => {
    imageSearch(text)
  }, 600)

  const gridImageClasses = (value) => {
    const selected = (project.card_image_type == 'unsplash' && project.card_unsplash_image_url == value)
                     || (project.card_image_type == 'project' && project.card_project_image_name == value)

    return classNames('image', { selected: selected })
  }

  const renderImageGrid = (images, cardImageType, valueKey) => {
    if (cardImageType == 'unsplash' && images.length == 0) {
      return (
        <p className='small'>No images found. Try another search 🔍</p>
      )
    }

    return (
      <div className='image-grid'>
        {images.map((i, index) => {
          const { user_name, user_link, thumbnail, image, filename, id } = i
          return (
            <div className='image-display' key={index}>
              <img
                src={thumbnail}
                className={gridImageClasses(image || filename)}
                onClick={() => handleThumbnailClick(cardImageType, valueKey, (image || filename), (id || null))}
                draggable='false'
              />
              {(user_link && user_name) && (
                <p className='text tiny'>
                  By <a href={user_link} target='blank' rel='noreferrer noopener'>{user_name}</a><br/>
                  on <a href='https://unsplash.com/?utm_source=troopl&utm_medium=referral' target='blank' rel='noreferrer noopener'>Unsplash</a>
                </p>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const renderUnsplashTab = () => {
    if (unsplashError) {
      return (
        <p className='small'>Uhoh, something went wrong. We can't show Unsplash images right now.</p>
      )
    }

    return (
      <>
        <input
          type='text'
          placeholder='Search for an image'
          onChange={handleSearchChange}
        />
        {
          loading
          ? <Loader />
          : renderImageGrid(unsplashImages, 'unsplash', 'card_unsplash_image_url')
        }
      </>
    )
  }

  const renderProjectTab = () => {
    if (uploadedImagePreviews.length == 0) {
      return (
        <p className='small'>
          Upload <b>project images</b> (above) to use as your card image.<br/>
          Or search <b>Unsplash</b> or <b>Upload</b> an image only for the card.
        </p>
      )
    }

    return renderImageGrid(uploadedImagePreviews, 'project', 'card_project_image_name')
  }

  const renderUploadTab = () => (
    <div
      className='input-container card-image-selector'
      aria-invalid={!!errors['uploaded_card_image']}
    >
      <label htmlFor='uploaded_card_image' className='button-container center small'>
        Select image
        <input
          id='uploaded_card_image'
          type='file'
          hidden
          aria-invalid={!!errors['uploaded_card_image']}
          onChange={handleFileChange}
        />
      </label>
      {errors['uploaded_card_image'] && <span className='note error'>{errors['uploaded_card_image']}</span>}
    </div>
  )

  return (
    <div className='project-card-image-selector-container'>
       <label>
          Project card image
          <span className='note mb-10'>
            Choose a nice image for your project's card.
          </span>
        </label>
        <div className='image-selector-container'>
          <div className='tab-selector'>
            <div className='image-options'>
              {project.portfolio_type !== 'data_science' && (
                <Button
                  extraClasses={[imageOptionButtonClasses('project')]}
                  onClick={() => setCurrentTab('project')}
                  icon='camera'
                >
                  Project images
                </Button>
              )}
              <Button
                extraClasses={[imageOptionButtonClasses('unsplash')]}
                onClick={() => setCurrentTab('unsplash')}
                icon='unsplash'
              >
                Unsplash
              </Button>
              <Button
                extraClasses={[imageOptionButtonClasses('upload')]}
                onClick={() => setCurrentTab('upload')}
                icon='upload'
              >
                Upload
              </Button>
            </div>
            <Button
              extraClasses={['tiny']}
              onClick={handleRemoveButtonClick}
            >
              Remove
            </Button>
          </div>
          <div className='tab-container'>
            { showTab('unsplash') && renderUnsplashTab() }
            { showTab('project') && renderProjectTab() }
            { showTab('upload') && renderUploadTab() }
          </div>
        </div>

      <label>
        Project card preview
        <span className='note mb-10'>
          Happy with how this will look in your portfolio and on Troopl?
        </span>
      </label>
      <ProjectCards
        projects={[project]}
        projectCardPreview
      />
    </div>
  )
}

export default ProjectCardImageSelector
