import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.resetErrorOnChange !== this.props.resetErrorOnChange) {
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.onErrorCallback) {
      this.props.onErrorCallback()
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.onErrorDisplay ? this.props.onErrorDisplay : null
    }

    return this.props.children
  }
}

export default ErrorBoundary
