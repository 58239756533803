import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import ReactTags from 'react-tag-autocomplete'
import ImageGallery from '../ImageGallery'
import Loader from '../Loader'
import FetchWrapper from '../../helpers/FetchWrapper'
import showFlashMessage from '../../helpers/showFlashMessage'
import { projectInputValidation } from '../forms/formValidator'
import Button from '../Button'
import FormInput from '../FormInput'
import JupyterViewer from 'react-jupyter-notebook'
import MarkdownHighlighter from '../MarkdownHighlighter'
import ErrorBoundary from '../ErrorBoundary'
import ProjectCardImageSelector from '../ProjectCardImageSelector'

const ProjectForm = ({ action, challengeSubmission }) => {
  const [project, setProject] = useState({})
  const [imagePreviews, setImagePreviews] = useState([])
  const [imageOrder, setImageOrder] = useState([])
  const [presentationPreview, setPresentationPreview] = useState('')
  const [stackSuggestions, setStackSuggestions] = useState([])
  const [challengeSuggestions, setChallengeSuggestions] = useState([])
  const [errors, setErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [API] = useState(new FetchWrapper)
  const history = useHistory()
  const { projectId } = useParams()

  useEffect(async () => {
    try {
      await requestProject()
      await requestStackSuggestions()
      await requestChallengeSuggestions()
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    return () => {}
  }, [])

  useEffect(() => {
    checkFormErrors()
  }, [errors])

  const requestProject = async () => {
    try {
      const response = await actionSwitch[action].requestResponse()
      const data = await response.json()
      if (challengeSubmission) {
        setProject({ ...data, challenge_id: challengeSubmission })
      } else {
        setProject(data)
      }
      setImagePreviews(data.image_previews)
      setImageOrder(data.image_order)
      setPresentationPreview(data.display_presentation)
    } catch(error) {
      console.error(error)
    }
  }

  const requestStackSuggestions = async () => {
    try {
      const response = await API.get('/stacks')
      const data = await response.json()
      setStackSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const requestChallengeSuggestions = async () => {
    try {
      const response = await API.get('/challenges')
      const data = await response.json()
      setChallengeSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setProject({ ...project, [id]: value })

    if (errors[id] !== false) {
      handleInputErrors(id, value)
    }
  }

  const handleGenericInputChange = (newValues) => {
    setProject({ ...project, ...newValues })
  }

  const handleFileChange = (event) => {
    const { id, files } = event.target
    const [file] = files
    const newProject = {  ...project, [id]: file }
    setProject(newProject)

    if (id === 'presentation') {
      setPresentationPreview(`${URL.createObjectURL(file)}#toolbar=0`)
    }

    // If a notebook in the correct format
    if (id === 'notebook' && !projectInputValidation(id, file)) {
      createNotebookPreview(file, newProject)
    }

    if (id === 'uploaded_card_image' && !projectInputValidation(id, file)) {
      setProject({ ...newProject, card_image: URL.createObjectURL(file), card_image_type: 'upload' })
    }

    handleInputErrors(id, file)
  }

  const handleRemoveFile = (id) => {
    if (id == 'notebook') {
      setProject({ ...project, [id]: null, display_notebook: null})
    }

    if (id == 'presentation') {
      setProject({ ...project, [id]: null })
      setPresentationPreview(null)
    }

    setErrors({ ...errors, [id]: false })
  }

  const createNotebookPreview = async (notebookFile, newProject) => {
    try {
      const response = await API.post('/notebook_previews', { notebook: notebookFile }, true)
      const data = await response.json()
      setProject({ ...newProject, display_notebook: data })
    } catch(error) {
      console.error(error)
      handleNotebookError()
    }
  }

  const handleNotebookError = () => {
    setErrors({ ...errors, notebook: 'Please select a valid .json or .ipynb file 🙏' })
  }

  const handleInputBlur = (event) => {
    const {id, value} = event.target
    handleInputErrors(id, value)
  }

  const handleInputErrors = (id, value) => {
    const validationError = projectInputValidation(id, value)
    setErrors({ ...errors, [id]: validationError })
  }

  const handleStackAddition = (stack) => {
    const tagAlreadyAdded = project.stacks.some(addedStack => addedStack.name === stack.name)
    if (!tagAlreadyAdded) {
      const newStacks = [...project.stacks, {...stack, name: stack.name.trim() }]
      setProject({ ...project, stacks: newStacks })
      handleInputErrors('stacks', newStacks)
    }
  }

  const handleStackDelete = (index) => {
    const newStacks = project.stacks
    newStacks.splice(index, 1)
    setProject({ ...project, stacks: newStacks })
    handleInputErrors('stacks', newStacks)
  }

  const anyValidationErrors = () => {
    let anyErrors = false
    const allErrors = {}
    Object.entries(project).forEach(([key, value]) => {
      const validationError = projectInputValidation(key, value)
      if (validationError !== false ) {
        anyErrors = true
        allErrors[key] = validationError
      }
    })
    if (anyErrors) {
      setErrors(allErrors)
      return true
    }
  }

  const checkFormErrors = () => {
    const values = Object.values(errors)
    if (values.length === 0 || values.every(value => value === false)) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
  }

  const createFile = (bits, name) => {
    try {
      return new File(bits, name);
    } catch (e) {
      var myBlob = new Blob(bits);
      myBlob.lastModified = new Date();
      myBlob.name = name;
      return myBlob;
    }
  }

  // If new file(s) share filename with existing imagePreview or other existing file(s)
  // add '_dup' to end of file.name
  const renameDuplicateFiles = (files) => {
    let newFiles = [...files]
    const allFilenames = [...imagePreviews.map(ip => ip.filename), ...newFiles.map(f => f.name)]
    const uniqueFilenames = new Set(allFilenames)

    if (uniqueFilenames.size !== allFilenames.length) {
      const duplicateFilenames = allFilenames.filter(filename => {
        if (uniqueFilenames.has(filename)) {
          uniqueFilenames.delete(filename)
        } else {
          return filename
        }
      })
      newFiles = [...files].map((file) => {
        if (duplicateFilenames.includes(file.name)) {
          const split = file.name.split('.')
          const randomString = Math.random().toString(36).replace(/[^a-z]+/g, '')
          return createFile([file], `${split[0]}_${randomString}.${split[split.length - 1]}`)
        } else {
          return file
        }
      })
    }
    return newFiles
  }

  const handleMultipleImageChange = (event) => {
    const { files } = event.target
    const newFiles = renameDuplicateFiles(files)
    const createImagePreviews = newFiles.map(file => (
      {
        large: URL.createObjectURL(file),
        thumbnail: URL.createObjectURL(file),
        filename: file.name
      }
    ))
    setImagePreviews([ ...imagePreviews, ...createImagePreviews])

    const newImageFilenames = newFiles.map(file => file.name)
    const newImageOrder = [...imageOrder, ...newImageFilenames]
    setImageOrder(newImageOrder)

    const alreadyUploadedImages = project.images ?? []
    const joinedImages = [...Array.from(alreadyUploadedImages), ...newFiles]
    setProject({  ...project, images: joinedImages })
  }

  const handleImageOrderChange = (dragImageFilename, dropImageFilename) => {
    const tempImageOrder = imageOrder
    const dragImageIndex = imageOrder.indexOf(dragImageFilename)
    const dropImageIndex = imageOrder.indexOf(dropImageFilename)
    tempImageOrder[dragImageIndex] = dropImageFilename
    tempImageOrder[dropImageIndex] = dragImageFilename
    setImageOrder(tempImageOrder)
  }

  const handleDeleteImage = (filename) => {
    // Remove filename from image order array
    const newImageOrder = imageOrder.filter(i => i !== filename)
    setImageOrder(newImageOrder)

    // Remove file from images, if it exists
    if (project.images) {
      const newImages = project.images.filter(i => i.name !== filename )
      setProject({  ...project, images: newImages })
    }

    // Remove image preview (used in card image selector)
    const newImagePreviews = imagePreviews.filter(ip => ip.filename !== filename)
    setImagePreviews(newImagePreviews)

    // If card image was removed, remove card image from project
    if (filename === project.card_project_image_name) {
      setProject({ ...project, card_image: '', card_project_image_name: '' })
    }
  }

  const setRailsValidationErrors = (errors) => {
    const formattedErrors = {}
    Object.entries(errors).forEach(([key, value]) => {
      formattedErrors[key] = value.join('& ')
    })
    setErrors(formattedErrors)
  }

  const stringifyProject = (send_removed_images) => {
    const projectString = {
      ...project,
      stacks: JSON.stringify(project.stacks),
      image_order: JSON.stringify(imageOrder)
    }
    if (send_removed_images) {
      const images = project.image_order.filter(filename => !imageOrder.includes(filename))
      projectString['removed_images'] = JSON.stringify(images)
    }
    return projectString
}

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (anyValidationErrors()) { return }

    try {
      setSubmitting(true)
      const response = await actionSwitch[action].submitResponse()
      const data = await response.json()
      if (response.status !== 422) {
        setProject(data)
        showFlashMessage(actionSwitch[action].submitSuccessFlash, 'notice')

        // If this the first project added, return to the manage portfolio page
        if (data.only_project_in_portfolio) {
          history.push('/portfolios/manage')
          document.querySelector('nav').scrollIntoView({ behavior: 'smooth' }) // Scroll to top of page
          showFlashMessage('Congratulations on setting up your portfolio 🎉')
        } else {
          history.push({
            pathname: '/portfolios/projects',
            state: {showShareModal: true}
          })
        }

      } else {
        showFlashMessage(actionSwitch[action].submitValidationErrorFlash, 'alert')
        setRailsValidationErrors(data)
      }
    } catch(error) {
      console.error(error)
      showFlashMessage(actionSwitch[action].submitErrorFlash, 'alert')
    } finally {
      setSubmitting(false)
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
  }

  const actionSwitch = {
    new: {
      requestResponse: () => API.get('/projects/new'),
      submitResponse: () => API.post('/projects', stringifyProject(false), true),
      submitSuccessFlash: 'Woohoo! Your project has been created 🚀',
      submitValidationErrorFlash: 'Your project cannot be created just yet.<br/>Please see highlighted fields.',
      submitErrorFlash: `Uh-oh! Something went wrong and your project hasn't been created. Send all angry emails to <a href="mailto:tristan@troopl.com">tristan@troopl.com</a>.`,
      submitButtonText: 'Create project'
    },
    edit: {
      requestResponse: () => API.get(`/projects/${projectId}/edit`),
      submitResponse: () => API.put(`/projects/${projectId}`, stringifyProject(true), true),
      submitSuccessFlash: 'Your project has been updated 🚀',
      submitValidationErrorFlash: 'Your project cannot be updated just yet.<br/>Please see highlighted fields.',
      submitErrorFlash: `Uh-oh! Something went wrong and your project hasn't been updated. Send all angry emails to <a href="mailto:tristan@troopl.com">tristan@troopl.com</a>.`,
      submitButtonText: 'Update project'
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className='form-container project'
    >

      <FormInput
        label='Title*'
        valuesKey='title'
        values={project}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={errors}
        required
      />

      <label htmlFor='challenge_id'>
        <Link to='/challenges'>Challenge</Link> submission 🏆
        <select
          id='challenge_id'
          name='challenge_id'
          onChange={handleInputChange}
          value={project.challenge_id ?? undefined}
        >
          <option value=''>Select challenge, if relevant</option>
          {challengeSuggestions.map(({id, title, company: { name } }) => (
            <option
              value={id}
              key={id}
            >
              {name}: {title}
            </option>
          ))}
        </select>
      </label>

      <FormInput
        label='Project type*'
        valuesKey='project_type'
        placeholder={'Web app, API, Landing page, etc'}
        values={project}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={errors}
        required
      />

      <FormInput
        label='Code repository url'
        placeholder='www.github.com/your/awesome-project'
        valuesKey='code_url'
        values={project}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={errors}
      />

      <FormInput
        label='Production url'
        placeholder='www.awesome-project.com'
        valuesKey='url'
        values={project}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        errors={errors}
      />

      <label
        htmlFor='stacks'
        aria-invalid={!!errors.stacks}
      >
        Tech stack*
        <ReactTags
          id='stacks'
          placeholderText='Add stack'
          allowNew
          newTagText='Add: '
          minQueryLength={1}
          tags={project.stacks}
          suggestions={stackSuggestions}
          onAddition={handleStackAddition}
          onDelete={handleStackDelete}
          required
          />
        {errors['stacks'] && <span className='note error'>{errors['stacks']}</span>}
      </label>

      <div
        className='input-container'
        aria-invalid={!!errors['images']}
      >
        <label>
          Project images
          <span className='note mb-10'>
            Add screenshots and images to show how your project works.
          </span>
        </label>
        <label
          htmlFor='images'
          className='button-container center'
        >
        Add images
          <input
            id='images'
            type='file'
            hidden
            aria-invalid={!!errors['images']}
            onChange={handleMultipleImageChange}
            multiple
          />
        </label>
        {errors['images'] && <span className='note error'>{errors['images']}</span>}
      </div>
      {imagePreviews.length > 0 && (
        <>
          <h6 className='mb-10 label troopl-gradient-text'>Images added</h6>
          <ImageGallery
            images={imagePreviews}
            imageOrder={imageOrder}
            handleImageOrderChange={handleImageOrderChange}
            handleDeleteImage={handleDeleteImage}
            managePage
          />
        </>
      )}

      {
        project.portfolio_type == 'data_science' && (
          <>
            <div
              className='input-container'
              aria-invalid={!!errors['notebook']}
            >
              <label>
                Notebook
                <span className='note mb-10'>
                  Download and share as a <b>.json</b> or <b>.ipyrb</b> file. <a href='https://troopl.notion.site/Get-started-with-Troopl-57eee99e7cbd463f8e8fe5749d7c3207#77e092f16a394d9d889d0faba108bb9e' target='blank' className='link'>Here's how.</a>
                </span>
              </label>
              <div className='side-by-side'>
                <label
                  htmlFor='notebook'
                  className='button-container fill-width-just-mobile'
                >
                  {`${project.display_notebook ? 'Change' : 'Add'} notebook`}
                  <input
                    id='notebook'
                    type='file'
                    hidden
                    onChange={handleFileChange}
                  />
                </label>
                { (project.notebook || project.display_notebook) && (
                  <Button
                    extraClasses={['right', 'fill-width-just-mobile']}
                    onClick={() => handleRemoveFile('notebook')}
                  >
                    Remove
                  </Button>
                )}
              </div>
              {errors['notebook'] && <span className='note error'>{errors['notebook']}</span>}
            </div>

            { project.display_notebook && (
                <ErrorBoundary
                  onErrorCallback={handleNotebookError}
                  resetErrorOnChange={project.display_notebook}
                >
                  <h6 className='mb-10 label troopl-gradient-text'>Notebook added</h6>
                  <div className='notebook-preview'>
                    <MarkdownHighlighter>
                      <JupyterViewer
                        rawIpynb={project.display_notebook}
                        language='python'
                        displaySource='auto'
                        displayOutput='auto'
                        showLineNumbers={false}
                      />
                    </MarkdownHighlighter>
                  </div>
                </ErrorBoundary>
              )}

            <div
              className='input-container'
              aria-invalid={!!errors['presentation']}
            >
              <label>
                Presentation
                <span className='note mb-05'>
                  Support your insights and computations with a <b>.pdf</b> presentation.<br/>
                  Or don't, totally up to you 😅
                </span>
              </label>
              <div className='side-by-side'>
                <label htmlFor='presentation' className='button-container fill-width-just-mobile'>
                  {
                    presentationPreview
                    ? 'Change presentation'
                    : 'Add presentation'
                  }
                  <input
                    id='presentation'
                    type='file'
                    hidden
                    onChange={handleFileChange}
                  />
                </label>
                { (project.presentation || presentationPreview) && (
                  <Button
                    extraClasses={['right', 'fill-width-just-mobile']}
                    onClick={() => handleRemoveFile('presentation')}
                  >
                    Remove
                  </Button>
                )}
              </div>
              {errors['presentation'] && <span className='note error file'>{errors['presentation']}</span>}
            </div>

            {presentationPreview && (
              <>
                <h6 className='mb-10 label troopl-gradient-text'>Presentation added</h6>
                <div
                  className='project-presentation-container form-preview'
                  aria-invalid={!!errors['presentation']}
                >
                  <object data={presentationPreview} type='application/pdf'>
                      Uh-oh. We cannot preview your presentation.
                  </object>
                </div>
              </>
            )}
          </>
        )
      }

      <label htmlFor='about'>
        About*
        <textarea
          id='about'
          value={project.about ?? ''}
          placeholder="Share a little about this project. How does it work? Why did you choose to work on it? Did you run into any interesting challenges?"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          aria-required
          aria-invalid={!!errors['about']}
          rows={8}
        />
        {errors['about'] && <span className='note error'>{errors['about']}</span>}
      </label>

      <ProjectCardImageSelector
        handleProjectInputChange={handleGenericInputChange}
        project={project}
        uploadedImagePreviews={imagePreviews}
        handleFileChange={handleFileChange}
        errors={errors}
      />

      <Button
        extraClasses={['fill-width', 'submit']}
        onClick={handleSubmit}
        errors={hasErrors}
        submitting={submitting}
        submittingText={'Submitting'}
      >
        { actionSwitch[action].submitButtonText }
      </Button>
      {
        hasErrors &&
        <span className='error-message'>Please see highlighted fields above ☝️☝️</span>
      }
    </form>
  )
}

export default ProjectForm
