import React from 'react'
import { privacyPolicy } from '../../copy/privacyPolicy'

const PrivacyPage = () => {
  return (
    <main className='page-content-container legal-page-container'>
      <header>
        <h1>Privacy policy 👨🏻‍🚀</h1>
        <h5 className='subhead'>
          We take your privacy seriously.
        </h5>
      </header>
      {
        privacyPolicy.map(({headline, body}, index) => (
          <section key={index}>
            <h3>{headline}</h3>
            {body}
          </section>
        ))
      }
    </main>
  )
}

export default PrivacyPage
