import React, { useState } from 'react'

const useShowModal = (props) => {
  const showShareModal = props?.location?.state?.showShareModal || false
  const [showModal, setShowModal] = useState(showShareModal)
  const toggleModal = () => setShowModal(!showModal)

  return [showModal, toggleModal]
}

export default useShowModal
