import React from 'react'
const classNames = require('classnames')

const TrooplPlusIcon = ({size}) => {
  const iconClasses = classNames('troopl-plus-icon', size)
  return (
    <span className={iconClasses}>Plus 👑</span>
  )
}

export default TrooplPlusIcon
