import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import FetchWrapper from '../../helpers/FetchWrapper'
import ProjectCards from '../ProjectCards'
import Loader from '../Loader'

const TagIndexPage = () => {
  const [stack, setStack] = useState({})
  const [loading, setLoading] = useState(true)
  const [API] = useState(new FetchWrapper)
  const {stackSlug} = useParams()

  useEffect(() => {
    requestData()
    return () => {}
  }, [])

  useEffect(() => {
    setStack([])
    setLoading(true)
    requestData()
  }, [stackSlug])

  const requestData = async () => {
    try {
      const response = await API.get(`/stacks/${stackSlug}`)
      const data = await response.json()
      setStack(data)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loader pageLoader />
  }

  return (
    <main className='page-content-container tag-show-page-container'>
      <header>
        <Link to={'/stacks'}>
          <h3 className='overhead'>Stacks 🥞</h3>
        </Link>
        <h1>{stack.name}</h1>
      </header>
      <ProjectCards
        title={'Projects'}
        projects={stack.projects}
      />
    </main>
  )
}

export default TagIndexPage
