import React from 'react'

const Page404 = () => {
  return (
    <main className='page-content-container page-404-container'>
      <header>
        <h1>
          Page not found 🔍
        </h1>
        <h5 className='subhead'>
          We looked everywhere, but we just can't find it.
        </h5>
      </header>
      <img src='https://media.giphy.com/media/hEc4k5pN17GZq/source.gif' className='gif'/>
    </main>
  )
}

export default Page404

