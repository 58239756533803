import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import JupyterViewer from 'react-jupyter-notebook'
import MarkdownHighlighter from '../MarkdownHighlighter'
import FetchWrapper from '../../helpers/FetchWrapper'
import ImageGallery from '../ImageGallery'
import Stats from '../Stats'
import Tags from '../Tags'
import Button from '../Button'
import Loader from '../Loader'
import Modal from '../Modal'
import Share from '../Share'
import ErrorBoundary from '../ErrorBoundary'
import SEOMetaData from '../SEOMetaData'
import useShowModal from '../../hooks/useShowModal'

const PortfolioShowPage = (props) => {
  const { portfolioSlug, projectSlug } = useParams()
  const [project, setProject] = useState({})
  const [loading, setLoading] = useState(true)
  const [showModal, toggleModal] = useShowModal(props)
  const [API] = useState(new FetchWrapper)

  useEffect(() => {
    requestProject()
    return () => {}
  }, [])

  const requestProject = async () => {
    try {
      const response = await API.get(`/${portfolioSlug}/${projectSlug}`)
      const data = await response.json()
      setProject(data)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loader pageLoader />
  }

  return (
    <>
      <SEOMetaData
        dynamicTitle={`${project.title} | ${project.project_type} | ${project.portfolio_full_name} | Troopl`}
        dynamicDescription={ project.about.split('.').slice(0,2) } // first two sentences
        dynamicImage={ project.card_image }
      />

      <main className='page-content-container project-show-page-container'>
        <header>
          {/* TODO: Challenge card goes here if submitted as part of a challenge */}
          <h4 className='type'>{ project.project_type }</h4>
          <h1 className='title'>{ project.title }</h1>
          <Link to={`/${project.portfolio_slug}`}>
            <div className='portfolio-link'>
              {project.portfolio_picture &&
                <img
                  className='picture'
                  src={project.card_portfolio_picture}
                  alt='portfolio-picture'
                />
              }
              <h6 className='portfolio-full-name'>{project.portfolio_full_name}</h6>
            </div>
          </Link>

          <div className='project-links'>
            {project.code_url && (
              <a href={project.code_url} target='_blank'>
                <Button
                  extraClasses={['view-code-button', 'fill-width-just-mobile']}
                  icon='github-icon'
                >
                  View code
                </Button>
              </a>
            )}
            {project.url && (
              <a href={project.url} target='_blank'>
                <Button
                  extraClasses={['view-project-button', 'fill-width-just-mobile']}
                  icon='compass'
                >
                  View project
                </Button>
              </a>
            )}
            <Button
              icon='share'
              onClick={toggleModal}
              extraClasses={['fill-width-just-mobile', 'share']}
              id='project-share-cta'
            >
              Share project
            </Button>
          </div>
        </header>

        <section className='information'>
          <div className='left-section'>
            <h4 className='heading'>Tech stack</h4>
            <Tags
              tags={project.stacks}
            />
          </div>
          <div className='right-section'>
            <div className='about'>
              <h4 className='heading'>About</h4>
              <p className='text'>{project.about}</p>
            </div>
            <Stats views={project.total_views} />
          </div>
        </section>

        {
          project.display_presentation && (
            <div className='project-presentation-container'>
              <object data={project.display_presentation} type='application/pdf'>
                  Uh-oh. We cannot display this presentation.
              </object>
            </div>
          )
        }

        <ImageGallery
          images={project.image_previews}
          imageOrder={project.image_order}
          extraClasses={['mb-60']}
        />

        {
          project.display_notebook && (
            <ErrorBoundary
              onErrorDisplay={
                <p className='notebook-error'>
                  ⚠️ Uhoh. Something went wrong and this notebook cannot be displayed.
                </p>
              }
            >
              <MarkdownHighlighter>
                <JupyterViewer
                  rawIpynb={project.display_notebook}
                  language='python'
                  displaySource='auto'
                  displayOutput='auto'
                  showLineNumbers={false}
                />
              </MarkdownHighlighter>
            </ErrorBoundary>
          )
        }

        <section className='portfolio-button-container'>
          <Link to={`/${project.portfolio_slug}`}>
            <Button
              icon='star-border'
              id='project-view-portfolio-cta'
            >
              See {project.portfolio_full_name}'s portfolio
            </Button>
          </Link>
        </section>
      </main>

      {
        showModal ? (
          <Modal toggle={toggleModal}>
            <Share
              slug={`${project.portfolio_slug}/${project.slug}`}
              type='project'
              fullName={project.portfolio_full_name}
              preferredName={project.portfolio_preferred_name}
              userId={project.user_id}
            />
          </Modal>
        ) : null
      }
    </>
  )
}

export default PortfolioShowPage
