import React from 'react'
// import SEOMetaData from '../../components/SEOMetaData'
import PriceTable from '../PriceTable'

const PricingPage = () => {
  return (
    <>
      {/* <SEOMetaData
        dynamicTitle={`${challenge.title} | Sponsored by ${challenge.company.name} | Troopl challenge`}
        dynamicDescription={ challenge.intro }
      /> */}
      <main className='page-content-container pricing-page-container'>
        <header>
          <h1>
            Pricing🍿
          </h1>
          <h5 className='subhead'>
            Two ways to build your kickass portfolio.
          </h5>
        </header>
        <PriceTable location='pricing-page' />
      </main>
    </>
  )
}

export default PricingPage
