import React, { useState, useEffect, useContext } from 'react'
import FetchWrapper from '../helpers/FetchWrapper'
import CurrentUserContext from '../context/CurrentUserContext'
import showFlashMessage from '../helpers/showFlashMessage'
import Button from './Button'
import PriceTable from './PriceTable'

const PlusTab = () => {
  const [submitting, setSubmitting] = useState(false)
  const [API] = useState(new FetchWrapper)
  const [currentUser] = useContext(CurrentUserContext)

  useEffect(() => {
    // Check if this is a redirect from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      showFlashMessage("Congratulations! You've just upgraded to Troopl Plus!")
    }

    if (query.get('canceled')) {
      showFlashMessage('Your transaction has been cancelled.', 'warning')
    }
  }, [window.location]);

  const onUpgradeButtonClick = async () => {
    setSubmitting(true)
    try {
      const response = await API.post('/stripe/create-checkout-session',
        { userId: currentUser.id },
        false,
        false
      )
      const data = await response.json()
      if (response.status == 303) {
        window.location.href = data.redirect_to
      } else {
        showFlashMessage('Something went wrong...', 'warning')
      }
    } catch(error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const onManageSubscriptionClick = async () => {
    setSubmitting(true)
    try {
      const response = await API.post('/stripe/create-portal-session', {
        stripe_subscription_id: currentUser.stripe_subscription_id,
        userId: currentUser.id
      }, false, false)
      const data = await response.json()
      if (response.status == 303) {
        window.location.href = data.redirect_to
      } else {
        showFlashMessage('Something went wrong...', 'warning')
      }
    } catch(error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const UpgradeContent = () => (
    <PriceTable
      location='plus-tab'
      onPlusTabButtonClick={onUpgradeButtonClick}
      submitting={submitting}
    />
  )

  const ManageSubscriptionContent = () => (
    <section className='stripe-payment-container'>
      <h5>Congratulations, you have upgraded to Troopl Plus 🚀</h5>
      <p
        className='manage-link'
        onClick={onManageSubscriptionClick}
      >
        Manage your billing information ->
      </p>
    </section>
  )

  if (!currentUser.plus) {
    return <UpgradeContent />
  }

  return <ManageSubscriptionContent />
}

export default PlusTab
