import React from 'react'
import ArticleForm from '../forms/ArticleForm'

const ArticleEditPage = () => {
  return (
    <main className='page-content-container'>
      <header className='no-bottom-margin'>
        <h1>Edit article 📖</h1>
        <h5 className='subhead'>Make any changes below.</h5>
      </header>
      <ArticleForm action='edit' />
    </main>
  )
}

export default ArticleEditPage
