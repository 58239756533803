import { useHistory } from 'react-router-dom'

class FetchWrapper {
  constructor() {
    this.baseUrl = `${window.location.origin}/api/v1`
    this.token = document.querySelector("meta[name='csrf-token']").content
    this.history = useHistory()
  }

  async get(endpoint) {
    const response = await fetch(this.baseUrl + endpoint)
    return this._checkResponse(response)
  }

  put(endpoint, body, createFormData = false, trooplApiCall = true) {
    return this._send('put', endpoint, body, createFormData, trooplApiCall)
  }

  post(endpoint, body, createFormData = false, trooplApiCall = true) {
    return this._send('post', endpoint, body, createFormData, trooplApiCall)
  }

  delete(endpoint, body) {
    return this._send('delete', endpoint, body)
  }

  async _send(method, endpoint, body, createFormData = false, trooplApiCall = true) {
    const sendBody = createFormData ? this._buildFormData(body) : JSON.stringify(body)
    const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.token
      }

    if (!createFormData) {
      headers['Content-Type'] = 'application/json'
    }

    const responseUrl = trooplApiCall ? this.baseUrl : window.location.origin

    const response = await fetch(responseUrl + endpoint, {
      method,
      headers: headers,
      body: sendBody
    })
    return this._checkResponse(response)
  }

  // Append multiple 'images' one at a time
  _buildFormData(body) {
    const formData = new FormData()
    Object.entries(body).forEach(([key, value]) => {
      if (key === 'images') {
        if (!value.length > 0) { return }
        [...value].forEach(image => formData.append('images[]', image))
      } else {
        formData.append(key, value)
      }
    })
    return formData
  }

  _checkResponse(response) {
    if (!response.ok) {
      // Devise
      if (response.status === 401) {
        window.location.href = `${window.location.origin}/signup`;
        return
      }

      // Pundit
      if (response.status === 403) {
        window.location.href = `${window.location.origin}/`;
        return
      }

      // Manually return 404 when project, portfolio, stack isn't found in DB
      if (response.status === 404) {
        return this.history.push('/404')
      }
    }

    return response
  }
}

export default FetchWrapper
