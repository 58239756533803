import React from 'react'
import Loader from '../components/Loader'
const classNames = require('classnames')

const Button = ({
  children,
  extraClasses,
  submit,
  errors,
  submitting,
  submittingText,
  icon,
  locked,
  disabled,
  ...props
}) => {
  const buttonClassName = classNames(
    'button-container',
    { errors: errors },
    { submitting: submitting },
    { locked: locked },
    { disabled: disabled },
    [extraClasses]
  )
  const type = submit ? 'submit' : 'button'
  const iconImage = icon ? require(`images/svg/${icon}.svg`) : null

  return (
    <button
      type={type}
      className={buttonClassName}
      { ...props }
    >
      {
        submitting
        ? <>{submittingText} <Loader extraClasses={['button']} /></>
        : (
          <>
            { iconImage && <img src={iconImage} alt='icon' className='icon'/> }
            { children }
          </>
        )
      }
    </button>
  )
}

export default Button
