import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import FetchWrapper from '../../helpers/FetchWrapper'
import Isabelle from '../portfolio-layouts/Isabelle' // PLUS LAYOUT
import Denis from '../portfolio-layouts/denis/Denis' // FREE LAYOUT
import Loader from '../Loader'
import Modal from '../Modal'
import Share from '../Share'
import SEOMetaData from '../SEOMetaData'
import useShowModal from '../../hooks/useShowModal'

const PortfolioShowPage = (props) => {
  const [portfolio, setPortfolio] = useState([])
  const [projects, setProjects] = useState([])
  const [articles, setArticles] = useState([])
  const [stacks, setStacks] = useState([])
  const [heroStacks, setHeroStacks] = useState([])
  const [references, setReferences] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, toggleModal] = useShowModal(props)
  const [API] = useState(new FetchWrapper)
  const {portfolioSlug} = useParams()

  useEffect(() => {
    getPortfolioData()
    return () => {}
  }, [])

  const getPortfolioData = async () => {
    try {
      const response = await API.get(`/portfolios/${portfolioSlug}`)
      const data = await response.json()
      const {stacks, projects, articles, hero_portfolio_stacks, references, ...portfolio} = data
      setPortfolio(portfolio)
      setStacks(stacks)
      setProjects(projects)
      setArticles(articles)
      setHeroStacks(hero_portfolio_stacks)
      setReferences(references)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const resumeButtonClick = () => {
    window.open(portfolio.cv, '_blank')
  }

  const emailButtonClick = () => {
    window.open(`mailto:${portfolio.email}`, '_blank')
  }

  const SelectPortfolioLayout = () => {
    if (portfolio.layout == 1) {
      return (
        <Denis
          portfolio={portfolio}
          projects={projects}
          stacks={stacks}
          heroStacks={heroStacks}
          articles={articles}
          references={references}
          resumeButtonClick={resumeButtonClick}
          emailButtonClick={emailButtonClick}
          toggleModal={toggleModal}
        />
      )
    }

    return (
      <Isabelle
        portfolio={portfolio}
        projects={projects}
        stacks={stacks}
        articles={articles}
        resumeButtonClick={resumeButtonClick}
        emailButtonClick={emailButtonClick}
        toggleModal={toggleModal}
      />
    )
  }

  const ShareModal = ({ show }) => {
    if (!show) { return null }

    return (
      <Modal toggle={toggleModal}>
        <Share
          slug={portfolio.slug}
          type='portfolio'
          fullName={portfolio.full_name}
          preferredName={portfolio.preferred_name}
          userId={portfolio.user.id}
        />
      </Modal>
    )
  }

  if (loading) {
    return <Loader pageLoader />
  }

  return (
    <>
      <SEOMetaData
        dynamicTitle={`${portfolio.full_name} | ${portfolio.display_developer_type} | Troopl portfolio`}
        dynamicDescription={ portfolio.bio.split('.').slice(0,2) } // first two sentences
      />
      <SelectPortfolioLayout />
      <ShareModal show={showModal} />
    </>
  )
}

export default PortfolioShowPage
