import React, { createRef, useEffect, useState } from 'react'
import Emoji from '../../Emoji'
import pencil from 'images/pencil.png'

const DenisArticleSlider = ({articles}) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const [scrollPercent, setScrollPercent] = useState(0)
  const scrollRef = createRef()
  const cardContainerlRef = createRef()

  useEffect(() => {
    calculateAndSetScrollPercent()
  }, [])

  const calculateAndSetScrollPercent = () => {
    setScrollPercent((getScrollContainerRight() / getCardContainerRight()) * 100)
  }

  const getScrollContainerRight = () => (
    scrollRef?.current?.getBoundingClientRect()?.right + 1
  )

  const getCardContainerRight = () => (
    cardContainerlRef?.current?.getBoundingClientRect()?.right
  )

  const onMouseDown = (event) => {
    setIsScrolling(true)
    setClientX(event.clientX)
  }

  const onMouseUp = () => {
    setIsScrolling(false)
  }

  const onMouseLeave = () => {
    onMouseUp()
  }

  const onMouseMove = (event) => {
    if (isScrolling) {
      const calculateScrollX = scrollX - event.clientX + clientX
      scrollRef.current.scrollLeft = scrollX - event.clientX + clientX;
      setClientX(event.clientX)
      // Prevents overscrolling on X axis
      if (calculateScrollX >= 0 && getCardContainerRight() > getScrollContainerRight()) {
        setScrollX(calculateScrollX)
        calculateAndSetScrollPercent()
      }
    }
  }

  if (articles.length == 0) {
    return null
  }

  return (
    <div className='denis-article-slider-container'>
      <div
        className='slider-section'
        ref={scrollRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onScroll={calculateAndSetScrollPercent}
      >
        <div className='slider-page-boundary'>
          <div
            className='cards-container'
            ref={cardContainerlRef}
          >
            {articles.map(({ title, url }, index) => (
              <div
                className='card'
                key={index}
              >
                <h5>{title}</h5>
                <a
                  href={url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Read ->
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='fixed-section'>
        <div className='fixed-page-boundary'>
           <div className='articles-header'>
            <h6>A good read</h6>
            <h4>Articles, case studies & blog posts</h4>
          </div>
          <div className='scroll-percent-indicator'>
            <div className='fill' style={{width: `${scrollPercent}%`}}></div>
          </div>
          <Emoji name='open-book' />
          <img
            className='pencil large'
            alt='cartoon-ish pencil'
            src={pencil}
          />
          <img
            className='pencil small'
            alt='cartoon-ish pencil'
            src={pencil}
          />
        </div>
      </div>
    </div>
  )
}

export default DenisArticleSlider
