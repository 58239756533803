import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FetchWrapper from '../helpers/FetchWrapper'
import showFlashMessage from '../helpers/showFlashMessage'
const classNames = require('classnames')
import Button from './Button'
import Stats from './Stats'
import DragToReorder, { useLastDraggedOrder } from './DragToReorder'
import { arrayPairs } from '../helpers/general'
import partyPopper from 'images/emoji/party-popper.png'
import eyeSVG from 'images/svg/eye-outline.svg'

const ProjectCards = ({
  projects,
  title,
  portfolioShow,
  portfolioManage,
  discover,
  challengeShow,
  challengeID,
  extraClasses,
  projectCardPreview,
  handleProjectOrderChange,
  portfolioTemplate,
  featuredProject,
  emailButtonClick
  }) => {

  const [API] = useState(new FetchWrapper)
  const history = useHistory()
  const projectCardContainerClasses = classNames(
    'project-card-container',
    { 'portfolio-show': portfolioShow },
    { 'portfolio-manage': portfolioManage },
    { 'discover': discover },
    { 'challengeShow': challengeShow },
    { 'project-card-preview': projectCardPreview },
    { 'featured-project': featuredProject },
    [extraClasses],
    portfolioTemplate
  )
  const [lastDraggedOrder, setLastDraggedOrder] = useLastDraggedOrder()
  const draggable = portfolioManage && projects.length > 0
  const addProjectCardClasses = classNames('card', 'new-project', { 'draggable': draggable })

  const handleCardClick = ({slug, portfolio_slug}) => {
    history.push(`/${portfolio_slug}/${slug}`)
  }

  const handleEditClick = (event) => {
    event.stopPropagation()
    const { id } = event.target.dataset
    history.push(`/projects/${id}/edit`)
  }

  const handleDeleteClick = async (event) => {
    event.stopPropagation()
    const { id, slug } = event.target.dataset

    if (window.confirm('Are you 101% sure you want to delete this project? Just checkin\' as this cannot be undone.')) {
      try {
        await API.delete(`/projects/${id}`)
        const cardToDelete = document.querySelector(`#project-${slug}`) // THIS DELETES NOTHING
        showFlashMessage('Sayonara! Your project has been deleted from your portfolio.')
        cardToDelete.remove()
      } catch(error) {
          showFlashMessage('Uh-oh! Something went wrong and your project cannot be deleted.', 'warning')
          console.error(error)
        }
      }
    }

  const handleTagClick = (event) => {
    event.stopPropagation()
    const { type, slug } = event.target.dataset
    history.push(`/${type}/${slug}`)
  }

  const renderImageOrPlaceholder = (project) => {
    if (project.card_image) {
      return (
        <img
          src={project.card_image} alt={`${project.title} image`}
          className='project-image'
        />
      )
    }

    return (
      <div className='no-image-container'>
        <h3 className='project-type'>{ project.project_type }</h3>
      </div>
    )
  }

  const renderCardLinks = ({id, slug}) => {
    if (!portfolioManage) { return null}

    return (
      <div className='card-links'>
        <Button
          extraClasses={['small', 'edit']}
          onClick={handleEditClick}
          data-id={id}
        >
          Edit
        </Button>
        <Button
          extraClasses={['small']}
          data-id={id}
          data-slug={slug}
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      </div>
    )
  }

  const renderCardTagButtons = (project) => {
    if (!project.stacks) { return null }

    return (
      <div className='card-buttons'>
        {
          project.stacks.map((tag, index) => (
            <Button
              key={index}
              extraClasses={['tiny']}
              data-type={tag.type}
              data-slug={tag.slug}
              onClick={handleTagClick}
            >
              {tag.name}
            </Button>
          ))
        }
      </div>
    )
  }

  const renderBottomSection = (project) => {
    return (
      <div className='project-details-container'>
        <div className='top'>
          <div className='card-title-container'>
            <Link to={`/${project.portfolio_slug}/${project.slug}`}>
              <p className='card-title'>{project.title}</p>
            </Link>
          </div>
          <Stats card views={project.total_views} />
        </div>
        {(!portfolioShow && !portfolioManage) && (
          <div className='bottom'>
            {project.card_portfolio_picture && (
              <img
                className='portfolio-picture'
                src={project.card_portfolio_picture}
                alt={`${project.portfolio_full_name} profile picture`}
              />
            )}
            <Link to={`/${project.portfolio_slug}`}>
              <p className='username'>{project.portfolio_full_name}</p>
            </Link>
          </div>
        )}
      </div>
    )
  }

  // Render an empty cards on Challanges to show the 'Submit your project' button
  // Render nothing on portfolios
  // Render a 'nothing found' message on discover pages
  if (!challengeShow && !projects.length > 0) {
    if (!discover) { return null }

    return (
      <section className={projectCardContainerClasses}>
        <div className='no-projects-found-container'>
          <h3>No projects found 🔍</h3>
          <p className='mt-05'>
            Try broadening your horizons.
          </p>
        </div>
      </section>
    )
  }

  const renderDenisPortfolioProject = (project, index = 1) => (
    <div
      key={index}
      id={project.slug}
      className='card'
    >
      <div className='top-section'>
        { renderImageOrPlaceholder(project) }
        <div className='info'>
          <div className='pill'>{ project.title }</div>
          <div className='pill'><img src={eyeSVG} alt='eye icon'/>{ project.total_views }</div>
        </div>
        <div className='rollover-content' onClick={() => handleCardClick(project)}>
          { renderCardTagButtons(project) }
        </div>
      </div>
    </div>
  )

  // Cards for the Denis Plus Portfolio template
  if (portfolioTemplate == 'denis') {
    const [theFeaturedProject, ...restOfProjects] = projects
    const restOfProjectsInPairs = arrayPairs(restOfProjects)

    if (featuredProject) {
      return (
        <section className={projectCardContainerClasses}>
          <div className='cards'>
            { renderDenisPortfolioProject(theFeaturedProject) }
          </div>
        </section>
      )
    }

    return (
      <section className={projectCardContainerClasses}>
        <div className='cards denis featured'>
          { projects.length > 1 && renderDenisPortfolioProject(theFeaturedProject) }
          <div className='project-cta'>
            <img src={partyPopper} alt='party popper emoji'/>
            <div className='text'>
              <h5>Got a project?</h5>

              <a
                className='lets-talk'
                onClick={emailButtonClick}
              >
                  Let's talk ->
              </a>
            </div>
          </div>
        </div>
        {restOfProjectsInPairs.map((projectPair, index) => (
          <div
            className='cards denis pair'
            key={index}
            style={{order: (index + 1) * 2}}
          >
            {projectPair.map((project, index) => (
                renderDenisPortfolioProject(project, index)
            ))}
          </div>
        ))}
      </section>
    )
  }

  return (
    <section className={projectCardContainerClasses}>
      { title && <h3 className='mb-30'>{ title }</h3> }
      <div className='cards'>
        {
          projects.map((project, index) => {
            return(
              <DragToReorder
                key={index}
                showDrag={draggable}
                order={project.order}
                handleOrderChange={handleProjectOrderChange}
                lastDraggedOrder={lastDraggedOrder}
                setLastDraggedOrder={setLastDraggedOrder}
                id={`project-${project.slug}`}
              >
                <div
                  id={project.slug}
                  className='card'
                >
                  <div className='top-section'>
                    { renderImageOrPlaceholder(project) }
                    <div className='rollover-content' onClick={() => handleCardClick(project)}>
                      { renderCardLinks(project) }
                      { renderCardTagButtons(project) }
                    </div>
                  </div>
                { renderBottomSection(project) }
              </div>
            </DragToReorder>
          )})
        }
        {
          portfolioManage && (
            <div className={addProjectCardClasses}>
              <Link to='/projects/new'>
                <Button icon='add-circle'>Add project</Button>
              </Link>
            </div>
          )
        }
        {
          challengeShow && (
            <div className='card new-project'>
              <Link to={{
                pathname: '/projects/new',
                state: { challengeSubmission: challengeID }
              }}>
                <Button icon='add-circle'>Submit your project</Button>
              </Link>
            </div>
          )
        }
      </div>
    </section>
  )
}

export default ProjectCards
