const buildFlashMessage = (message, status = 'notice') => {
  const emoji = status === 'notice' ? '👍' : '✋'
  const flash  = document.createElement('div')
  flash.classList.add('flash', status)
  flash.innerHTML = `<span class='emoji'>${emoji}</span><span>${message}</span>`
  return flash
}

const showFlashMessage = (message, status = 'notice') => {
  const flashContainer = document.querySelector('.flash-component-container')
  flashContainer.appendChild(buildFlashMessage(message, status))
}

export default showFlashMessage
