import React from 'react'
const classNames = require('classnames')
import AliceCarousel from 'react-alice-carousel'

const DenisReferences = ({references, questionAndAnswers}) => {
  const containerClasses = classNames('denis-references-container', { 'negative-margin': questionAndAnswers.length > 0 })

  const referenceItems = references.map((reference, index) => {
    const handImage = require(`images/denis-reference-hand-${index % 3}.png`)
    return (
      <div className='carousel-slide'>
        <div className='contact'>
          <p className='name'>{ reference.name }</p>
          <p className='job-title'>{ reference.job_title }</p>
          <div className='divider' />
        </div>
        <img
          src={handImage}
          alt='3D hand'
          className='hand-image'
        />
        <div className='reference-headline-container'>
          <h6>What others think</h6>
          <h4 className='reference-headline'>{ reference.headline }</h4>
        </div>
        <p className='reference-text'>{ reference.text }</p>
      </div>
    )
  })

  if (references.length == 0) {
    return null
  }

  return (
    <div className={containerClasses}>
      <AliceCarousel
        items={referenceItems}
        mouseTracking
        animationDuration={1000}
      />
    </div>
  )
}

export default DenisReferences
