// Note: A lot of the logic here is repeated in the PortfolioShowPage
//       If Troopl lives, this should be combined
//       I simply don't have the time right now

import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import FetchWrapper from '../../helpers/FetchWrapper'
import Isabelle from '../portfolio-layouts/Isabelle'       // PLUS LAYOUT
import Denis from '../portfolio-layouts/denis/Denis'       // FREE LAYOUT
import Loader from '../Loader'
import Modal from '../Modal'
import Share from '../Share'
import SEOMetaData from '../SEOMetaData'
import useShowModal from '../../hooks/useShowModal'

const DesignsDemoPage = (props) => {
  const [portfolio, setPortfolio] = useState([])
  const [projects, setProjects] = useState([])
  const [articles, setArticles] = useState([])
  const [stacks, setStacks] = useState([])
  const [heroStacks, setHeroStacks] = useState([])
  const [references, setReferences] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, toggleModal] = useShowModal(props)
  const [API] = useState(new FetchWrapper)
  const {designName} = useParams()
  const history = useHistory()

  useEffect(() => {
    if (!['denis', 'isabelle'].includes(designName)) {
      history.push('/404')
    }
    getPortfolioData()
    document.querySelector('body').classList.add('demo')
    return () => {
      document.querySelector('body').classList.remove('demo')
    }
  }, [])

  const getPortfolioData = async () => {
    try {
      // Using my portfolio as the example for now to save time
      // Create a fresh one in the future
      const response = await API.get('/portfolios/tristan')
      const data = await response.json()
      const {stacks, projects, articles, hero_portfolio_stacks, references, ...portfolio} = data
      setPortfolio(portfolio)
      setStacks(stacks)
      setProjects(projects)
      setArticles(articles)
      setHeroStacks(hero_portfolio_stacks)
      setReferences(references)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const uppercaseDesignName = () => (
    designName[0].toUpperCase() + designName.slice(1)
  )

  const resumeButtonClick = () => {
    window.open(portfolio.cv, '_blank')
  }

  const emailButtonClick = () => {
    window.open(`mailto:${portfolio.email}`, '_blank')
  }

  const SelectPortfolioDemoLayout = () => {
    if (designName == 'denis') {
      return (
        <Denis
          portfolio={portfolio}
          projects={projects}
          stacks={stacks}
          heroStacks={heroStacks}
          articles={articles}
          references={references}
          resumeButtonClick={resumeButtonClick}
          emailButtonClick={emailButtonClick}
          toggleModal={toggleModal}
        />
      )
    }

    if (designName == 'isabelle') {
      return (
        <Isabelle
          portfolio={portfolio}
          projects={projects}
          stacks={stacks}
          articles={articles}
          resumeButtonClick={resumeButtonClick}
          emailButtonClick={emailButtonClick}
          toggleModal={toggleModal}
        />
      )
    }
  }

  const ShareModal = ({ show }) => {
    if (!show) { return null }

    return (
      <Modal toggle={toggleModal}>
        <Share
          slug={portfolio.slug}
          type='portfolio'
          fullName={portfolio.full_name}
          preferredName={portfolio.preferred_name}
          userId={portfolio.user.id}
        />
      </Modal>
    )
  }

  if (loading) {
    return <Loader pageLoader />
  }

  return (
    <>
      <SEOMetaData
        dynamicTitle={`${portfolio.full_name} | ${portfolio.display_developer_type} | Troopl portfolio`}
        dynamicDescription={ portfolio.bio.split('.').slice(0,2) } // first two sentences
      />
      <div className='design-demo-message-container'>
        <div className='page-boundary'>
          <h6>This is a preview of our <b>{ uppercaseDesignName() }</b> portfolio design ✌️</h6>
        </div>
      </div>
      <SelectPortfolioDemoLayout />
      <ShareModal show={showModal} />
    </>
  )
}

export default DesignsDemoPage
