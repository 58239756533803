import React from 'react'
import { Link } from 'react-router-dom'
const classNames = require('classnames')
import Button from './Button'
import pricePersonImg from 'images/price-person.png'
import pricePersonHandImg from 'images/price-person-hand.png'

const PriceTable = ({location, onPlusTabButtonClick, submitting}) => {
  const priceTableClasses = classNames('price-table-container', location)
  const plusTab = location == 'plus-tab'

  const renderUpgradeButton = () => {
    if (onPlusTabButtonClick) {
      return (
        <Button
          extraClasses={['fill-width']}
          onClick={onPlusTabButtonClick}
          submitting={submitting}
          submittingText='Upgrade'
        >
          Upgrade ⚡️
        </Button>
      )
    }

    return (
      <Link to='/portfolios/plus'>
        <Button extraClasses={['fill-width']}>Upgrade ⚡️</Button>
      </Link>
    )
  }

  return (
    <div className={priceTableClasses}>
      <table className='price-table'>
        <tbody>
          <tr>
            <th className='top left'>
              <h3>Essential 👌</h3>
            </th>
            <th className='top right desktop-person-container'>
              <h3>{ plusTab ? 'Troopl Plus 👑' : 'Plus 👑'}</h3>
              <img src={pricePersonImg} className='person' alt='3D person leaning on a pricing table'/>
              <img src={pricePersonHandImg} className='hand' alt='3D hand overlapping price table'/>
            </th>
          </tr>
          <tr>
            <td>
              <p className='point'>🚀 Visual portfolio to showcase projects</p>
              <p className='point'>🔗 Personalised url</p>
              <p className='point'>🔎 Discoverable portfolio & projects</p>
              <p className='point'>💪 Browse thousands of junior jobs around the world</p>
              <p className='point'>🏆 Complete challenges to skill up and show off your skills</p>
              <p className='point'>🤩 Join our Slack community</p>
            </td>
            <td>
              <p className='point essentials'>
                {
                  plusTab
                  ? 'Upgrade to unlock:'
                  : 'The essentials plus:'
                }
              </p>
              <p className='point'>🎨 Professional portfolio design</p>
              <p className='point'>🐘 Additional portfolio sections<br/>(biography, testimonials, Q&A)</p>
              <p className='point'>🔥 Appear higher in search</p>
            </td>
          </tr>
          <tr>
            <td className='bottom left'>
              <h3 className='troopl-gradient-text'>Free</h3>
              <a href='/signup' className='sign-up-button'>
                <Button extraClasses={['fill-width']}>Sign up 🚀</Button>
              </a>
            </td>
            <td className='bottom right'>
              <div className='troopl-gradient-text'>
                <h3 className='troopl-gradient-text'>€6</h3>
                <p className='small'>/month</p>
              </div>
              { renderUpgradeButton() }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PriceTable
