import React, { useState, useContext } from 'react'
const classNames = require('classnames');
import CurrentUserContext from '../context/CurrentUserContext'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'

const Share = ({slug, type, fullName, preferredName, projectName, portfolioManagePage, userId, disabled}) => {
  const [copied, setCopied] = useState(false)
  const copyShareURL = `troopl.com/${slug}`
  const buttonShareURL = `https://troopl.com/${slug}`
  const iconSize = 46
  const iconRound = true
  const subject = type == 'portfolio' ? `${fullName} | Troopl ${type}` : `${projectName} | ${fullName} | Troopl ${type}`
  const shareContainerClasses = classNames('share-container', {'portfolio-manage-page': portfolioManagePage })
  const [currentUser] = useContext(CurrentUserContext)

  const handleCopyURL = () => {
    setCopied(true)
    navigator.clipboard.writeText(copyShareURL);
  }

  const renderHeadlineMessage = () => {
    const ownerIsSharing = userId == currentUser?.id
    const name = ownerIsSharing ? 'your' : preferredName[preferredName.length - 1] == 's' ? preferredName + "'" : preferredName + "'s"
    return (
      `Share ${name} ${type} with the world 🌎`
    )
  }

  if (disabled) { return null }

  return (
    <div className={shareContainerClasses}>
        {
          !portfolioManagePage && (
            <h3 className='headline'>
              { renderHeadlineMessage() }
            </h3>
          )
        }
        <div className='copy-url-container'>
          <p className='note'>
            {
              portfolioManagePage
              ? 'Share your portfolio'
              : 'Or share link'
            }
          </p>
          <div
            className='input-wrapper'
            onClick={handleCopyURL}
          >
            <div className='copy-input'>{copyShareURL}</div>
            <p className='copy-button'>
              { copied ? 'Copied' : 'Copy' }
            </p>
          </div>
        </div>
        <div className='button-wrapper'>
          <LinkedinShareButton
            url={buttonShareURL}
            title={subject}
            source='troopl.com'
          >
            <LinkedinIcon size={iconSize} round={iconRound} />
          </LinkedinShareButton>
          <TwitterShareButton
            url={buttonShareURL}
            title={subject}
            related={['@troopl_']}
          >
            <TwitterIcon size={iconSize} round={iconRound} />
          </TwitterShareButton>
          <FacebookShareButton url={buttonShareURL}>
            <FacebookIcon size={iconSize} round={iconRound} />
          </FacebookShareButton>
          <EmailShareButton
            url={buttonShareURL}
            subject={subject}
          >
            <EmailIcon size={iconSize} round={iconRound} />
          </EmailShareButton>
        </div>
    </div>
  )
}

export default Share
