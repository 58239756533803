import React from 'react'
import ProjectForm from '../forms/ProjectForm'

const ProjectNewPage = ({location}) => {
  const challengeSubmission = location.state?.challengeSubmission || null

  return (
    <main className='page-content-container'>
      <header>
        <h1>Add new project ⚡️</h1>
        <h5 className='subhead'>Let's get started!</h5>
      </header>
      <ProjectForm action='new' challengeSubmission={challengeSubmission}/>
    </main>
  )
}

export default ProjectNewPage
