import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import debounce from 'lodash.debounce'
const classNames = require('classnames')
import CurrentUserContext from '../context/CurrentUserContext'
import JobCountContext from '../context/JobCountContext'
import PlusTemplateContext from '../context/PlusTemplateContext'
import Button from './Button'
import logo from 'images/svg/troopl_logo.svg'
import menuSVG from 'images/svg/menu.svg'
import closeSVG from 'images/svg/close.svg'

const Navbar = () => {
  const [showMainButton, setShowMainButton] = useState(false)
  const [currentUser] = useContext(CurrentUserContext)
  const [jobCount] = useContext(JobCountContext)
  const [plusTemplate] = useContext(PlusTemplateContext)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const { pathname } = useLocation()

  const currentlyOnHomepage = () => pathname === '/'

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav)
  }

  const closeMobileNav = () => {
    setShowMobileNav(false)
  }

  const handleScroll = debounce(() => {
    setShowMainButton(window.pageYOffset > 600) // main banner height is 600px
  }, 50)

  // TODO: add .2s debounce to this
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const mainLinks = () => (
    <>
      <Link to='/projects' className='link' onClick={closeMobileNav}>Projects</Link>
      <Link to='/portfolios' className='link' onClick={closeMobileNav}>Portfolios</Link>
      <Link to='/challenges' className='link' onClick={closeMobileNav}>Challenges</Link>
      <Link to='/pricing' className='link' onClick={closeMobileNav}>Pricing</Link>
      <Link to='/jobs' className='link' onClick={closeMobileNav}>
        Jobs <span className='job-count'>{ jobCount }</span>
      </Link>
    </>
  )

  const secondaryLinks = (desktop) => {
    if (desktop && plusTemplate) { return null }

    if (currentUser?.user_type == 'developer') {
      return (
        <>
          <a href='/signout' data-method='delete' className='link sign-in'>Sign out</a>
          <Link to='/portfolios/manage' className='button' onClick={closeMobileNav}>
            <Button>
              {
                currentUser
                ? currentUser.portfolio_setup ? 'Manage portfolio 🚀' : 'Setup portfolio🕺🏼'
                : 'Build your portfolio 🚀'
              }
            </Button>
          </Link>
        </>
      )
    }

    if (currentUser?.user_type == 'company_user') {
      return (
        <>
          <a href='/companies/signout' data-method='delete' className='link sign-in'>Sign out</a>
        </>
      )
    }

    // Only add the extra class here
    // because it's only seen by non signed in users
    return (
      <>
        <a href='/signin' className='link sign-in'>Sign in</a>
        <a href='/signup' className='button' onClick={closeMobileNav}>
          <Button
            extraClasses={mainButtonClasses}
            id={currentlyOnHomepage ? 'signup-button-nav-homepage' : 'signup-button-nav'}
          >
            Build your portfolio 🚀
          </Button>
        </a>
      </>
    )
  }

  const mainButtonClasses = classNames('main-button', { 'show': showMainButton })
  const mobileMenuClasses = classNames('mobile-menu', { 'show': showMobileNav })
  const mobileCoverClasses = classNames('mobile-cover', { 'show': showMobileNav })
  const navbarContainerClasses = classNames('navbar-container', { 'homepage': currentlyOnHomepage() }, { 'plus-template': plusTemplate })

  return (
    <nav className={navbarContainerClasses}>
      <div className='left'>
        <Link to='/' className='logo-link'>
          <img src={logo} className='logo-img' alt='Troopl logo'/>
        </Link>
        { mainLinks() }
      </div>
      <div className='right'>
        { secondaryLinks(true) }
        <img
          src={menuSVG}
          alt='menu icon'
          className='menu-icon'
          onClick={toggleMobileNav}
        />
      </div>
      <div className={mobileMenuClasses}>
        <img
          src={closeSVG}
          alt='menu icon'
          className='close-icon'
          onClick={toggleMobileNav}
        />
        { mainLinks() }
        { secondaryLinks(false) }
      </div>
      <div
        className={mobileCoverClasses}
        onClick={toggleMobileNav}
      />
    </nav>
  )
}

export default Navbar
