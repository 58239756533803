import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Autocomplete from 'react-google-autocomplete'
import ReactTags from 'react-tag-autocomplete'
import CredentialsContext from '../../context/CredentialsContext'
import Button from '../Button'
import FormInput from '../FormInput'
import FormInputMulti from '../FormInputMulti'
import RadioButtonCards from '../RadioButtonCards'
import PortfolioTypeSelector from '../PortfolioTypeSelector'
import PortfolioEducationSelector from '../PortfolioEducationSelector'
import { safeSlug } from '../../helpers/general'

const PortfolioForm = (props) => {
  const {
    portfolio,
    hasPortfolio,
    picturePreview,
    biographyPicturePreview,
    educationSuggestions,
    handleEducationAddition,
    handleEducationDelete,
    handleEducationLocationChange,
    CVPreview,
    onInputChange,
    onFileInputChange,
    onLocationChange,
    onSubmit,
    onInputBlur,
    errors,
    submitting,
    hasErrors,
    userHasPlus,
    stackSuggestions,
    handleHeroStackAddition,
    handleHeroStackDelete,
    spokenLanguageSuggestions,
    handleSpokenLanguageAddition,
    handleSpokenLanguageDelete,
    handleMultiInputChange,
    handleMultiInputBlur,
    addEmptyMultiInputKeysToState,
    removeMultiInputs,
    handleMultiInputsOrderChange
  } = props

  const [{ google_maps_api_key }] = useContext(CredentialsContext)

  const handleFormSubmit = (event) => {
    event.preventDefault()
  }

  const SectionHeader = ({title}) => (
    <div className='section-header'>
      <div className='title'>{ title }</div>
    </div>
  )

  return (
    <form
      onSubmit={handleFormSubmit}
      className='form-container portfolio'
    >

      <SectionHeader title='General info ✏️' />

      <FormInput
        label='Choose your portfolio URL*'
        labelNote={<span className='note'>Currently <b className='troopl-gradient-text'>troopl.com/{safeSlug(portfolio.slug)}</b></span>}
        valuesKey='slug'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        required
      />

      <FormInput
        label='Preferred name*'
        valuesKey='preferred_name'
        placeholder='Mia'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        required
      />

      <FormInput
        label='Full name*'
        valuesKey='full_name'
        placeholder='Mia Wallace'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        required
      />

      <FormInput
        label='Email*'
        valuesKey='email'
        type='email'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        required
      />

      <label htmlFor='city'>
        Current city*
        <Autocomplete
          id='city'
          apiKey={google_maps_api_key}
          options={{fields: ['address_components', 'formatted_address']}}
          defaultValue={portfolio.city}
          language='en'
          placeholder='Begin typing city name...'
          onPlaceSelected={onLocationChange}
          onChange={onInputChange}
          onBlur={onInputBlur}
          aria-required
          aria-invalid={!!errors['city']}
          type='search'
          autoComplete='off'
        />
      </label>
      {errors['city'] && <span className='note error'>{errors['city']}</span>}

      {
        picturePreview ? (
          <img
            className='preview-image'
            alt='portfolio-picture'
            src={picturePreview}
            aria-invalid={!!errors['picture']}
          />
        ) : (
          <div className='preview-image empty' />
        )
      }
      {errors['picture'] && <span className='note error picture'>{errors['picture']}</span>}

      <label
        htmlFor='picture'
        className='button-container'
        >
        {
          picturePreview
          ? 'Upload new picture'
          : 'Upload picture (optional)'
        }
        <input
          id='picture'
          type='file'
          hidden
          aria-invalid={!!errors['picture']}
          onChange={onFileInputChange}
        />
      </label>

      <SectionHeader title='Specialty 🤩' />

      <PortfolioTypeSelector
        portfolio={portfolio}
        onInputChange={onInputChange}
        errors={errors}
        onInputBlur={onInputBlur}
      />

      <label
        htmlFor='spoken_languages'
        aria-invalid={!!errors.spoken_languages}
      >
        Spoken languages
        <ReactTags
          id='spoken_languages'
          placeholderText='Add language'
          newTagText='Add: '
          minQueryLength={1}
          tags={portfolio.spoken_languages}
          suggestions={spokenLanguageSuggestions}
          onAddition={handleSpokenLanguageAddition}
          onDelete={handleSpokenLanguageDelete}
        />
      </label>

      <FormInput
        label='Portfolio headline'
        labelNote={<span className='note'>Put text you'd like <b className='troopl-gradient-text'>turned into a gradient</b> between square brackets [like this]</span>}
        valuesKey='header_headline'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        plus
      />

      <FormInput
        label='Portfolio intro'
        valuesKey='header_intro'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        plus
      />

      <RadioButtonCards
        cards={[
          {
            value: true,
            emoji: '🎉',
            text: 'Yes'
          },
          {
            value: false,
            emoji: '👎',
            text: 'Nope'
          }
        ]}
        label='Open to employment opportunities?'
        values={portfolio}
        valuesKey='open_to_opportunities'
        name='opportunities'
        onChange={onInputChange}
        errors={errors}
        required
      />

      <RadioButtonCards
        cards={[
          {
            value: true,
            emoji: '🌎',
            text: 'Yes'
          },
          {
            value: false,
            emoji: '👎',
            text: 'Nope'
          }
        ]}
        label='Open to remote work?'
        valuesKey='open_to_remote_work'
        name='remote'
        values={portfolio}
        onChange={onInputChange}
        errors={errors}
        required
      />

      <FormInput
        label="What companies would you love to work for?"
        placeholder='Optional'
        labelNote={<span className='note'>This won't show on your portfolio, but maybe we can help!<br/>Share as many companies as you like.</span>}
        valuesKey='dream_jobs'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
      />

      <SectionHeader title='Education 🧠' />

      <PortfolioEducationSelector
        portfolio={portfolio}
        educationSuggestions={educationSuggestions}
        handleEducationAddition={handleEducationAddition}
        handleEducationDelete={handleEducationDelete}
        handleEducationLocationChange={handleEducationLocationChange}
        onInputChange={onInputChange}
        errors={errors}
        onInputBlur={onInputBlur}
        googleMapsAPIKey={google_maps_api_key}
      />

      <SectionHeader title='Biography 🦄' />

      <label htmlFor='bio'>
        About you*
        <textarea
          id='bio'
          value={portfolio.bio ?? ''}
          placeholder="Share a little about yourself. i.e. What interests you about coding?"
          onChange={onInputChange}
          onBlur={onInputBlur}
          aria-required
          aria-invalid={!!errors['bio']}
          rows={8}
        />
        {errors['bio'] && <span className='note error'>{errors['bio']}</span>}
      </label>

      <FormInput
        label='Biography headline'
        valuesKey='bio_headline'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
        plus
      />

      <label
        htmlFor=''
        disabled={!userHasPlus}
      >
        <div className='title'>
          <span className='label'>Biography picture</span>
          <span className='troopl-plus-icon'>Plus 👑</span>
        </div>
        <span className='note'>Add an optional 2nd image to the biography section of your portfolio.</span>
      </label>
      {
        biographyPicturePreview ? (
          <img
            className='preview-image'
            alt='portfolio biography picture'
            src={biographyPicturePreview}
            aria-invalid={!!errors['biography_picture']}
          />
        ) : (
          <div className='preview-image empty' />
        )
      }
      {errors['biography_picture'] && <span className='note error picture'>{errors['biography_picture']}</span>}

      <label
        htmlFor='biography_picture'
        className='button-container'
        disabled={!userHasPlus}
        >
        {
          picturePreview
          ? 'Upload new picture'
          : 'Upload picture (optional)'
        }
        <input
          id='biography_picture'
          type='file'
          hidden
          aria-invalid={!!errors['biography_picture']}
          onChange={onFileInputChange}
        />
      </label>

      {
        CVPreview
        ? (
        <div
          className='cv-preview-container'
          aria-invalid={!!errors['cv']}
        >
          <object data={CVPreview} type='application/pdf'>
              Uh-oh. We cannot display your CV.
          </object>
        </div>
        ) : (
          <div className='cv-preview-container empty' />
        )
      }
      {errors['cv'] && <span className='note error file'>{errors['cv']}</span>}

      <label htmlFor='cv' className='button-container'>
        {
          CVPreview
          ? 'Upload new CV'
          : 'Upload CV (optional)'
        }
        <input
          id='cv'
          type='file'
          hidden
          aria-invalid={!!errors['cv']}
          onChange={onFileInputChange}
        />
      </label>

      <FormInputMulti
        label='Additional skills'
        labelNote=''
        values={portfolio}
        valuesKey='additional_portfolio_skills'
        multiInputs={[
          {
            key: 'text',
            type: 'textarea',
            rows: 2
          }
        ]}
        onMultiInputChange={handleMultiInputChange}
        onMultiInputBlur={handleMultiInputBlur}
        errors={errors}
        addEmptyMultiInputKeysToState={addEmptyMultiInputKeysToState}
        removeMultiInputs={removeMultiInputs}
        handleMultiInputsOrderChange={handleMultiInputsOrderChange}
        orderable
        plus
      />

      <label
        htmlFor='hero_portfolio_stacks'
        aria-invalid={!!errors.favourite_stacks}
        disabled={!userHasPlus}
      >
        <div className='title'>
          <span className='label'>Hero stacks</span>
          <span className='troopl-plus-icon'>Plus 👑</span>
        </div>
        <span className='note'>Highlight up to 3 technology stacks on your portfolio.</span>
        <ReactTags
          id='hero_portfolio_stacks'
          placeholderText={portfolio.hero_portfolio_stacks.length > 2 ? null : 'Add stack'}
          allowNew
          newTagText='Add: '
          minQueryLength={1}
          tags={portfolio.hero_portfolio_stacks}
          suggestions={stackSuggestions}
          onAddition={handleHeroStackAddition}
          onDelete={handleHeroStackDelete}
          disabled={true}
        />
        {!userHasPlus ? <Link to='/portfolios/plus' className='troopl-plus-cta'>Only on Troopl Plus 👑 Upgrade now</Link> : null}
      </label>

      <SectionHeader title='Plus template sections 👑' />

      <FormInputMulti
        label='References'
        labelNote=''
        values={portfolio}
        valuesKey='references'
        multiInputs={[
          {
            label: 'Name',
            key: 'name'
          },
          {
            label: 'Job title & company',
            key: 'job_title'
          },
          {
            label: 'Testimonial headline',
            key: 'headline',
          },
          {
            label: 'Testimonial',
            key: 'text',
            type: 'textarea',
          }
        ]}
        onMultiInputChange={handleMultiInputChange}
        onMultiInputBlur={handleMultiInputBlur}
        errors={errors}
        addEmptyMultiInputKeysToState={addEmptyMultiInputKeysToState}
        removeMultiInputs={removeMultiInputs}
        handleMultiInputsOrderChange={handleMultiInputsOrderChange}
        orderable
        plus
      />

      <FormInputMulti
        label='Q & A'
        labelNote="A flexible section to share your answer to questions Troopl hasn't asked!"
        values={portfolio}
        valuesKey='question_and_answers'
        multiInputs={[
          {
            label: 'Question',
            key: 'question'
          },
          {
            label: 'Answer',
            key: 'answer',
            type: 'textarea',
            rows: 2
          }
        ]}
        onMultiInputChange={handleMultiInputChange}
        onMultiInputBlur={handleMultiInputBlur}
        errors={errors}
        addEmptyMultiInputKeysToState={addEmptyMultiInputKeysToState}
        removeMultiInputs={removeMultiInputs}
        handleMultiInputsOrderChange={handleMultiInputsOrderChange}
        orderable
        plus
      />

      <SectionHeader title='Social profiles 👋' />

      <FormInput
        label='GitHub'
        valuesKey='github_url'
        type='url'
        placeholder='Optional'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
      />

      <FormInput
        label='LinkedIn'
        valuesKey='linkedin_url'
        type='url'
        placeholder='Optional'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
      />

      <FormInput
        label='Twitter'
        valuesKey='twitter_url'
        type='url'
        placeholder='Optional'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
      />

      <FormInput
        label='Facebook'
        valuesKey='facebook_url'
        type='url'
        placeholder='Optional'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
      />

      <FormInput
        label='Instagram'
        valuesKey='instagram_url'
        type='url'
        placeholder='Optional'
        values={portfolio}
        onChange={onInputChange}
        onBlur={onInputBlur}
        errors={errors}
      />

      <SectionHeader title='Legal stuff 🙌' />

      <RadioButtonCards
        cards={[
          {
            value: false,
            emoji: '👎',
            text: 'Nope'
          },
          {
            value: true,
            emoji: '👀',
            text: 'Yes'
          }
        ]}
        label={<span>Do you accept the <Link to='/terms' target='_blank'>Troopl Terms and Conditions</Link>?*</span>}
        values={portfolio}
        valuesKey='terms_accepted'
        name='terms'
        onChange={onInputChange}
        errors={errors}
        required
      />

      <Button
        extraClasses={['fill-width', 'submit']}
        onClick={onSubmit}
        errors={hasErrors}
        submitting={submitting}
        submittingText={'Submitting'}
      >
        { hasPortfolio ? 'Update portfolio 🎉' : 'Add information 🎉' }
      </Button>
      {
        hasErrors &&
        <span className='error-message'>Please see highlighted fields above ☝️☝️</span>
      }
    </form>
  )
}

export default PortfolioForm
