import React from 'react'
import ArticleForm from '../forms/ArticleForm'

const ArticleNewPage = () => {
  return (
    <main className='page-content-container'>
      <header>
        <h1>Add article 📖</h1>
        <h5 className='subhead'>
          Share something you've written, like an article, blog post, or case study!</h5>
      </header>
      <ArticleForm action='new' />
    </main>
  )
}

export default ArticleNewPage
