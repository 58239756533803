import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const classNames = require('classnames');
import CurrentUserContext from '../context/CurrentUserContext'
import closeSVG from 'images/svg/close-white.svg'
import addSVG from 'images/svg/add-circle.svg'
import dragSVG from 'images/svg/drag.svg'

const FormInputMulti = (props) => {
  const {
    label,
    labelNote,
    values,
    valuesKey,
    multiInputs,
    onMultiInputChange,
    onMultiInputBlur,
    addEmptyMultiInputKeysToState,
    errors,
    removeMultiInputs,
    handleMultiInputsOrderChange,
    plus,
    orderable
  } = props

  const [dragInputsOrder, setDragInputsOrder] = useState(0)
  const [allowOrderChange, setAllowOrderChange] = useState(0)
  const [currentUser] = useContext(CurrentUserContext)

  useEffect(() => {
    // If there are no value keys, create them
    if (values[valuesKey].length == 0) {
      addEmptyKeys()
    }
    return () => {}
  }, [])

  useEffect(() => {
    setAllowOrderChange(orderable && values[valuesKey].length > 1)
    return () => {}
  }, [values])

  const addEmptyKeys = () => addEmptyMultiInputKeysToState(multiInputs, valuesKey)

  const plusDisabled = () => {
    return !!plus && !currentUser.plus
  }

  const handleDragStart = (e) => {
    e.currentTarget.classList.add('on-drag')
    setDragInputsOrder(getDropOrder(e))
  }

  const handleDragOver = (e) => {
    e.currentTarget.classList.add('over')
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('on-drag')
    setDragInputsOrder(0)
  }

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('over')
  }

  const handleDrop = (e) => {
    handleMultiInputsOrderChange(dragInputsOrder, getDropOrder(e), valuesKey)
    e.currentTarget.classList.remove('over')
  }

  const getDropOrder = (e) => {
    return e.currentTarget.dataset.order
  }

  const buildMultiInputs = (value, valueIndex) => {
    const divClassNames = classNames('input-fields', valuesKey)

    return (
      multiInputs.map((input, inputIndex) => {
        const indexKeyId = `${valuesKey}_${input.key}_${valueIndex}`
        const inputClassNames = classNames('multi-input', input.key)

        return (
          <div
            className={divClassNames}
            key={`${valuesKey}_${inputIndex}`}
          >
            <label
              htmlFor={indexKeyId}
              className={inputClassNames}
            >
              {input.label ?? input.label}
              {
                input.type == 'textarea'
                ? (
                  <textarea
                    id={indexKeyId}
                    aria-invalid={!!errors[indexKeyId]}
                    value={value[input.key] ?? ''}
                    placeholder={input.placeholder}
                    onChange={onMultiInputChange}
                    onBlur={onMultiInputBlur}
                    data-columnkey={input.key}
                    data-index={valueIndex}
                    data-valueskey={valuesKey}
                    rows={input.rows ?? 3}
                  />
                ) : (
                  <input
                    id={indexKeyId}
                    aria-invalid={!!errors[indexKeyId]}
                    value={value[input.key] ?? ''}
                    placeholder={input.placeholder}
                    onChange={onMultiInputChange}
                    onBlur={onMultiInputBlur}
                    data-columnkey={input.key}
                    data-index={valueIndex}
                    data-valueskey={valuesKey}
                  />
                )
              }
              {errors[indexKeyId] && <span className='note error'>{errors[indexKeyId]}</span>}
            </label>
          </div>
        )
      })
    )
  }

  const buildTopSection = (value, valueIndex) => {
    return (
      <div className='top-section-container'>
        {
          allowOrderChange && (
            <div className='drag-icon-container'>
              <img
                src={dragSVG}
                alt='drag icon'
                className='drag-icon'
              />
              <p className='drag-note'>Drag to reorder</p>
            </div>
          )
        }
        <div className='counter-background'>
          <div className='counter'>{ valueIndex + 1 }</div>
        </div>
        {
          <div
            className='close-button-container'
            onClick={() => removeMultiInputs(valuesKey, value)}
          >
            <img
              src={closeSVG}
              alt='close button'
              className='close-button'
            />
          </div>
        }
      </div>
    )
  }

  return (
    <div
      className={'form-input-multi-container'}
      disabled={plusDisabled()}
    >
      <label className='main'>
        <div className='title'>
          <span className='label'>{ label }</span>
          { plus && <span className='troopl-plus-icon'>Plus 👑</span> }
        </div>
        { labelNote && <span className='note'>{ labelNote }</span>}
      </label>
      {
        values[valuesKey].length > 0 && (
          values[valuesKey].map((value, valueIndex) => (
            <div
              className={classNames('multi-inputs', { orderable: allowOrderChange })}
              key={`multiInputs_${valuesKey}_${valueIndex}`}
              data-order={value.order}
              draggable={allowOrderChange}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              { buildTopSection(value, valueIndex) }
              { buildMultiInputs(value, valueIndex) }
            </div>
          ))
        )
      }
      <div className='border-bottom-container'>
        <div
          className='plus-button-container'
          onClick={addEmptyKeys}
        >
          <img
            src={addSVG}
            alt='plus button'
            className='plus-button'
          />
        </div>
      </div>
      {plusDisabled() ? <Link to='/portfolios/plus' className='troopl-plus-cta'>Available on Troopl Plus 👑  Upgrade now</Link> : null}
    </div>
  )
}

export default FormInputMulti
