import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ReactTags from 'react-tag-autocomplete'
import Autocomplete from 'react-google-autocomplete'
import CredentialsContext from '../context/CredentialsContext'
import CurrentUserContext from '../context/CurrentUserContext'
const queryString = require('query-string')
const classNames = require('classnames')
import FetchWrapper from '../helpers/FetchWrapper'
import ProjectCards from './ProjectCards'
import PortfolioCards from './PortfolioCards'
import RadioButtonCards from './RadioButtonCards'
import Button from './Button'
import Loader from './Loader'
import { formatGoogleFilterLocation } from '../helpers/formatGoogleLocation'

const DiscoverFilter = ({model}) => {
  const [projects, setProjects] = useState({})
  const [moreProjects, setMoreProjects] = useState(false)
  const [portfolios, setPortfolios] = useState({})
  const [morePortfolios, setMorePortfolios] = useState(false)
  const [loading, setLoading] = useState(true)
  const [moreLoading, setMoreLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({ sort: 'popular' })
  const [filterLocation, setFilterLocation] = useState({})
  const [stacksFilterParams, setStacksFilterParams] = useState([])
  const [selectedButton, setSelectedButton] = useState('')
  const [stackSuggestions, setStackSuggestions] = useState([])
  const [initialLoad, setInitialLoad] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const [offset, setOffset] = useState(0)
  const [API] = useState(new FetchWrapper)
  const [{ google_maps_api_key }] = useContext(CredentialsContext)
  const [currentUser] = useContext(CurrentUserContext)
  let location = useLocation()
  let history = useHistory()
  const featureButtons = [
    { type: null,        name: 'All' },
    { type: 'stacks',    name: 'JavaScript' },
    { type: 'stacks',    name: 'Ruby on Rails' },
    { type: 'stacks',    name: 'React' },
    { type: 'stacks',    name: 'Node.js' },
    { type: 'stacks',    name: 'Flutter' },
    { type: 'stacks',    name: 'Firebase' }
  ]

  // Handle very first page load
  useEffect(() => {
    const params = parsedSearchParams()
    if (model === 'projects') {
      requestProjects({ body: params} )
      setSelectedButton(findSelectedButton(params))
    }
    if (model === 'portfolios') {
      requestPortfolios({ body: params })
    }
    setSearchParams(params)
    requestStackSuggestions()
    setInitialLoad(false)
    if (Object.keys(params).length > 0) {
      setShowFilters(true)
    }
    return () => {}
  }, [])

  useEffect(() => {
    if (initialLoad) { return }
    if (model == 'projects') {
      requestProjects({body: searchParams})
    }
    if (model == 'portfolios') {
      requestPortfolios({body: searchParams})
    }
    return () => {}
  }, [location])

  useEffect(() => {
    if (initialLoad) { return }
    const { city, state, country, ...newSearchParams } = searchParams
    // Check if location has been cleared from handleLocationClear
    if (filterLocation.type !== undefined) {
      newSearchParams[filterLocation.type] = filterLocation.name
    }
    handleNewSeachParams(newSearchParams)
    return () => {}
  }, [filterLocation])

  useEffect(() => {
    setStacksFilterParams(searchParams.stacks?.map((stack, index) => {
      return { type: 'stacks', name: stack, index: index }
    }))
    return () => {}
  }, [searchParams.stacks])

  const requestProjects = async ({body = searchParams, moreProjects = false}) => {
    try {
      moreProjects ? setMoreLoading(true) : setLoading(true)
      const addOffsetToBody = {...body, offset: offset + (moreProjects ? 1 : 0)}
      const response = await API.post('/discover-projects', addOffsetToBody, true)
      const data = await response.json()
      moreProjects ? setProjects([...projects, ...data.projects]): setProjects(data.projects)
      setMoreProjects(data.more_projects)
    } catch(error) {
      console.error(error)
    } finally {
      if (moreProjects) {
        setMoreLoading(false)
        setOffset(offset + 1)
      } else {
        setLoading(false)
      }
    }
  }

  const requestPortfolios = async ({body = searchParams, morePortfolios = false}) => {
    try {
      morePortfolios ? setMoreLoading(true) : setLoading(true)
      const addOffsetToBody = {...body, offset: offset + (morePortfolios ? 1 : 0)}
      const response = await API.post('/discover-portfolios', addOffsetToBody, true)
      const data = await response.json()
      morePortfolios ? setPortfolios([...portfolios, ...data.portfolios]): setPortfolios(data.portfolios)
      setMorePortfolios(data.more_portfolios)
    } catch(error) {
      console.error(error)
    } finally {
      if (morePortfolios) {
        setMoreLoading(false)
        setOffset(offset + 1)
      } else {
        setLoading(false)
      }
    }
  }

  const requestStackSuggestions = async () => {
    try {
      const response = await API.get('/stacks')
      const data = await response.json()
      setStackSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const findSelectedButton = (params) => {
    switch(Object.keys(params).length) {
    case 0:
      return 'All'
    case 1:
      return Object.values(params)[0]
    default:
      return ''
    }
  }

  const parsedSearchParams = () => {
    const params = queryString.parse(location.search, { arrayFormat: 'comma', parseBooleans: true })
    // If 'stacks' is a single value (parsed as a string), put into array
    if (typeof(params['stacks']) == 'string') {
      params['stacks'] = [params['stacks']]
    }
    return params
  }

  const handleNewSeachParams = (newParams) => {
    // redirectToCompanySignupCheck()
    setSearchParams(newParams)
    updateURLWithParams(newParams)
    setOffset(0)
    setSelectedButton(findSelectedButton(newParams))
  }

  // const redirectToCompanySignupCheck = () => {
  //   if (model == 'portfolios' && !currentUser) {
  //     window.location.href = window.location.origin + '/companies/signup'
  //   }
  // }

  // clear selected stacks with the selected button
  const handleFeatureButtonClick = ({type, name}) => {
    let { stacks, ...resetSearchParams } = searchParams
    if (name !== 'All') {
      resetSearchParams = { [type]: [name], ...resetSearchParams } // add new params at the beginning
    }
    setSelectedButton(name)
    handleNewSeachParams(resetSearchParams)
  }

  const handleSortChange = (event) => {
    const value = event.target.value
    const { sort, ...resetSearchParams } = searchParams
    resetSearchParams['sort'] = value // keep sort param at the end
    handleNewSeachParams(resetSearchParams)
  }

  const updateURLWithParams = (params = searchParams) => {
    const { sort, ...reorderSearchParams } = params
    const pathname = {
      projects: 'projects',
      portfolios: 'portfolios'
    }
    history.push({
      pathname: `/${pathname[model]}`,
      search: '?' + queryString.stringify({...reorderSearchParams, sort}, { arrayFormat: 'comma', sort: false, skipNull: true, skipEmptyString: true })
    })
  }

  const handleStackAddition = (stack) => {
    if (searchParams.stacks) {
      const alreadyAdded = searchParams['stacks'].some(addedTag => addedTag === stack.name)
      if (alreadyAdded) { return }
    }
    const safeTags = searchParams.stacks ?? []
    const newTags = [...safeTags, stack.name]
    handleNewSeachParams({ ...searchParams, stacks: newTags })
  }

  const deleteTagFromSearchParams = (index, type) => {
    const newSearchTags = searchParams[type]
    newSearchTags.splice(index, 1)
    handleNewSeachParams({ ...searchParams, [type]: newSearchTags })
  }

  const handleStackDelete = (index) => {
    deleteTagFromSearchParams(index, 'stacks')
    const newStacksParams = stacksFilterParams
    newStacksParams.splice(index, 1)
    setStacksFilterParams(newStacksParams)
  }

  const handleLocationSelected = (place) => {
    if (!place.address_components) {
      alert('Pretty please, with a cherry on top, begin typing and select a location from the autocomplete dropdown 🍒🙏')
      return
    }
    setFilterLocation(formatGoogleFilterLocation(place))
  }

  // Clear location searchParams the location is completely removed
  const handleLocationClear = (event) => {
    if (!searchParams.city && !searchParams.state && !searchParams.country) { return }
    if (event.target.value == '') {
      setFilterLocation({})
    }
  }

  const handleRemoteCheckboxUpdate = (event) => {
    const { checked } = event.target
    const { remote, ...newSearchParams } = searchParams
    if (checked) { newSearchParams['remote'] = true }
    handleNewSeachParams(newSearchParams)
  }

  const handleAvailableCheckboxUpdate = (event) => {
    const { checked } = event.target
    const { hire, ...newSearchParams } = searchParams
    if (checked) { newSearchParams['hire'] = true }
    handleNewSeachParams(newSearchParams)
  }

  const handleDeveloperTypeClick = (event) => {
    const value = event.target.value
    const { type, ...newSearchParams } = searchParams
    if (type != value) {
      newSearchParams['type'] = value
    } else {
      event.target.defaultChecked = false
    }
    handleNewSeachParams(newSearchParams)
  }

  const renderFilterButtonSection = () => (
    <div className='button-section'>
      <select
        id='sort'
        className='sort-dropdown'
        onChange={handleSortChange}
        value={searchParams.sort}
      >
        <option value={'popular'}>Popular</option>
        <option value={'views'}>Views</option>
        <option value={'recent'}>Recent</option>
      </select>
      {
        model == 'projects' && (
          <div className='feature-buttons'>
            {
              featureButtons.map(({type, name}) => {
                const buttonClasses = classNames('small', 'filter', 'no-wrap',{ selected: name == selectedButton })
                return (
                  <Button
                    key={name}
                    extraClasses={buttonClasses}
                    onClick={() => handleFeatureButtonClick({type, name})}
                  >
                    { name }
                  </Button>
                )
              })
            }
          </div>
        )
      }
      <Button
        extraClasses={classNames('small', 'no-wrap', 'filter', { selected: showFilters })}
        icon={'funnel'}
        onClick={() => setShowFilters(!showFilters)}
      >
        {model == 'projects' ? 'More stacks' : 'Show filters'}
      </Button>
    </div>
  )

  const renderFilterSection = () => (
    <div className={classNames('filter-section', 'form-container', model, { hide: !showFilters })}>
      <div className='filter-container'>
        <div className='left-filters'>
          {model == 'portfolios' && (
            <RadioButtonCards
              cards={[
                {
                  value: 'full_stack',
                  emoji: '🥞',
                  text: 'Full-stack'
                },
                {
                  value: 'front_end',
                  emoji: '🎨',
                  text: 'Front-end'
                },
                {
                  value: 'back_end',
                  emoji: '🎒',
                  text: 'Back-end'
                }
              ]}
              values={searchParams}
              valuesKey='type'
              name='type'
              onClick={handleDeveloperTypeClick}
              cardCount='three'
              extraClasses={['filter']}
              errors={[]}
            />
          )}
          <label
            htmlFor='stacks'
            className='stack-filter'
          >
            Tech stack
            <ReactTags
              id='stacks'
              placeholderText='i.e. Ruby'
              minQueryLength={1}
              tags={stacksFilterParams}
              suggestions={stackSuggestions}
              onAddition={handleStackAddition}
              onDelete={handleStackDelete}
            />
          </label>
          {model == 'portfolios' && (
            <label
              htmlFor='location'
              className='location-filter'
              >
              Location
              <Autocomplete
                id='location'
                apiKey={google_maps_api_key}
                defaultValue={searchParams.city || searchParams.state || searchParams.country}
                options={{
                  fields: ['address_components'],
                  types: ['geocode']
                }}
                language='en'
                onPlaceSelected={handleLocationSelected}
                onChange={handleLocationClear}
                type='search'
                autoComplete='off'
                placeholder='City, state or country'
                />
            </label>
          )}
        </div>
        {model == 'portfolios' && (
          <div className='checkbox-filters'>
            <label
              htmlFor='hire'
              className='hire-checkbox'
            >
              <input
                id='hire'
                type='checkbox'
                defaultChecked={searchParams.hire}
                onClick={handleAvailableCheckboxUpdate}
              />
              <div className='checkbox-button'>
                <span className='emoji'>🚀</span>
                <span>Available for hire</span>
              </div>
            </label>
            <label
              htmlFor='remote'
              className='remote-checkbox'
            >
              <input
                id='remote'
                type='checkbox'
                defaultChecked={searchParams.remote}
                onClick={handleRemoteCheckboxUpdate}
              />
              <div className='checkbox-button'>
                <span className='emoji'>🌎</span>
                <span>Remote worker</span>
              </div>
            </label>
          </div>
        )}
      </div>
    </div>
  )

  const renderProjectCardsSection = () => {
    return (
      <>
        {
          loading
            ? <Loader centerLoader/>
            : <ProjectCards
                projects={projects}
                extraClasses={['mb-40', 'mt-20']}
                discover
              />
        }
        {
          (!loading && moreProjects && projects.length > 0) && (
            <div className='more-projects-container'>
              <Button
                onClick={() => requestProjects({moreProjects: true})}
                submitting={moreLoading}
                submittingText={'Loading'}
              >
                More projects
              </Button>
            </div>
          )
        }
      </>
    )
  }

  const renderPortfolioCardsSection = () => {
    return (
      <>
        {
          loading
            ? <Loader centerLoader/>
            : <PortfolioCards portfolios={portfolios} />
        }
        {
          (!loading && morePortfolios && portfolios.length > 0) && (
            <div className='more-projects-container'>
              <Button
                onClick={() => requestPortfolios({morePortfolios: true})}
                submitting={moreLoading}
                submittingText={'Loading'}
                // extraClasses={[!currentUser ? 'blur' : '']}
              >
                More portfolios
              </Button>
            </div>
          )
        }
      </>
    )
  }

  return (
    <>
      <section className={classNames('discover-filter-container', model)}>
        { renderFilterButtonSection() }
        { renderFilterSection() }
      </section>
      {
        model == 'projects'
        ? renderProjectCardsSection()
        : renderPortfolioCardsSection()
      }
    </>
  )
}

export default DiscoverFilter
