// Ensure this matches meta.yml & meta_tags_helper.rb

import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import BotPresentContext from '../context/BotPresentContext'
import trooplImage from 'images/troopl-meta-image.jpg'

const SEOMetaData = ({dynamicTitle, dynamicDescription, dynamicKeywords, dynamicImage}) => {
  const [bot] = useContext(BotPresentContext)

  const title = dynamicTitle ?? 'Build a portfolio that gets you hired | Troopl'
  const description = dynamicDescription ?? 'Troopl is a portfolio builder for developers & data scientists to showcase work and be discovered.'
  const keywords = dynamicKeywords ?? 'portfolio, portfolio builder, developer, web developer, coding challenges, data scientist, tech career, tech recruitment, developer jobs'
  const image = dynamicImage ?? trooplImage
  const author = 'troopl'
  const url = window.location
  const twitterHandle = '@troopl_'

  // If a bot is present, don't load this meta data
  // Stops AHREFS error showing double descriptions
  if (bot) { return null }

  return (
    <Helmet>
      <title>{ title }</title>
      <meta name='description' content={ description } />
      <meta name='author' constent={ author } />
      <meta name='keywords' content={ keywords } />

      {/* Facebook Open Graph meta data */}
      <meta property='og:title' content={ title } />
      <meta property='og:description' content={ description } />
      <meta property='og:image' content={ image } />
      <meta property='og:url' content={ url } />
      <meta property='og:site_name' content={ author } />
      <meta property='og:type' content='website' />

      {/* Twitter Card meta data */}
      <meta property='twitter:title' content={ title } />
      <meta property='twitter:description' content={ description } />
      <meta property='twitter:card' content={ image } />
      <meta property='twitter:site' content={ twitterHandle } />
      <meta property='twitter:creator' content={ twitterHandle } />
      <meta property='twitter:image' content={ image } />
    </Helmet>
  )
}

export default SEOMetaData
