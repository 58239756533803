import React, { useState } from 'react'
import chevron from 'images/svg/chevron-down.svg'
const classNames = require('classnames')

const Acordion = ({headline, children}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const chevronClasses = classNames('chevron', { 'show-dropdown': showDropdown })
  const dropdownClasses = classNames('dropdown', { 'show-dropdown': showDropdown })

  const handleClick = () => setShowDropdown(!showDropdown)

  return (
    <div
      className='acordion-container'
      onClick={handleClick}
    >
      <div className='top'>
        <h3 className='headline'>{ headline }</h3>
        <img src={chevron} alt='chevron pointing down' className={chevronClasses}/>
      </div>
      <div className={dropdownClasses}>
        { children }
      </div>
    </div>
  )
}

export default Acordion
