import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
const classNames = require('classnames');

const Tabs = ({tabs, baseURL}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const history = useHistory()
  const { tabSlug } = useParams()

  useEffect(() => {
    setSelectedTab(getTabIndex(tabSlug))
    return () => {}
  }, [tabSlug])

  const onTabSelect = (event) => {
    if (baseURL) {
      history.push(`${baseURL}/${event.target.getAttribute('slug')}`)
    } else {
      setSelectedTab(event.target.getAttribute('index'))
    }
  }

  const getTabIndex = (tabSlug) => {
    if (!baseURL) { return 0 }
    return tabs.findIndex(tab => tab.slug == tabSlug)
  }

  return (
    <div className='tabs-container'>
      <ul className='tab-list'>
        {
          tabs.map((tab, index) => {
            const tabClasses = classNames(
              'tab',
              { selected: selectedTab == index },
              { disabled: tab.disabled }
            )

            return (
              <li
                key={index}
                index={index}
                className={tabClasses}
                onClick={onTabSelect}
                slug={tab.slug}
              >
                { tab.title }
              </li>
            )
          })
        }
      </ul>
      <div className='content'>
        { tabs[selectedTab].content }
      </div>
    </div>
  )
}

export default Tabs
