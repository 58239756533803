import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from './Button'
import AliceCarousel from 'react-alice-carousel'

const PortfolioCard = ({ portfolio }) => {
  const history = useHistory()

  const handleCardClick = (slug) => {
    history.push(`/${slug}`)
  }

  const handleViewPortfolioClick = (portfolioSlug) => {
    history.push(`/${portfolioSlug}`)
  }

  const createProjectImages = (portfolio) => {
    return (
      portfolio?.project_cards.map(({image, title, slug}, index) => {
        if (!image) {
          return (
            <div
              className='no-image-container'
              onClick={() => handleCardClick(slug)}
            >
              <h5 className='project-type'>
                { title }
              </h5>
              <div className='rollover'/>
            </div>
          )
        }

        return (
          <>
            <img
              key={index}
              className='carousel-image'
              src={image}
              alt={`${title} image`}
              onClick={() => handleCardClick(slug)}
            />
            <div className='rollover'/>
          </>
        )
      })
    )
  }

  return (
    <div className='portfolio-card'>
      <div className='info-section'>
        <div className='image-name'>
          {
            portfolio.picture
            ? (
              <Link to={`/${portfolio.slug}`}>
                <img
                  src={portfolio.picture}
                  className='picture'
                  alt={`${portfolio.full_name} picture`}
                />
              </Link>
            ) : (
              <Link to={`/${portfolio.slug}`}>
                <div className='no-picture'>
                  <h3>{portfolio.initials}</h3>
                </div>
              </Link>
            )
          }
          <Link to={`/${portfolio.slug}`}>
            <h4>{portfolio.full_name}</h4>
          </Link>
        </div>
        <div className='columns'>
          <div className='left'>
            <p>{portfolio.display_developer_type}</p>
            <p>📍 {portfolio.city}</p>
          </div>
          <div className='right'>
          { portfolio.open_to_opportunities ? <p>⭐ ️Available to hire</p> : null }
          { portfolio.open_to_remote_work ? <p>🌎 Remote worker</p> : null }
          </div>
        </div>
      </div>
      <AliceCarousel
        mouseTracking
        items={createProjectImages(portfolio)}
        disableButtonsControls
        autoWidth
        paddingRight={30 + (portfolio.project_cards.length * 18)}
        paddingLeft={30}
        mouseTracking
      />
      <div className='button-section'>
      <Button
        extraClasses={['fill-width', 'mt-10']}
        onClick={() => handleViewPortfolioClick(portfolio.slug)}
      >
        View portfolio
      </Button>
      </div>
    </div>
  )
}

export default PortfolioCard
