import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import CurrentUserContext from '../context/CurrentUserContext'
import TrooplPlusIcon from '../components/TrooplPlusIcon'

const FormInput = (props) => {
  const {
    values,
    valuesKey,
    type,
    label,
    placeholder,
    labelNote,
    labelClass,
    onChange,
    onBlur,
    autoFocus,
    errors,
    plus
  } = props

  const [currentUser] = useContext(CurrentUserContext)

  const plusDisabled = () => {
    return !!plus && !currentUser.plus
  }

  return (
    <label
      htmlFor={valuesKey}
      className={labelClass}
      disabled={plusDisabled()}
    >
      <div className='title'>
        <span className='label'>{ label }</span>
        { plus && <TrooplPlusIcon /> }
      </div>
      {labelNote}
      <input
        autoFocus={autoFocus}
        id={valuesKey}
        type={type ?? 'text'}
        placeholder={placeholder}
        aria-required={!!onBlur}
        aria-invalid={!!errors[valuesKey]}
        value={values[valuesKey] ?? ''}
        onChange={onChange}
        onBlur={onBlur}
        disabled={plusDisabled()}
      />
      {plusDisabled() ? <Link to='/portfolios/plus' className='troopl-plus-cta'>Available on Troopl Plus 👑  Upgrade now</Link> : null}
      {errors[valuesKey] && <span className='note error'>{errors[valuesKey]}</span>}
    </label>
  )
}

export default FormInput
