export const formatGoogleLocation = (place) => {
  const { address_components, formatted_address } = place
  const location = {
      google_display_name: formatted_address,
      city: cityAddressComponentLongName(address_components),
      state: stateAddressComponentLongName(address_components),
      country: addressComponentLongName(address_components, 'country')
    }
  return JSON.stringify(location)
}

// Find the most accurate location level from search and
// return either: locality, state, country as a {type, name} object
export const formatGoogleFilterLocation = (place) => {
  const { address_components } = place
  const {long_name, types} = address_components[0]
  const level = {
    locality: 'city',
    sublocality_level_1: 'city',
    sublocality: 'city',
    administrative_area_level_1: 'state',
    administrative_area_level_2: 'state',
    administrative_area_level_3: 'state',
    country: 'country'
  }
  const foundType = Object.entries(level).find(([key, _value]) => types.includes(key) )
  if (!foundType) { return false }
  return { type: foundType[1], name: long_name }
}

const addressComponentLongName = (components, type) => {
  return components.find(component => component.types.includes(type)).long_name
}

// Sometimes Google dodesn't provide the high level state (administrative_area_level_1)
// i.e. 'Patras, Greece'
// And sometimes it doesn't prodive a state at all
// This function ensures the highest level state available is returned
// Or null... so our app doesn't crash and burn
const cityAddressComponentLongName = (components) => {
  const cityLevels = ['locality', 'sublocality_level_1', 'sublocality']
  const highestCityLevel = cityLevels.find(cityLevel => components.find(component => component.types.includes(cityLevel)))
  return highestCityLevel === undefined ? null : addressComponentLongName(components, highestCityLevel)
}

const stateAddressComponentLongName = (components) => {
  const stateLevels = ['administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3']
  const highestStateLevel = stateLevels.find(stateLevel => components.find(component => component.types.includes(stateLevel)))
  return highestStateLevel === undefined ? null : addressComponentLongName(components, highestStateLevel)
}

