
import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../Button'
import Acordion from '../Accordion'
import Loader from '../Loader'
import PortfolioCard from '../PortfolioCard'
import FetchWrapper from '../../helpers/FetchWrapper'
import showFlashMessage from '../../helpers/showFlashMessage'
import CurrentUserContext from '../../context/CurrentUserContext'
import mascotsImage from 'images/troopl-header-mascots-flipped.png'
import resumeManImage from 'images/landing-page-resume-man.png'
import jumpingWomanImage from 'images/landing-page-jumping-woman.png'
import arrow from 'images/svg/hand-drawn-arrow.svg'
import rubyOnRailsLogo from 'images/svg/logos/ruby-on-rails.svg'
import reactLogo from 'images/svg/logos/react.svg'
import nodejsLogo from 'images/svg/logos/nodejs.svg'
import pythonLogo from 'images/svg/logos/python.svg'
import javascriptLogo from 'images/svg/logos/javascript.svg'
import numpyLogo from 'images/svg/logos/numpy.svg'
import rubyLogo from 'images/svg/logos/ruby.svg'
import tensorflowLogo from 'images/svg/logos/tensorflow.svg'

const HomePage = ({newUsers}) => {
  const [testimonialPortfolios, setTestimonialPortfolios] = useState([])
  const [loadingTestimonials, setLoadingTestimonials] = useState(true)
  const [currentUser] = useContext(CurrentUserContext)
  const history = useHistory()
  const [API] = useState(new FetchWrapper)

  useEffect(() => {
    getTestimonialPortfolios()
    return () => {}
  }, [])

  // Not in use
  // Redirects signed in users to the projects page
  // const checkForSignedInUserAndRedirect = () => {
  //   if (currentUser) {
  //     showFlashMessage(`Since you've already signed up, check out the amazing work on Troopl, get inspired, and get building!`, 'notice')
  //     history.push('/projects')
  //   }
  // }

  const getTestimonialPortfolios = async () => {
    try {
      const response = await API.get('/testimonial_portfolios')
      const data = await response.json()
      setTestimonialPortfolios(data)
    } catch(error) {
      console.error(error)
    } finally {
      setLoadingTestimonials(false)
    }
  }

  return (
    <main className='home-page-container'>
      <section className='header-container'>
        <div className='header'>
          <div className='image'>
            <img src={mascotsImage} alt='Happy Troopl 3D characters' className='mascots'/>
          </div>
          <div className='cta'>
            <h1 className='headline'>
              Build a portfolio<br/>
              that gets you hired.
            </h1>
            <p className='subhead'>
              No more being ghosted. Stand out with a kickass <b>Web Developer</b> or <b>Data Scientist</b> portfolio demonstrating your skills.
            </p>
            <div className='button-section'>
              <a href='/signup' className='tablet'>
                <Button extraClasses={['welcome-header']} id='signup-button-home-page-banner-cta'>Build your free portfolio in minutes ⚡️</Button>
              </a>
              <a href='/signup' className='mobile'>
                <Button id='signup-button-home-page-banner-cta-mobile'>Build your free portfolio ⚡️</Button>
              </a>
              <img src={arrow} alt='hand drawn arrow pointing to button' className='arrow'/>
              <p className='new-user-text'>{ newUsers } people joined this month!</p>
            </div>
          </div>
        </div>
      </section>

      <div className='full-width-container beige'>
        <section className='section portfolio-design'>
          <h2>Designed to make you look awesome 👑</h2>
          <p className='subhead'>
            Share your story and unique skillset with a professionally designed
            portfolio that'll make you stand out and be remembered.
          </p>
          <iframe id='design-walkthrough' className='tella-iframe' src="https://www.tella.tv/video/ckwahuhx5000109ksfhjd0umr/embed" allowFullScreen/>
          <a
            href='/designs/demo/denis'
            target='_blank'
          >
            <Button>
              See design demo for a closer look 👀
            </Button>
          </a>
        </section>

        <section className='section before-and-after'>
          <h2>A better way to land a job ✌️</h2>
          <div className='before'>
            <div className='text before-text'>
              <h4>Before Troopl</h4>
              <p>
                Overwhelmed applying for jobs, demoralised by lack of feedback,
                forever writing resumes, cover letters, and chasing recruiters.
              </p>
            </div>
            <img src={resumeManImage} alt='3D character looking at resume' className='image'/>
          </div>
          <div className='after'>
            <div className='text after-text'>
              <h4>After Troopl</h4>
              <p>
                Focused time building and learning, supported by a community of
                like-minded builders, hired by simply sharing one portfolio link.
              </p>
            </div>
            <img src={jumpingWomanImage} alt='3D character jumping with joy' className='image'/>
          </div>
        </section>
      </div>

      <section className='section what-matters-most'>
        <h2>Focus on what matters most ⏳</h2>
        <p className='subhead'>
          Keep learning and grow your skills by completing
          our challenges, building projects for your
          portfolio, and engaging with our supportive community.
        </p>
        <Acordion headline='⏰  Spend more time building and less time applying'>
          <p>
            Cover letters, connecting on LinkedIn, re-working your CV,
            and networking meet-ups. There are plenty of ways to spend
            time looking for a job. But where you’ll see the biggest
            impact is to focus on continuing to learn and build
            interesting projects to share with the right people.
          </p>
        </Acordion>
        <Acordion headline='🙈  Show don’t tell'>
          <p>
            It’s impossible to convey your skill, passion, and uniqueness
            in a cover letter or CV. The most impactful way to communicate
            your skills isn’t to tell people what you can do, it’s to show
            them with a portfolio of projects.
          </p>
        </Acordion>
        <Acordion headline='🏆  Coding challenges to inspire you'>
          <p>
            Sometimes the hardest part of building a new project is knowing
            what to build. On Troopl, you’ll find a series of fun, real-world
            projects to challenge and inspire you to grow your portfolio.
            <Link to='/challenges'>View our challenge library</Link>
          </p>
        </Acordion>
        <Acordion headline='🎯  It’s all about self-development'>
          <p>
            Building projects is the best way to improve, learn
            valuable new skills, and impress companies.
          </p>
        </Acordion>
        <Acordion headline='👋  You’re not alone, join our community'>
          <p>
            Find peers & grow your skills together, get support when you
            feel lost or stuck, have your code reviewed, and get feedback
            on your projects.
          </p>
        </Acordion>
      </section>

      <section className='section testimonials'>
        <h2>
          Showcase your work and get hired 🙌<br/>
        </h2>
        <h4>(Like these legends 🎉)</h4>
        <div className='testimonial card-left'>
          <div className='portfolio-card-container single-card'>
          {
            loadingTestimonials
            ? <Loader extraClasses={['dead-center']}/>
            : <PortfolioCard portfolio={testimonialPortfolios[0]} />
          }
          </div>
          <div className='quote'>
            <p>
              “My experience using Troopl and being part of the community has been fantastic.
              We support each other, give project feedback, and help each other with bugs we run into.
              Troopl’s unique challenge section is a winner for me. It gave me motivation and inspiration
              during my post-bootcamp journey and kept me on track to my end goal of landing my first
              frontend developer role.”
              <br/>
              <br/>
              — James
            </p>
          </div>
        </div>
        <div className='testimonial card-right'>
          <div className='quote'>
             <p>
               “For me, Troopl was the most efficient way to build a useful portfolio as a data scientist.
               It allows me to showcase my code in a layman-friendly format where non-technical recruiters
               and hiring managers can readily understand my coding skills and competencies. It was
               incredibly fast to build a portfolio from my existing GitHub repositories, web apps,
               presentations, notebooks, and articles.”
               <br/>
               <br/>
               — Elizabeth
             </p>
           </div>
          <div className='portfolio-card-container single-card'>
          {
            loadingTestimonials
            ? <Loader extraClasses={['dead-center']}/>
            : <PortfolioCard portfolio={testimonialPortfolios[1]} />
          }
          </div>
        </div>
        <div className='testimonial card-left'>
          <div className='portfolio-card-container single-card'>
          {
            loadingTestimonials
            ? <Loader extraClasses={['dead-center']}/>
            : <PortfolioCard portfolio={testimonialPortfolios[2]} />
          }
          </div>
          <div className='quote'>
            <p>
              “Since I found Troopl, it instantly became my main platform to share
              online! It’s friendly, easy to create a portfolio, integrated with
              Github, well-organized, all the information about yourself is there,
              and, most importantly for me, it's visual. I recommend Troopl to
              everybody who is looking for a job in tech. It worked for me!”
              <br/>
              <br/>
              — Igor
            </p>
          </div>
        </div>
      </section>

      <section className='section stacks'>
        <h2>
          Build awesome stuff in your stack 🥞
        </h2>
        <h2 className='subhead'>(or learn something new)</h2>
        <div className='stack-cards'>
          <Link to='/stacks/ruby-on-rails'>
            <div className='card'>
              <img src={rubyOnRailsLogo} alt='Ruby on Rails logo' className='logo' />
              <p className='title'>Ruby on rails</p>
            </div>
          </Link>
          <Link to='/stacks/react'>
            <div className='card'>
              <img src={reactLogo} alt='React logo' className='logo' />
              <p className='title'>React</p>
            </div>
          </Link>
          <Link to='/stacks/nodejs'>
            <div className='card'>
              <img src={nodejsLogo} alt='Node.js logo' className='logo' />
              <p className='title'>Node.js</p>
            </div>
          </Link>
          <Link to='/stacks/python'>
            <div className='card'>
              <img src={pythonLogo} alt='Python logo' className='logo' />
              <p className='title'>Python</p>
            </div>
          </Link>
          <Link to='/stacks/javascript'>
            <div className='card'>
              <img src={javascriptLogo} alt='JavaScript logo' className='logo' />
              <p className='title'>JavaScript</p>
            </div>
          </Link>
          <Link to='/stacks/numpy'>
            <div className='card'>
              <img src={numpyLogo} alt='NumPy logo' className='logo' />
              <p className='title'>NumPy</p>
            </div>
          </Link>
          <Link to='/stacks/ruby'>
            <div className='card'>
              <img src={rubyLogo} alt='Ruby logo' className='logo' />
              <p className='title'>Ruby</p>
            </div>
          </Link>
          <Link to='/stacks/tensorflow'>
            <div className='card'>
              <img src={tensorflowLogo} alt='Tensorflow logo' className='logo' />
              <p className='title'>Tensorflow</p>
            </div>
          </Link>
        </div>
      </section>

      <section className='section one-link'>
        <h2>One link to rule them all 🔗</h2>
        <p className='subhead'>
          Say goodbye to individually sharing your projects,
          code repositories, social profiles, and CV.
          With Troopl, everything's on one link — that you pick.
        </p>
        <a href='/signup'>
          <Button id='signup-button-home-page-end-cta'>Claim your unique portfolio link ⚡️</Button>
        </a>
        <div className='pill-container green'><p>troopl.com/benjamin</p></div>
        <div className='pill-container yellow'><p>troopl.com/mehr</p></div>
        <div className='pill-container blue'><p>troopl.com/ibrahim</p></div>
        <div className='pill-container purple'><p>troopl.com/tristan</p></div>
      </section>
    </main>
  )
}

export default HomePage
