import React from 'react'
import { termsAndConditions } from '../../copy/termsAndConditions'

const TermsPage = () => {
  return (
    <main className='page-content-container legal-page-container'>
      <header>
        <h1>Terms of service 👩🏽‍🚀</h1>
      </header>
      {
        termsAndConditions.map(({headline, body}, index) => (
          <section key={index}>
            <h3>{headline}</h3>
            {body}
          </section>
        ))
      }
    </main>
  )
}

export default TermsPage
