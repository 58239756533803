import React, { useState } from 'react'
import dragSVG from 'images/svg/drag.svg'

const DragToReorder = ({
  showDrag,
  order,
  lastDraggedOrder,
  handleOrderChange,
  setLastDraggedOrder,
  children,
  ...props
}) => {
  const handleDragStart = (e) => {
    setLastDraggedOrder(order)
    e.currentTarget.classList.add('on-drag')
  }

  const handleDragOver = (e) => {
    e.currentTarget.classList.add('over')
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('on-drag')
  }

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('over')
  }

  const handleDrop = (e) => {
  handleOrderChange(lastDraggedOrder, order)
    e.currentTarget.classList.remove('over')
  }

  if (!showDrag) { return children }

  return (
    <div
      className='drag-to-reorder-container'
      draggable
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      { ...props }
    >
      <div className='draggable-area'>
        <img
          src={dragSVG}
          alt='drag icon'
          className='icon'
        />
        <span className='text'>Drag to reorder</span>
      </div>
      { children }
    </div>
  )
}

export const useLastDraggedOrder = () => {
  const [lastDraggedOrder, setLastDraggedOrder] = useState(0)

  return [lastDraggedOrder, setLastDraggedOrder]
}

export default DragToReorder
