import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
const classNames = require('classnames')
import Button from '../Button'
import Tags from '../Tags'
import Loader from '../Loader'
import ProjectCards from '../ProjectCards'
import FetchWrapper from '../../helpers/FetchWrapper'
import SEOMetaData from '../SEOMetaData'

const ChallengeShowPage = () => {
  const [challenge, setChallenge] = useState({})
  const [loading, setLoading] = useState(true)
  const [submittingRegistration, setSubmittingRegistration] = useState(false)
  const [API] = useState(new FetchWrapper)
  const { challengeSlug } = useParams()

  useEffect(() => {
    requestChallenge()
    return () => {}
  }, [])

  const requestChallenge = async () => {
    try {
      const response = await API.get(`/challenges/${challengeSlug}`)
      const data = await response.json()
      setChallenge(data)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChallengeRegistrationClick = async () => {
    if (challenge.current_user_registered) { return }

    try {
      setSubmittingRegistration(true)
      const response = await API.post('/challenge_registrations', {id: challenge.id})
      const data = await response.json()
      setChallenge(data)
    } catch(error) {
      console.error(error)
    } finally {
      setSubmittingRegistration(false)
    }
  }

  const showChallengeCards = () => {
    return challenge.challenge_status != 'upcoming'
  }

  const pillClasses = classNames(
    'pill-container', 'challenge-show', challenge.display_challenge_status == 'Evergreen' ? 'green' : 'purple'
  )

  const techStackClasses = classNames(
    'mt-20',
    'width-90',
    {'mb-40': !showChallengeCards() }
  )

  if (loading) {
    return <Loader pageLoader />
  }

  const registrationButtonStatus = {
    icon: challenge.current_user_registered ? 'check_circle' : 'check_circle_outline',
    text: challenge.current_user_registered == null
          ? 'Sign in to register for this challenge'
          : challenge.current_user_registered ? "Thanks — you're registered for this challenge" : 'Register for this challenge',
    locked: !(challenge.current_user_registered == false)
  }

  return (
    <>
      <SEOMetaData
        dynamicTitle={`${challenge.title} | Sponsored by ${challenge.company.name} | Troopl challenge`}
        dynamicDescription={ challenge.intro }
      />
      <main className='page-content-container challenge-show-page-container'>
        <header>
          <div className={pillClasses}>
            <p>{challenge.display_challenge_status}</p>
          </div>
          <h4 className='developer-type'>{challenge.developer_type} challenge 🏆</h4>
          <h1>{challenge.title}</h1>
          <div className='registration-container'>
            <Button
              icon={registrationButtonStatus.icon}
              onClick={handleChallengeRegistrationClick}
              locked={registrationButtonStatus.locked}
              disabled={challenge.current_user_registered == null}
              submittingText='Registering...'
              submitting={submittingRegistration}
            >
              { registrationButtonStatus.text}
            </Button>
            {
              !challenge.current_user_registered && (
                <p className=''>
                  Enrol so we can support you and share any relevant info.
                </p>
              )
            }
          </div>
          <div className='sponsor-container'>
            <p>Sponsored by</p>
            <a href={challenge.company.url} target='blank'>
              <img src={challenge.company.logo} alt={`${challenge.company.name} logo`} className='logo'/>
            </a>
          </div>
        </header>
        <section className='challenge-info'>
          <div className='description'>
            { ReactHtmlParser(challenge.description) }
            {
              challenge.company.stacks.length > 0 && (
                <p className='stack-note'>
                  Feel free to complete this challenge in your preferred tech stack. But to really woo the sponsor, consider using theirs.
                </p>
              )
            }
          </div>
        </section>
        <Tags
          title={`${challenge.company.name}'s tech stack`}
          tags={challenge.company.stacks}
          extraClasses={techStackClasses}
        />
        {
          showChallengeCards() && (
            <ProjectCards
              projects={challenge.projects}
              title='Submitted projects'
              extraClasses={['mt-30']}
              challengeShow
              challengeID={challenge.id}
            />
          )
        }
      </main>
    </>
  )
}

export default ChallengeShowPage
