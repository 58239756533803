import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
const classNames = require('classnames')

const ChallengeCards = ({challenges}) => {
  return (
    <section className='challenge-cards-container'>
      {
        challenges.map((challenge, index) => {
          const {
            title,
            intro,
            developer_type,
            slug,
            display_challenge_status,
            company: {
              name,
              logo,
              url
            }
          } = challenge

          const statusClasses = classNames(
            'status', display_challenge_status == 'Evergreen' ? 'green' : 'purple'
          )

          return (
            <div key={index} className='challenge-card'>
              <div className={statusClasses}>{display_challenge_status}</div>
              <h5 className='type'>{developer_type} ⚡️</h5>
              <h2 className='title'>{title}</h2>
              <p className='intro'>{intro}</p>
              <div className='sponsor-container'>
                <p>Sponsored by</p>
                <a href={url} target='blank'>
                  <img src={logo} alt={`${name} logo`} className='logo'/>
                </a>
              </div>
            <Link to={`/challenges/${slug}`}>
              <Button>See challenge 🙈</Button>
            </Link>
            </div>
          )
        })
      }
    </section>
  )
}

export default ChallengeCards
