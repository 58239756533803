import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import CurrentUserContext from '../../context/CurrentUserContext'
import Button from '../Button'
import people1 from 'images/people_1_2x.jpg'
import people2 from 'images/people_2_2x.jpg'
import people3 from 'images/people_3_2x.jpg'
import people4 from 'images/people_4_2x.jpg'
import people5 from 'images/people_5_2x.jpg'


const AboutPage = () => {
  const [currentUser] = useContext(CurrentUserContext)

  return (
    <main className='page-content-container about-page-container'>
      <header>
        <h1>
          Create portfolio.<br/>
          Be <span className='troopl-gradient-text'>discovered</span> 🦄
        </h1>
        <h5 className='subhead'>
          Troopl helps web developers & data scientists land a job. It's founded on several principles.
        </h5>
      </header>
      <section className='principal-container'>
        <div className='principal image-right'>
          <div className='text one'>
            <h3>Show don't tell</h3>
            <p>
              The most impactful way to communicate
              your skillset isn’t to <em>tell</em> people what you can do, it’s to <em>show</em> them with a portfolio of projects.
            </p>
          </div>
          <img src={people1} className='image one' alt='3d Troopl character'/>
        </div>
        <div className='principal image-left'>
          <div className='text'>
            <h3>Keep building</h3>
            <p>
              Complete projects to learn valuable new skills, be discovered, and woo companies.
              For ideas and inspiration, see our <Link to='/challenges'>challenges</Link>.
            </p>
          </div>
          <img src={people5} className='image five' alt='3d Troopl character'/>
        </div>
        <div className='principal image-right'>
          <div className='text'>
            <h3>Ability trumps experience</h3>
            <p>
              Demonstrate your abilities with projects, rather than years of work experience.
            </p>
          </div>
          <img src={people2} className='image two' alt='3d Troopl character'/>
        </div>
        <div className='principal image-left'>
          <div className='text'>
            <h3>Easy to be discovered</h3>
            <p>
              We help companies find exactly what they’re looking for. On Troopl, projects are
              visible to more employers (not just other developers and tech savvy recruiters).
            </p>
          </div>
          <img src={people4} className='image four' alt='3d Troopl character'/>
        </div>
        <div className='principal image-right'>
          <div className='text four'>
            <h3>Stand out with a portfolio</h3>
            <p>
             It’s tough to convey your coding skill, passion, and uniqueness in a cover letter or resume.
             Sharing a portfolio is the most effective way to begin a conversation with a company.
            </p>
          </div>
          <img src={people3} className='image three' alt='3d Troopl character'/>
        </div>
        <div className='principal image-center'>
          <div className='text five'>
            <h3>Focus on what matters most</h3>
            <p>
             Cover letters, connecting on LinkedIn, re-working your resume, and networking meet-ups. There's plenty of
             ways to spend time looking for a job. But where you’ll see the biggest impact is to focus on continuing to learn
             and build interesting projects to share with the right people.
            </p>
          </div>
        </div>
      </section>
      {/* Not showing the CTA is the user is signed in is a hacky solution
      But I can't figure out why directing to /signup when signed in triggers a 404
      It calls the project show page by creating the route 'portfolios/signup' */}
      { !currentUser && (
      <section className='call-to-action'>
        <h2>Create your free portfolio</h2>
        <h5 className='mt-10 mb-20'>And launch your career in tech.</h5>
        <a href='/signup'><Button>Get started 🚀</Button></a>
      </section>
      )}
    </main>
  )
}

export default AboutPage
