import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
const classNames = require('classnames');
import CurrentUserContext from '../../context/CurrentUserContext'
import ProjectCards from '../ProjectCards'
import ArticleCards from '../ArticleCards'
import Tip from '../Tip'
import PortfolioDesignCards from '../PortfolioDesignCards'
import StripePayment from '../StripePayment'
import Loader from '../Loader'
import Button from '../Button'
import Share from '../Share'
import FetchWrapper from '../../helpers/FetchWrapper'
import showFlashMessage from '../../helpers/showFlashMessage'
import { safeSlug } from '../../helpers/general'
import PortfolioForm from '../forms/PortfolioForm'
import { portfolioInputValidation, portfolioMultiInputValidation } from '../forms/formValidator'
import { formatGoogleLocation } from '../../helpers/formatGoogleLocation'

import Tabs from '../Tabs'

const PortfolioManagePage = () => {
  const [portfolio, setPortfolio] = useState({})
  const [projects, setProjects] = useState([])
  const [articles, setArticles] = useState([])
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext)
  const [educationSuggestions, setEducationSuggestions] = useState([])
  const [stackSuggestions, setStackSuggestions] = useState([])
  const [spokenLanguageSuggestions, setSpokenLanguageSuggestions] = useState([])
  const [picturePreview, setPicturePreview] = useState('')
  const [biographyPicturePreview, setBiographyPicturePreview] = useState('')
  const [CVPreview, setCVPreview] = useState('')
  const [location, setLocation] = useState({})
  const [educationLocation, setEducationLocation] = useState({})
  const [errors, setErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [API] = useState(new FetchWrapper)
  const history = useHistory()

  useEffect(async() => {
    try {
      setLoading(true)
      await requestPortfolio()
      await requestEducationSuggestions()
      await requestStackSuggestions()
      await requestSpokenLanguageSuggestions()
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    return () => {}
  }, [])

  useEffect(() => {
    setPortfolio({ ...portfolio, location })
    setErrors({ ...errors, city: false, location: false })
    return () => {}
  }, [location])

  useEffect(() => {
    setPortfolio({ ...portfolio, education_location: educationLocation })
    setErrors({ ...errors, education_city: false, education_location: false })
    return () => {}
  }, [educationLocation])

  useEffect(() => {
    checkFormErrors()
    return () => {}
  }, [errors])

  // Serialised cv & picture is a url string on existing portfolios, not a file
  // So removed from portfolio object and into preview states to ensure only files are submitted
  const requestPortfolio = async () => {
    try {
      const response = await API.get('/portfolios/manage')
      const data = await response.json()
      setMultipleData(data)
    } catch(error) {
      console.error(error)
    }
  }

  const setMultipleData = (data) => {
    const { user, cv, picture, biography_picture, projects, articles, ...portfolio } = data
    setPortfolio(portfolio)
    setProjects(projects)
    setCurrentUser(user)
    setArticles(articles)
    setPicturePreview(picture || user.picture_url)
    setBiographyPicturePreview(biography_picture)
    setCVPreview(cv)
  }

  const requestEducationSuggestions = async () => {
    try {
      const response = await API.get('/educations')
      const data = await response.json()
      setEducationSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const requestStackSuggestions = async () => {
    try {
      const response = await API.get('/stacks')
      const data = await response.json()
      setStackSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const requestSpokenLanguageSuggestions = async () => {
    try {
      const response = await API.get('/spoken_languages')
      const data = await response.json()
      setSpokenLanguageSuggestions(data)
    } catch(error) {
      console.error(error)
    }
  }

  const handleInputChange = (event) => {
    const { id, value, type, checked } = event.target;
    const setValue = type === 'checkbox' ? checked : value
    const newPortfolio = { ...portfolio, [id]: setValue }
    setPortfolio(newPortfolio)

    if (type === 'radio' || type === 'checkbox') {
      handleInputErrors(id, value)
    } else {
      if (errors[id] !== false) {
        handleInputErrors(id, value)
      }
    }

    handleInputEdgeCases(newPortfolio, id, value)
  }

  const handleInputEdgeCases = (newPortfolio, id, value) => {
    // Clear education_location from state when Autofill input is cleared
    // allowing users to remove a location from their education
    if (id == 'education_city' && value == '') {
      setPortfolio({ ...newPortfolio, education_location: ''})
    }
    // Clear all education info when switching education_type to self_taught
    if (id == 'education_type') {
      setPortfolio({
        ...newPortfolio,
        educations: [],
        batch: '',
        education_city: '',
        education_location: {},
        graduation_date: null
      })
      setErrors({
        ...errors,
        education_name: false,
        education_city: false,
        education_location: false,
        educations: false,
      })
    }

    if (id == 'portfolio_type') {
      if (value == 'other') {
        // Set portfolio type because there's only 1 option
        setPortfolio({ ...newPortfolio, developer_type: 'other_developer'})
      } else {
        // Prevent a web developer having a data scientist specialty, or vice versa
        setPortfolio({ ...newPortfolio, developer_type: '', other_developer_type: '' })
        setErrors({ ...errors, other_developer_type: false })
      }
    }

    // If error triggered on other developer type and then
    // new developer_type is selected (hiding other_developer field)
    if (id == 'developer_type' && value != 'other_developer') {
        setErrors({ ...errors, other_developer_type: false })
    }
  }

  const handleFileChange = (event) => {
    const { id, files } = event.target
    setPortfolio({  ...portfolio, [id]: files[0] })

    if (id === 'picture') {
      setPicturePreview(URL.createObjectURL(files[0]))
    }

    if (id === 'biography_picture') {
      setBiographyPicturePreview(URL.createObjectURL(files[0]))
    }

    if (id === 'cv') {
      setCVPreview(URL.createObjectURL(files[0]))
    }

    handleInputErrors(id, files[0])
  }

  const handleInputBlur = (event) => {
    const {id, value} = event.target
    // Only remove city errors when Location state is updated
    // We use the location field, not city, to save the location
    if (id === 'city') { return }
    handleInputErrors(id, value)
  }

  const handleMultiInputChange = (event) => {
    const { id, value, dataset: { index, valueskey, columnkey } } = event.target;
    const newValues = portfolio[valueskey]
    newValues[index][columnkey] = value
    const newPortfolio = { ...portfolio, [valueskey]: newValues }
    setPortfolio(newPortfolio)
    handleMultiInputErrors(id, value, valueskey)
  }

  const handleMultiInputBlur = (event) => {
    const {id, value, dataset: { valueskey }} = event.target
    handleMultiInputErrors(id, value, valueskey)
  }

  const addEmptyMultiInputKeysToState = (multiInputs, valuesKey) => {
    const newValueKeys = {}
    multiInputs.forEach(inputs => newValueKeys[inputs.key] = '')
    newValueKeys['order'] = portfolio[valuesKey].length + 1
    const newValues = portfolio[valuesKey]
    newValues.push(newValueKeys)
    const newPortfolio = { ...portfolio, [valuesKey]: newValues }
    setPortfolio(newPortfolio)
  }

  const removeMultiInputs = (valuesKey, value) => {
    const newValues = portfolio[valuesKey]
    const index = newValues.indexOf(newValues.find(newValue => newValue.order == value.order))
    newValues.splice(index, 1)
    const sortedValues = newValues.sort((a,b) => a.order - b.order)
    const valuesWithNewOrder = sortedValues.map((value, index) => {
      return { ...value, order: index + 1}
    })
    setPortfolio({ ...portfolio, [valuesKey]: valuesWithNewOrder })
  }

  const handleMultiInputsOrderChange = (dragOrder, dropOrder, valuesKey) => {
    if (dragOrder == dropOrder) { return }
    const tempValues = portfolio[valuesKey]
    const dragValue = tempValues.find(value => value.order == dragOrder)
    const dropValue = tempValues.find(value => value.order == dropOrder)
    const dragInputIndex = tempValues.indexOf(dragValue)
    const dropInputIndex = tempValues.indexOf(dropValue)
    tempValues[dragInputIndex] = { ...dropValue, order: dragOrder}
    tempValues[dropInputIndex] = { ...dragValue, order: dropOrder }
    setPortfolio({ ...portfolio, [valuesKey]: tempValues })

    // Check the drop & drag values to switch errors if there are any
    const newErrors = {}
    Object.keys(tempValues[0]).forEach(key => {
      const dragId = `${valuesKey}_${key}_${dragInputIndex}`
      const dropId = `${valuesKey}_${key}_${dropInputIndex}`
      newErrors[dragId] = portfolioMultiInputValidation(dragId, dropValue[key])
      newErrors[dropId] = portfolioMultiInputValidation(dropId, dragValue[key])
    })
    setErrors({ ...errors, ...newErrors })
  }

  const handleMultiInputErrors = (id, value, valueKey) => {
    // If there are no values, or all the keys are empty
    // set the error to false to allow fields to be blank
    const testValues = portfolio[valueKey]
    if (testValues == [] || (testValues.length == 1 && testValues.every(testValue => Object.keys(removeNonUpdatableFieldsFromTestValue(testValue)).every(key => testValue[key] == '')))) {
      setErrors({ ...errors, [id]: false })
    } else {
      const validationError = portfolioMultiInputValidation(id, value)
      setErrors({ ...errors, [id]: validationError })
    }
  }

  const handleProjectOrderChange = async (dragProjectOrder, dropProjectOrder) => {
    try {
      const response = await API.post('/projects/order-swap', { dragProjectOrder, dropProjectOrder })
      const data = await response.json()
      setProjects(data)
      if (response.status !== 422) {
        showFlashMessage('Your project order has been updated.')
      } else {
        showFlashMessage('Hmmm... something went wrong. Your project order has not been updated.', 'warning')
      }
    } catch(error) {
      console.error(error)
      showFlashMessage('Hmmm... something went wrong. Your project order has not been updated.', 'warning')
    }
  }

  const handleArticleOrderChange = async (dragArticleOrder, dropArticleOrder) => {
    try {
      const response = await API.post('/articles/order-swap', { dragArticleOrder, dropArticleOrder })
      const data = await response.json()
      setArticles(data)
      if (response.status !== 422) {
        showFlashMessage('Your article order has been updated.')
      } else {
        showFlashMessage('Hmmm... something went wrong. Your article order has not been updated.', 'warning')
      }
    } catch(error) {
      console.error(error)
      showFlashMessage('Hmmm... something went wrong. Your article order has not been updated.', 'warning')
    }
  }

  const removeNonUpdatableFieldsFromTestValue = (testValue) => {
    delete testValue['id']
    delete testValue['created_at']
    delete testValue['updated_at']
    delete testValue['order']
    delete testValue['portfolio_id']
    return testValue
  }

  const handleInputErrors = (id, value) => {
    const validationError = portfolioInputValidation(id, value)
    setErrors({ ...errors, [id]: validationError })
  }

  // Front end only supports 1 education but backend is setup
  // to allow multiple educations (i.e. online courses, certificates) in the future
  const handleEducationAddition = (education) => {
    if (portfolio.educations.length == 0) {
      const newEducations = [...portfolio.educations, { ...education, name: education.name.trim() }]
      setPortfolio({ ...portfolio, educations: newEducations })
      handleInputErrors('education_name', newEducations)
    }
  }

  const handleEducationDelete = (index) => {
    const newEducations = portfolio.educations
    newEducations.splice(index, 1)
    setPortfolio({ ...portfolio, stacks: newEducations })
    handleInputErrors('education_name', newEducations)
  }

  const checkFormErrors = () => {
    const values = Object.values(errors)
    if (values.length === 0 || values.every(value => value === false)) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
  }

  const handleHeroStackAddition = (stack) => {
    // Max 3 hero portfolio stacks
    if (portfolio.hero_portfolio_stacks.length > 2) { return }
    const tagAlreadyAdded = portfolio.hero_portfolio_stacks.some(addedStack => addedStack.name === stack.name)
    if (!tagAlreadyAdded) {
      const newStacks = [...portfolio.hero_portfolio_stacks, {...stack, name: stack.name.trim() }]
      setPortfolio({ ...portfolio, hero_portfolio_stacks: newStacks })
    }
  }

  const handleHeroStackDelete = (index) => {
    const newStacks = portfolio.hero_portfolio_stacks
    newStacks.splice(index, 1)
    setPortfolio({ ...portfolio, hero_portfolio_stacks: newStacks })
  }

  const handleSpokenLanguageAddition = (language) => {
    const tagAlreadyAdded = portfolio.spoken_languages.some(addedLanguage => addedLanguage.name === language.name)
    if (!tagAlreadyAdded) {
      const newLanguage = [...portfolio.spoken_languages, {...language, name: language.name.trim() }]
      setPortfolio({ ...portfolio, spoken_languages: newLanguage })
    }
  }

  const handleSpokenLanguageDelete = (index) => {
    const newLanguage = portfolio.spoken_languages
    newLanguage.splice(index, 1)
    setPortfolio({ ...portfolio, spoken_languages: newLanguage })
  }

  const handleLocationSelected = (place) => {
    if (!place.address_components) {
      alert('Pretty please, with a cherry on top, begin typing and select a city from the autocomplete dropdown 🍒🙏')
      return
    }
    setLocation(formatGoogleLocation(place))
  }

  const handleEducationLocationSelected = (place) => {
    if (!place.address_components) {
      alert('Pretty please, with a cherry on top, begin typing and select a city from the autocomplete dropdown 🍒🙏')
      return
    }
    setEducationLocation(formatGoogleLocation(place))
  }

  const handlePortfolioDesignChange = async (layoutNumber) => {
    if (portfolio.layout == layoutNumber) {
      showFlashMessage('This is your current portfolio design.')
      return null
    }

    try {
      const response = await API.put(`/portfolio_layout/${layoutNumber}`)
      const data = await response.json()
      if (response.status !== 422) {
        setPortfolio(data)
        history.push('/' + safeSlug(portfolio.slug))
        showFlashMessage('Your portfolio design has been updated.')
      } else {
        showFlashMessage('Hmmm... something went wrong. Your portfolio design has not been updated.', 'warning')
      }
    } catch(error) {
      console.error(error)
      showFlashMessage('Hmmm... something went wrong. Your portfolio design has not been updated.', 'warning')
    }
  }

  const setRailsValidationErrors = (errors) => {
    const formattedErrors = {}
    Object.entries(errors).forEach(([key, value]) => {
      formattedErrors[key] = value.join('& ')
    })
    setErrors(formattedErrors)
  }

  const stringifyPortfolio = () => {
    return {
      ...portfolio,
      educations: JSON.stringify(portfolio.educations),
      hero_portfolio_stacks: JSON.stringify(portfolio.hero_portfolio_stacks),
      spoken_languages: JSON.stringify(portfolio.spoken_languages),
      additional_portfolio_skills: JSON.stringify(portfolio.additional_portfolio_skills),
      question_and_answers: JSON.stringify(portfolio.question_and_answers),
      references: JSON.stringify(portfolio.references)
    }
  }

  const handleSubmitButtonClick = async () => {
    const portfolioStatus = currentUser.has_portfolio ? 'updated' : 'added'

    try {
      setSubmitting(true)
      const response = currentUser.has_portfolio
                       ? await API.put(`/portfolios/${portfolio.id}`, stringifyPortfolio(), true)
                       : await API.post('/portfolios', stringifyPortfolio(), true)
      const data = await response.json()
      if (response.status !== 422) {
        showFlashMessage(`Woohoo! Your portfolio info has been ${portfolioStatus} 🚀`, 'notice')

        if (currentUser.has_portfolio) {
          history.push('/' + safeSlug(portfolio.slug))
        } else {
          // If user just created their portfolio, stay on manage page to add projects
          history.push('/portfolios/manage')
          document.querySelector('nav').scrollIntoView({ behavior: 'smooth' }) // Scroll to top of page
          setMultipleData(data)
        }
      } else {
        showFlashMessage(`Your portfolio info cannot be ${portfolioStatus} just yet.<br/>Please see highlighted fields.`, 'alert')
        setRailsValidationErrors(data)
      }
    } catch(error) {
      console.error(error)
      showFlashMessage(`Uh-oh! Something went wrong and your portfolio hasn't been ${portfolioStatus}. Send all angry emails to <a href="mailto:tristan@troopl.com">tristan@troopl.com</a>.`, 'alert')
    } finally {
      setSubmitting(false)
    }
  }

  const renderManagePortfolioHeader = () => (
    <>
      <header>
        <h1>Manage your <span className='troopl-gradient-text'>portfolio</span> 🚀</h1>
      </header>
      <Link
        className='fill-width center-text mb-20'
        to={`/${portfolio.slug}`}
      >
         <Button extraClasses={['fill-width-just-mobile', 'center']}>
            View live portfolio 🙌
          </Button>
       </Link>
    </>
  )

  const renderSetupPortfolioHeader = () => {
    const { info_added, project_added } = portfolio.status;
    const projectAddedCardClasses = classNames('info-card-container', { disabled: !info_added })

    return (
      <>
        <header>
          <h1>
            Let's setup <br/>
            your <span className='troopl-gradient-text'>portfolio</span>🕺🏼
          </h1>
          <h5 className='subhead'>
            You're almost ready to be discovered.
          </h5>
        </header>
        <section className='status-container'>
          <div className='info-card-container'>
            <h5>{ info_added ? '✅': '👉'} Add info</h5>
            <p>
              Complete your portfolio by answering a few quick questions and introducing
              yourself in the <b>portfolio</b> section below.
              <br/><br/>
              You can always update this later.
            </p>
          </div>
          <div className={projectAddedCardClasses}>
            <h5>{ project_added ? '✅': '👉'} Add first project</h5>
            <p className='mb-20'>
             Begin showcasing your talent and tech stack by adding your first project.
            </p>
            <Link
              to='/projects/new'
              disabled={!info_added}
            >
              <Button
                icon='add-circle'
                extraClasses={['fill-width']}
                errors={!info_added}
              >
                Add project
              </Button>
            </Link>
          </div>
        </section>
      </>
    )
  }

  if (loading) {
    return <Loader pageLoader />
  }

  return (
    <main className='page-content-container portfolio-manage-page-container'>
      {
        portfolio.status.setup_complete
        ? renderManagePortfolioHeader()
        : renderSetupPortfolioHeader()
      }
      <Tabs
        baseURL='/portfolios'
        tabs={[
          {
            title: 'Portfolio',
            slug: 'manage',
            content: (
              <>
                <Tip>
                  The information you add below is used to create your portfolio.
                  Complete each section to personalise your portfolio and experience
                  on Troopl.
                </Tip>
                <PortfolioForm
                  portfolio={portfolio}
                  hasPortfolio={currentUser?.has_portfolio}
                  picturePreview={picturePreview}
                  biographyPicturePreview={biographyPicturePreview}
                  educationSuggestions={educationSuggestions}
                  handleEducationAddition={handleEducationAddition}
                  handleEducationDelete={handleEducationDelete}
                  handleEducationLocationChange={handleEducationLocationSelected}
                  CVPreview={CVPreview}
                  onInputChange={handleInputChange}
                  onFileInputChange={handleFileChange}
                  onInputBlur={handleInputBlur}
                  onLocationChange={handleLocationSelected}
                  onSubmit={handleSubmitButtonClick}
                  errors={errors}
                  submitting={submitting}
                  hasErrors={hasErrors}
                  userHasPlus={currentUser?.plus}
                  stackSuggestions={stackSuggestions}
                  handleHeroStackAddition={handleHeroStackAddition}
                  handleHeroStackDelete={handleHeroStackDelete}
                  spokenLanguageSuggestions={spokenLanguageSuggestions}
                  handleSpokenLanguageAddition={handleSpokenLanguageAddition}
                  handleSpokenLanguageDelete={handleSpokenLanguageDelete}
                  handleMultiInputChange={handleMultiInputChange}
                  handleMultiInputBlur={handleMultiInputBlur}
                  addEmptyMultiInputKeysToState={addEmptyMultiInputKeysToState}
                  removeMultiInputs={removeMultiInputs}
                  handleMultiInputsOrderChange={handleMultiInputsOrderChange}
                />
              </>
            )
          },
          {
            title: 'Projects',
            slug: 'projects',
            disabled: !portfolio.status.setup_complete,
            content: (
              <>
                <Tip moreContent>
                  Adding projects showcases your skills and enthusiasm for building interesting things!
                  <br/>
                  <br/>
                  <b>Mention the stacks you use</b><br/>
                  This is added to your portfolio page and is how people discover you and your work when searching on Troopl.
                  <br/>
                  <br/>
                  <b>Shipped is better than perfect</b><br/>
                  Upload your works in progress. You can always update and edit your projects later.
                  <br/>
                  <br/>
                  <b>Make it visual</b><br/>
                  Share images of your project in action to give non-technical visitors to your portfolio a great impression of your skills.
                </Tip>
                <ProjectCards
                  projects={projects}
                  portfolioManage
                  handleProjectOrderChange={handleProjectOrderChange}
                />
              </>
            )
          },
          {
            title: 'Articles',
            slug: 'articles',
            disabled: !portfolio.status.setup_complete,
            content: (
              <>
              <Tip moreContent>
                Another way to showcase your skills and share
                more about your presonality is to write articles.
                <br/>
                <br/>
                Tell the story about a project you’ve built, what inspires you about web development or data science, an area you’re interested in, or anything else!
                <br/>
                <br/>
                Some places you might consider hosting your articles/blog posts
                are <a href='https://substack.com/' target='_blank' rel='noreferrer noopener'>Substack</a>
                and <a href='https://medium.com/' target='_blank' rel='noreferrer noopener'>Medium</a>.
              </Tip>
                <ArticleCards
                  articles={articles}
                  portfolioManage
                  handleArticleOrderChange={handleArticleOrderChange}
                />
              </>
            )
          },
          {
            title: 'Design',
            slug: 'design',
            disabled: !(portfolio.status.setup_complete || currentUser.plus),
            content: (
              <PortfolioDesignCards
                handlePortfolioDesignChange={handlePortfolioDesignChange}
              />
            )
          },
          {
            title: 'Share',
            slug: 'share',
            disabled: !portfolio.status.setup_complete,
            content: (
              <>
                <Tip moreContent>
                  Don’t keep your work secret! Opportunities can come from anywhere,
                  sometimes the most unlikely places! So share your portfolio far and wide.
                  <br/>
                  <br/>
                  Some ideas on where share your unique portfolio URL:
                  <br/>
                  – Your resume <br/>
                  – Job applications <br/>
                  – Twitter <br/>
                  – LinkedIn <br/>
                  – Facebook <br/>
                  – Instagram <br/>
                  – Personal website <br/>
                  – Any time you email a company or meet someone in tech <br/>
                  <br/>
                  But don't limit yourself to online. Also share your portfolio with
                  your family, friends, and anyone who's interested you learning to code.
                  You never know where that next interesting opportunity will come from. We
                  know a developer who got her break by sharing her portfolio with her neighbour!
                </Tip>
                <Share
                  slug={portfolio.slug}
                  name={portfolio.full_name}
                  preferredName={portfolio.preferred_name}
                  type='portfolio'
                  disabled={!portfolio.status.setup_complete}
                  portfolioManagePage
                />
              </>
            )
          },
          {
            title: 'Troopl Plus 👑',
            slug: 'plus',
            content: (
              <StripePayment />
            )
          }
        ]}
      />
    </main>
  )
}

export default PortfolioManagePage
