import React from 'react'
import RadioButtonCards from './RadioButtonCards'
import FormInput from './FormInput'

const PortfolioTypeSelector = (props) => {
  const {
    portfolio,
    onInputChange,
    errors,
    onInputBlur
  } = props

  return (
    <>
      <RadioButtonCards
        cards={[
          {
            value: 'web_development',
            emoji: '👨🏼‍🎤',
            text: 'Web Development'
          },
          {
            value: 'data_science',
            emoji: '👩🏻‍🔬',
            text: 'Data Science'
          },
          {
            value: 'other',
            emoji: '🥷🏻',
            text: 'Other'
          }
        ]}
        label='Portfolio type*'
        values={portfolio}
        valuesKey='portfolio_type'
        name='portfolio_type'
        onChange={onInputChange}
        cardCount='three'
        errors={errors}
        required
      />

      {portfolio.portfolio_type == 'web_development' && (
        <RadioButtonCards
          cards={[
            {
              value: 'front_end_developer',
              emoji: '🎨',
              text: 'Front-end'
            },
            {
              value: 'back_end_developer',
              emoji: '🎒',
              text: 'Back-end'
            },
            {
              value: 'full_stack_developer',
              emoji: '🥞',
              text: 'Full-stack'
            },
            {
              value: 'other_developer',
              emoji: '🥷🏻',
              text: 'Other'
            }
          ]}
          label='Web developer specialty*'
          values={portfolio}
          valuesKey='developer_type'
          name='developer_type'
          onChange={onInputChange}
          cardCount='four'
          errors={errors}
          required
        />
      )}

      {portfolio.portfolio_type == 'data_science' && (
        <RadioButtonCards
          cards={[
            {
              value: 'data_scientist',
              emoji: '🧪',
              text: 'Data Scientist'
            },
            {
              value: 'data_analyst',
              emoji: '🔎',
              text: 'Data Analyst'
            },
            {
              value: 'data_engineer',
              emoji: '🧰',
              text: 'Data Engineer'
            },
            {
              value: 'other_developer',
              emoji: '🥷🏻',
              text: 'Other'
            }
          ]}
          label='Data science specialty*'
          values={portfolio}
          valuesKey='developer_type'
          name='developer_type'
          onChange={onInputChange}
          cardCount='four'
          errors={errors}
          required
        />
      )}

      {(portfolio.developer_type == 'other_developer' || portfolio.portfolio_type == 'other') && (
        <FormInput
          label='What kind of portfolio are you building?*'
          valuesKey='other_developer_type'
          placeholder='i.e Solana Developer, VR Developer'
          values={portfolio}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errors={errors}
          required
        />
      )}
    </>
  )
}

export default PortfolioTypeSelector
