import React from 'react'
import ProjectCards from '../ProjectCards'
import ArticleCards from '../ArticleCards'
import Tags from '../Tags'
import Stats from '../Stats'
import Button from '../Button'
import githubSVG from 'images/svg/github-icon.svg'
import linkedinSVG from 'images/svg/linkedin-circle.svg'
import twitterSVG from 'images/svg/twitter-circle.svg'
import facebookSVG from 'images/svg/facebook-circle.svg'
import instagramSVG from 'images/svg/instagram-circle.svg'

const Isabelle = (props) => {
  const {
    portfolio,
    projects,
    stacks,
    articles,
    resumeButtonClick,
    emailButtonClick,
    toggleModal
  } = props

  return (
    <main className='page-content-container isabelle-container'>
      <header>
        {
          portfolio.picture && (
            <img className='portfolio-picture'
              alt='portfolio-picture'
              src={portfolio.picture}
            />
          )
        }
        <h4 className='developer-type'>{ portfolio.display_developer_type }</h4>
        <h1 className='name'>{ portfolio.full_name }</h1>
        <div className='info-section'>
          <h6 className='city'>{ portfolio.city }</h6>
          {
            portfolio.spoken_language_names && (
              <h6 className='languages'>{ portfolio.spoken_language_names }</h6>
            )
          }
          {
            portfolio.open_to_opportunities && (
              <h6 className='available'>Available for hire</h6>
            )
          }
          {
            portfolio.open_to_remote_work && (
              <h6 className='remote'>Remote</h6>
            )
          }
        </div>
        <Button
          icon='share'
          onClick={toggleModal}
          extraClasses={['fill-width-just-mobile']}
          id='portfolio-share-cta'
        >
          Share portfolio
        </Button>
      </header>

      <ProjectCards
        title={'Projects'}
        projects={projects}
        portfolioShow
      />

      <ArticleCards
        title='Articles'
        articles={articles}
        portfolioShow
      />

      <section className='about'>
        <h3 className='title'>About</h3>
        <div className='content-container'>
          <div className='left-section'>
            <Tags
              title='Tech stack'
              tags={stacks}
              extraClasses={['mb-40']}
            />
            {
              portfolio.education_type && (
                <div className='info-container'>
                  <h6>Coding education</h6>
                  <p>{portfolio.display_education_type}</p>
                  { portfolio.education && <p>{ portfolio.education }</p> }
                  { portfolio.graduation_date && <p>{ portfolio.display_graduation_date }</p> }
                </div>
              )
            }
            { (portfolio.github_url || portfolio.linkedin_url || portfolio.twitter_url || portfolio.facebook_url || portfolio.instagram_url) && (
              <div className='info-container social-profiles'>
                <h6>Social profiles</h6>
                <div className='icons'>
                  {portfolio.github_url && (
                    <a href={portfolio.github_url} target='_blank'>
                      <img src={githubSVG} alt='github-icon' className='logo github'/>
                    </a>
                  )}
                  {portfolio.linkedin_url && (
                    <a href={portfolio.linkedin_url} target='_blank'>
                      <img src={linkedinSVG} alt='linkedin-icon' className='logo twitter'/>
                    </a>
                  )}
                  {portfolio.twitter_url && (
                    <a href={portfolio.twitter_url} target='_blank'>
                      <img src={twitterSVG} alt='twitter-icon' className='logo twitter'/>
                    </a>
                  )}
                  {portfolio.facebook_url && (
                    <a href={portfolio.facebook_url} target='_blank'>
                      <img src={facebookSVG} alt='facebook-icon' className='logo facebook'/>
                    </a>
                  )}
                  {portfolio.instagram_url && (
                    <a href={portfolio.instagram_url} target='_blank'>
                      <img src={instagramSVG} alt='instagram-icon' className='logo instagram'/>
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='right-section'>
            <div className='info-container'>
              <h6>Bio</h6>
              <p className='bio'>{ portfolio.bio }</p>
            </div>
            <Stats views={portfolio.project_views} allProjects />
          </div>
        </div>
      </section>

      <section className='contact'>
      <h3>Get in touch</h3>
        <Button
          onClick={emailButtonClick}
          id='portfolio-contact-cta'
        >
          Email {portfolio.preferred_name}
        </Button>
        { portfolio.cv && (
            <Button
              onClick={resumeButtonClick}
              extraClasses={['resume-button']}
              id='portfolio-resume-cta'
              >
                Download resume
            </Button>
          )
        }
      </section>
    </main>
  )
}

export default Isabelle
