import React, { useState } from 'react'
const classNames = require('classnames')
import Emoji from '../../Emoji'

const DenisQuestionAndAnswers = ({questionAndAnswers, references}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const containerClasses = classNames(
    'denis-question-and-answers-container',
    { 'references-component-offset': references.length > 0 }
  )

  if (questionAndAnswers.length == 0) { return null }

  return (
    <div className={containerClasses}>
      <div className='header'>
        <h3>A few<br/><span className='troopl-gradient-text'>extra things</span> to know</h3>
        <Emoji name='speech-balloon' />
      </div>
      <div className='denis-acordion-container'>
        {
          questionAndAnswers.map(({question, answer}, index) => {
            const questionAndAnswerClasses = classNames(
              'question-and-answer',
              { 'collapse': index != selectedQuestion }
            )

            return (
              <div
                className={questionAndAnswerClasses}
                key={index}
                onClick={() => setSelectedQuestion(index)}
              >
                <div className='question'>
                  <h5>{ question }</h5>
                  <div className='svg-container'>
                    <svg className='minus' width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.35082 16.1515V14.608H14.6508V16.1515H8.35082Z" fill="currentColor"/>
                      <rect x="0.75" y="4.75" width="21" height="21" rx="6.25" stroke="currentColor" strokeWidth="1.5"/>
                    </svg>
                    <svg className='plus' width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7314 19.2175V15.2065H6.72045V13.663H10.7314V9.6625H12.2749V13.663H16.2755V15.2065H12.2749V19.2175H10.7314Z" fill="currentColor"/>
                      <rect x="0.75" y="4.75" width="21" height="21" rx="6.25" stroke="currentColor" strokeWidth="1.5"/>
                    </svg>
                  </div>
                </div>
                <div className='answer'>
                  <p>{ answer }</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default DenisQuestionAndAnswers
