import React from 'react'
import ReactTags from 'react-tag-autocomplete'
import Autocomplete from 'react-google-autocomplete'
import RadioButtonCards from './RadioButtonCards'
import FormInput from './FormInput'
import { humaniseEducationType } from '../helpers/general'

const PortfolioTypeSelector = (props) => {
  const {
    portfolio,
    educationSuggestions,
    handleEducationAddition,
    handleEducationDelete,
    handleEducationLocationChange,
    onInputChange,
    errors,
    onInputBlur,
    googleMapsAPIKey
  } = props

  return (
    <>
      <RadioButtonCards
        cards={[
          {
            value: 'self_taught',
            emoji: '🚀',
            text: 'Self-taught'
          },
          {
            value: 'coding_bootcamp',
            emoji: '🪖',
            text: 'Bootcamp'
          },
          {
            value: 'university',
            emoji: '🎓',
            text: 'University'
          }
        ]}
        label='Type*'
        values={portfolio}
        valuesKey='education_type'
        name='education'
        onChange={onInputChange}
        errors={errors}
        cardCount='three'
        language
      />

      {['coding_bootcamp', 'university'].includes(portfolio.education_type) && (
        <>
          <div className='education-selector-side-by-side'>
            <label
              htmlFor='educations'
              className='educations'
              aria-invalid={!!errors.education_name}
            >
              {`${humaniseEducationType(portfolio.education_type)} name*`}
              <ReactTags
                id='educations'
                placeholderText=''
                allowNew
                newTagText='Enter: '
                minQueryLength={1}
                tags={portfolio.educations}
                suggestions={educationSuggestions}
                onAddition={handleEducationAddition}
                onDelete={handleEducationDelete}
              />
              {errors['education_name'] && <span className='note error'>{errors['education_name']}</span>}
            </label>

            {portfolio.education_type == 'coding_bootcamp' && (
              <FormInput
                label='Batch (optional)'
                labelClass='batch'
                valuesKey='batch'
                values={portfolio}
                onChange={onInputChange}
                onBlur={onInputBlur}
                errors={errors}
              />)}
          </div>

          <label htmlFor='education_city'>
            {`${humaniseEducationType(portfolio.education_type)} city (optional)`}
            <span className='note'>{`Leave blank if 100% online, digital ${humaniseEducationType(portfolio.education_type)}`}</span>
            <Autocomplete
              id='education_city'
              apiKey={googleMapsAPIKey}
              options={{fields: ['address_components', 'formatted_address']}}
              defaultValue={portfolio.education_city}
              language='en'
              placeholder='Begin typing city name...'
              onPlaceSelected={handleEducationLocationChange}
              onChange={onInputChange}
              onBlur={onInputBlur}
              aria-invalid={!!errors['education_city']}
              type='search'
              autoComplete='off'
            />
          </label>
          {errors['city'] && <span className='note error'>{errors['city']}</span>}

          <label htmlFor='graduation_date'>
            Graduation date (optional)
            <input
              id='graduation_date'
              type='date'
              value={portfolio.graduation_date ?? ''}
              onChange={onInputChange}
              onBlur={onInputBlur}
            />
          </label>
        </>
      )}
    </>
  )
}

export default PortfolioTypeSelector
