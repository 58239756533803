import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import CurrentUserContext from '../../../context/CurrentUserContext'
import PlusTemplateContext from '../../../context/PlusTemplateContext'
import ProjectCards from '../../ProjectCards'
import Tags from '../../Tags'
import Button from '../../Button'
import DenisArticleSlider from './DenisArticleSlider'
import DenisReferences from './DenisReferences'
import DenisQuestionAndAnswers from './DenisQuestionAndAnswers'
import ReactHtmlParser from 'react-html-parser'
import Emoji from '../../Emoji'
import navPinSVG from 'images/svg/plus-nav-pin.svg'
import speechBubbleSVG from 'images/svg/plus-speech-bubble.svg'
import starSVG from 'images/svg/star-border.svg'
import globeSVG from 'images/svg/globe.svg'
import githubSVG from 'images/svg/github-icon.svg'
import linkedinSVG from 'images/svg/linkedin-circle.svg'
import twitterSVG from 'images/svg/twitter-circle.svg'
import facebookSVG from 'images/svg/facebook-circle.svg'
import instagramSVG from 'images/svg/instagram-circle.svg'
import arrow from 'images/svg/hand-drawn-arrow.svg'

const Denis = (props) => {
  const {
    portfolio,
    projects,
    stacks,
    heroStacks,
    articles,
    references,
    resumeButtonClick,
    emailButtonClick,
    toggleModal
  } = props

  const [currentUser] = useContext(CurrentUserContext)
  const [, setPlusTemplate] = useContext(PlusTemplateContext)
  const history = useHistory()

  useEffect(() => {
    setPlusTemplate('denis')
    document.querySelector('body').classList.add('denis')
    return () => {
      document.querySelector('body').classList.remove('denis')
      setPlusTemplate(null)
    }
  }, [])

  const scrollToBioSection = () => {
    document.getElementById('biography').scrollIntoView({ behavior:"smooth" })
  }

  return (
    <main className='page-content-container denis-container'>
      <nav>
        <Button
          icon='plus-share'
          onClick={toggleModal}
          extraClasses={['plus-nav', 'share']}
        />
        <Button
          icon='plus-email'
          onClick={emailButtonClick}
          extraClasses={['plus-nav']}
        />
        { currentUser?.id == portfolio.user.id && (
          <>
            <Button
              icon='plus-home'
              extraClasses={['plus-nav']}
              onClick={() => history.push('/portfolios/manage')}
            />
            <img src={arrow} alt='hand drawn arrow pointing to button' className='arrow'/>
            <p className='portfolio-owner-text'>Only you can see this home button!</p>
          </>
        )}
      </nav>
      <div className='header'>
        <div className='details'>
          <div className='top'>
            <h2 className='name'>{ portfolio.full_name }</h2>
            {
              portfolio.picture && (
                <div className='picture-container'>
                  <Emoji name='wave' />
                  <img
                    className='picture'
                    alt='portfolio-picture'
                    src={portfolio.picture}
                  />
                </div>
              )
            }
          </div>
          <div className='bottom'>
            <div className='info city'>
              <img src={navPinSVG} alt='navigation pin icon' />
              <p>{ portfolio.city }</p>
            </div>
            {portfolio.spoken_language_names && (
              <div className='info language'>
                <img src={speechBubbleSVG} alt='speech bubble icon'/>
                <p>{ portfolio.spoken_language_names }</p>
              </div>
            )}
            {portfolio.open_to_opportunities && (
              <div className='info available'>
                <img src={starSVG} alt='star outline icon'/>
                <p>Available for hire</p>
              </div>
            )}
            {portfolio.open_to_remote_work && (
              <div className='info remote'>
                <img src={globeSVG} alt='globe icon'/>
                <p>Remote</p>
              </div>
            )}
          </div>
        </div>
        <div className='bio'>
          { ReactHtmlParser(portfolio.header_headline_html) }
          <p className='intro'>{ portfolio.header_intro }</p>
          <div className='links'>
            <Button
              onClick={emailButtonClick}
              extraClasses={['denis']}
            >
              Connect with me
            </Button>
            <a
              className='full-bio'
              onClick={scrollToBioSection}
            >
                View full bio ->
            </a>
          </div>
        </div>
      </div>
      {
        projects.length > 0 && (
          <div className='full-width-container pink'>
            <section className='projects-container'>
              <div className='projects'>
                <div className='featured-project-container'>
                  <ProjectCards
                    projects={[projects[0]]}
                    portfolioTemplate='denis'
                    featuredProject
                  />
                  { projects.length > 1 && <h4>All projects</h4> }
                </div>
                {
                  projects.length > 1 && (
                    <div className='all-projects-container'>
                      <ProjectCards
                        projects={projects}
                        portfolioTemplate='denis'
                        emailButtonClick={emailButtonClick}
                      />
                    </div>
                  )
                }
              </div>
            </section>
          </div>
        )
      }
      {
        stacks.length > 0 && (
          <div className='stacks'>
            <div className='text-container'>
              <div className='text'>
                <h2>
                  Tech<br/>
                  <span className='troopl-gradient-text'>Stack</span>
                </h2>
                { heroStacks.length > 0 && <p>The stars represent my favourite tools</p> }
                <Emoji name='pancakes' extraClasses={['one']}/>
              </div>
            </div>
            <Tags
              tags={stacks}
              heroStacks={heroStacks}
              layout='denis'
              buttonSize='denis'
              emoji={<Emoji name='pancakes' extraClasses={['two']} />}
            />
            <Emoji name='pancakes' extraClasses={['three']} />
          </div>
        )
      }
      <div className='biography' id='biography'>
        { portfolio.bio_headline  && <h3 className='headline'>{ portfolio.bio_headline }</h3> }
        <div className='main'>
          <div className='biography-container'>
            <h5>Biography</h5>
            <p>{ portfolio.bio }</p>
            { (portfolio.github_url || portfolio.linkedin_url || portfolio.twitter_url || portfolio.facebook_url || portfolio.instagram_url) && (
              <div className='social-profile-container'>
                <div className='icons'>
                  {portfolio.github_url && (
                    <a href={portfolio.github_url} target='_blank'>
                      <img src={githubSVG} alt='github-icon' className='logo github'/>
                    </a>
                  )}
                  {portfolio.linkedin_url && (
                    <a href={portfolio.linkedin_url} target='_blank'>
                      <img src={linkedinSVG} alt='linkedin-icon' className='logo twitter'/>
                    </a>
                  )}
                  {portfolio.twitter_url && (
                    <a href={portfolio.twitter_url} target='_blank'>
                      <img src={twitterSVG} alt='twitter-icon' className='logo twitter'/>
                    </a>
                  )}
                  {portfolio.facebook_url && (
                    <a href={portfolio.facebook_url} target='_blank'>
                      <img src={facebookSVG} alt='facebook-icon' className='logo facebook'/>
                    </a>
                  )}
                  {portfolio.instagram_url && (
                    <a href={portfolio.instagram_url} target='_blank'>
                      <img src={instagramSVG} alt='instagram-icon' className='logo instagram'/>
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='picture-container'>
            {portfolio.biography_picture && (
              <img
                className='picture'
                alt='biography picture'
                src={portfolio.biography_picture}
              />
            )}
            { portfolio.cv && (
              <Button
                onClick={resumeButtonClick}
                extraClasses={['resume-button']}
              >
                Download resume
              </Button>
            )}
            { heroStacks[0] && (
              <Tags
                tags={[heroStacks[0]]}
                heroStacks={heroStacks}
                buttonSize='small'
                extraClasses={['hero-stack', 'one']}
              />
            )}
            { heroStacks[1] && (
              <Tags
                tags={[heroStacks[1]]}
                heroStacks={heroStacks}
                buttonSize='small'
                extraClasses={['hero-stack', 'two']}
              />
            )}
            { heroStacks[2] && (
              <Tags
                tags={[heroStacks[2]]}
                heroStacks={heroStacks}
                buttonSize='small'
                extraClasses={['hero-stack', 'three']}
              />
            )}
          </div>
          { portfolio.additional_portfolio_skills.length > 0 && (
            <div className='additional-skills-container'>
              <h5>Additional skills</h5>
              <ul>
                {
                  portfolio.additional_portfolio_skills.map(({text, order}) => (
                    <li key={order}>{ text }</li>
                  ))
                }
              </ul>
            </div>
          )}
          <div className='coding-education-container'>
            <h5>Coding education</h5>
            <p>{portfolio.display_education_type}</p>
            { portfolio.education && <p>{ portfolio.education }</p> }
            { portfolio.graduation_date && <p>{ portfolio.display_graduation_date }</p> }
          </div>
        </div>
      </div>
      {
        (articles.length > 0 || references.length > 0) && (
          <div className='full-width-container white'>
            <DenisArticleSlider articles={articles} />
            <DenisReferences
              references={references}
              questionAndAnswers={portfolio.question_and_answers}
            />
          </div>
      )}
      <DenisQuestionAndAnswers
        questionAndAnswers={portfolio.question_and_answers}
        references={references}
      />
      <div className='contact-information'>
        <div className='connect'>
          <Emoji name='wave' />
          <Emoji name='speech-balloon' />
          <Emoji name='telephone' />
          <Emoji name='laptop' />
          <Emoji name='call-me-hand' />
          { portfolio.open_to_opportunities && (
            <p className='available'>Available for hire</p>
          )}
          <Button
            onClick={emailButtonClick}
            extraClasses={['denis']}
          >
            Connect with me
          </Button>
        </div>
        <div className='info'>
          <div className='top'>
            <h5>{portfolio.full_name}</h5>
            {
              portfolio.picture && (
                <img
                  className='picture'
                  alt='portfolio-picture'
                  src={portfolio.picture}
                />
              )
            }
          </div>
          { ReactHtmlParser(portfolio.header_headline_html) }
          { portfolio.cv && (
            <div className='resume'>
              <p>Find out more about me</p>
              <div className='resume-button-container'>
                <p>by</p>
                <Button
                  onClick={resumeButtonClick}
                  extraClasses={['resume-button']}
                  icon='download-resume'
                  extraClasses={['denis', 'small']}
                >
                  Downloading my resume
                </Button>
              </div>
            </div>
          )}

        </div>
      </div>
    </main>
  )
}

export default Denis
