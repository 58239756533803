import React, { useState } from 'react'
const classNames = require('classnames');
import chevron from 'images/svg/chevron-down.svg'

const Tip = ({moreContent, children}) => {
  const [showMoreContent, setShowMoreContent] = useState(false)
  const containerClasses = classNames(
    'tip-container',
    { 'show-more-content': showMoreContent },
    { 'more-content': moreContent }
  )
  const chevronClasses = classNames('chevron', { 'show-more-content': showMoreContent })

  const handleClick = () => {
    setShowMoreContent(!showMoreContent)
  }

  return (
    <div
      className={containerClasses}
      onClick={handleClick}
    >
      { moreContent && (
        <img src={chevron} alt='chevron pointing down' className={chevronClasses}/>
      )}
      <p>
        <b>🔥 Quick tip.&nbsp;</b>
        { children }
      </p>
    </div>
  )
}

export default Tip
