import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import CurrentUserContext from '../context/CurrentUserContext'
import TrooplPlusIcon from './TrooplPlusIcon'

const PortfolioDesignCards = ({handlePortfolioDesignChange}) => {
  const [currentUser] = useContext(CurrentUserContext)

  return (
    <div className='portfolio-design-cards-container'>
      {/* Denis (Plus) */}
      <div className='design-container'>
        <div className='name-and-actions'>
          <h3 className='title'>Denis <TrooplPlusIcon size='h3' /></h3>
          <p className='description'>
            A professional template with additional sections to tell your story, stand out, and make a splash.
          </p>
          <div className='buttons'>
            <a href='/designs/demo/denis' target='_blank'>
              <Button>
                Preview 👀
              </Button>
            </a>
            <Button
              onClick={() => handlePortfolioDesignChange(1)}
              disabled={!currentUser.plus}
            >
              Select design ⚡️
            </Button>
          </div>
          {!currentUser.plus ? <Link to='/portfolios/plus' className='troopl-plus-cta'>Available on Troopl Plus 👑  Upgrade now</Link> : null}
        </div>
        <div className='screenshot denis'>
        </div>
      </div>

      {/* Isabelle (Free) */}
      <div className='design-container'>
        <div className='name-and-actions'>
          <h3 className='title'>Isabelle</h3>
          <p className='description'>
            A clean and minimal design perfect for to showcasing your projects and creating an online presence.
          </p>
          <div className='buttons'>
            <a href='/designs/demo/isabelle' target='_blank'>
              <Button>
                Preview 👀
              </Button>
            </a>
            <Button
              onClick={() => handlePortfolioDesignChange(0)}
            >
              Select design ⚡️
            </Button>
          </div>
        </div>
        <div className='screenshot isabelle'>
        </div>
      </div>
    </div>
  )
}

export default PortfolioDesignCards
